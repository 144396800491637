import React, { useState, useEffect } from "react";
import "./style.css";
import { locations, companies } from "../SubmitAutocomplete.js";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { useCookies } from "react-cookie";
import { RouteComponentProps, navigate } from "@reach/router";
import {
  Input,
  Form,
  Button,
  Select,
  Radio,
  Tooltip,
  Rate,
  Checkbox,
  InputNumber,
  Tabs,
  AutoComplete,
  notification,
} from "antd/es";
import {
  QuestionCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { YearValue, MyInfo as myInfoInterface } from "../../reviews";
import { database } from "../../database";
import { useAuth } from "../../contexts/AuthContext";

let isDisabled = false;

const close = (key) => {
  console.log(
    "Notification was closed. Either the close button was clicked or duration time elapsed."
  );
  notification.close(key);
  navigate("/register");
};

const { Option } = Select;

const dev_is_required = true;

const { TabPane } = Tabs;

const years = [
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
];
const semesters = ["Spring", "Summer", "Fall"];

const terms1 = years
  .reduce((acc, year) => {
    let perm = semesters.reduce(
      (a, semester) => [...a, semester + " " + year],
      [] as string[]
    );
    return acc.concat(perm);
  }, [] as string[])
  .map((option, i) => (
    <Option value={option} key={i}>
      {option}
    </Option>
  ));

const terms = terms1.reverse();

function callback(key) {
  console.log(key);
}

const InterviewTab = ({ setSubmitPage, index }) => {
  const [form] = Form.useForm();
  const [cookies, setCookie, removeCookie] = useCookies([
    "name",
    "email",
    "school",
    "majors",
    "year",
  ]);
  // const [submitPage, setSubmitPage] = useState("1");

  const { currentUser, logout } = useAuth();

  const [myInfo, setMyInfo] = useState<myInfoInterface>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  // const [unfinished, setUnfinished] = useState(false);
  useEffect(() => {
    async function getUserInfo(userID) {
      console.log("hey");
      database
        .collection("users")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          setMyInfo(doc.data() as myInfoInterface);
          console.log(doc.data());
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if (currentUser) {
    //   isDisabled = false;
    //   getUserInfo(currentUser.uid);
    // }
  }, []);

  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="section-card interview-process">
        <h2 className="submit-section-header">The interview</h2>
        <div className="submit-horizontalFields">
          <Form.Item
            name="interview_application_new"
            label="How did you find the job / apply?"
            extra="Choose all that apply"
            rules={[
              {
                required: dev_is_required,
                message: "Please select an option",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Please select method(s)"
              style={{ width: "100%", maxWidth: "340px" }}
              tokenSeparators={[","]}
              className="input-horizontal"
              disabled={isDisabled}
              options={[
                { value: "Company career page" },
                { value: "Online job board" },
                { value: "School job board" },
                { value: "Referral/networking" },
                { value: "Career fair" },
                { value: "Cold email/call" },
              ]}
            ></Select>
          </Form.Item>

          <Form.Item
            name="interview_types_new"
            label="Interview type"
            extra="Choose all that apply"
            rules={[
              {
                required: dev_is_required,
                message: "Please select an option",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Please select types"
              style={{ width: "100%", maxWidth: "340px" }}
              tokenSeparators={[","]}
              className="input-horizontal"
              disabled={isDisabled}
              options={[
                { value: "Behavioral" },
                { value: "Knowledge (theoretical)" },
                { value: "Knowledge (technical)" },
                { value: "Case" },
                { value: "Past experience" },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item
            className="input-horizontal"
            name="rounds"
            label="Rounds"
            rules={[
              {
                required: dev_is_required,
                message: "Please select an option",
              },
            ]}
          >
            <Radio.Group
              disabled={isDisabled}
              className="input-horizontal-rounds"
            >
              <Radio value={"1"}>1</Radio>
              <br></br>
              <Radio value={"2"}>2</Radio>
              <br></br>
              <Radio value={"3"}>3</Radio>
              <br></br>
              <Radio value={"4+"}>4+</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <Form.Item
          name="interview_questions"
          label="What questions did they ask? How difficult were they?"
          rules={[
            {
              required: false,
              message: "Please select an option",
            },
          ]}
        >
          <Input.TextArea disabled={isDisabled} rows={1}></Input.TextArea>
        </Form.Item>

        <Form.Item
          name="interview_advice"
          label="What do your classmates need to do to get this internship? What do you wish you would've known?"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Input.TextArea disabled={isDisabled} rows={2}></Input.TextArea>
        </Form.Item>
        { index==0 ? (
                  <Button
                    className="submit-nextPage"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSubmitPage("2");
                      window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                      });
                    }}
                  >
                   Next <RightCircleOutlined />
                  </Button>
              ) : (
                <>

                  <Button
                    className="submit-nextPage"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSubmitPage(index.toString());
                      window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <LeftCircleOutlined /> Back
                  </Button>
                  <Button
                    className="submit-nextPage"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSubmitPage((index+2).toString());
                      window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Next <RightCircleOutlined />
                  </Button>
                  </>
              )}
        {/*<Button
          className="submit-nextPage"
          type="primary"
          size="large"
          onClick={() => {
            setSubmitPage("3");
            window.scrollTo({
              top: 100,
              behavior: "smooth",
            });
          }}
        >
          <LeftCircleOutlined /> Back
        </Button>

        <Button
          className="submit-nextPage"
          type="primary"
          size="large"
          onClick={() => {
            setSubmitPage("5");
            window.scrollTo({
              top: 100,
              behavior: "smooth",
            });
          }}
        >
          Next <RightCircleOutlined />
        </Button>*/}
      </div>
    </>
  );
};

const verticalStyle = {
  lineHeight: "30px",
  display: "block",
};

export default InterviewTab;
