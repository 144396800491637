import React, { useState, useEffect } from "react";
import "./style.css";
import { DynamicRule2, EmoryQuestionsTab } from "..";
import { RouteComponentProps } from "@reach/router";
import canary from "../../images/canaryLogo-img.png";
import canary_internshipreviews from "../../images/canaryLogo_text-img.png";
import emory from "../../images/emorylogo.png";
import { Button } from "antd/es";
import {
  QuestionCircleOutlined,
  RightCircleOutlined,
  ExportOutlined,
} from "@ant-design/icons";

const Survey = (props: RouteComponentProps) => {
  const [welcome, setWelcome] = useState<boolean>(true);

  return (
    <>
      <>
        <div className="school-survey">
          <div className="survey-header">
            <div className="survey-logos">
              <img
                className="survey-school-logo"
                alt="Emory logo"
                src={emory}
              ></img>
              {"  +  "}
              <img
                className="survey-canary-logo"
                alt="Canary Logo"
                src={canary_internshipreviews}
              ></img>
            </div>
            {welcome && (
              <h4 className="canary-description">
                <h3 className="align-center canary-description">
                  Emory has partnered with{" "}
                  <a href="https://canarystudent.com" target="_blank">
                    Canary
                  </a>
                  , the internship review site built by students, for students!
                  <br></br>
                  <br></br>Your response will only be shared with the Emory
                  Career Center. After completing it, you can continue on to
                  leave a full internship review publicly on Canary to help your
                  classmates (
                  <a
                    href="https://app.canarystudent.com/reviews/i8g7pjs29pq308z3rn5"
                    target="_blank"
                  >
                    see a live example from a fellow Emory student
                  </a>
                  ), and the first 100 students to do so will get a $10 Amazon
                  gift card via email!
                  <br></br>
                </h3>
              </h4>
            )}
            <br></br>

            <div className="emoryQuestions centered">
              <EmoryQuestionsTab setSubmitPage={1} index={1} />
              {/*<DynamicRule/>*/}
              {/*<DynamicRule2
                  l={[
                    "Emory Questions",
                    "Details",
                    "My Experience",
                    "Interview",
                    "Submit",
                  ]}
                />*/}
              {/*<DynamicRule2 l={[Details,
         MyExperience, Interview, SubmitTab
         ]}/>*/}
            </div>
          </div>

          <br></br>
        </div>
      </>
    </>
  );
};

export default Survey;
