import React, { useState, useEffect } from "react";
import "./style.css";
import { locations, companies } from "../SubmitAutocomplete.js";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { useCookies } from "react-cookie";
import { RouteComponentProps, navigate } from "@reach/router";
import {
  Input,
  Form,
  Button,
  Select,
  Radio,
  Tooltip,
  Rate,
  Checkbox,
  InputNumber,
  Tabs,
  AutoComplete,
  notification,
} from "antd/es";
import {
  QuestionCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { YearValue, MyInfo as myInfoInterface } from "../../reviews";
import { database } from "../../database";
// import { createEmitAndSemanticDiagnosticsBuilderProgram } from "typescript";
import { useAuth } from "../../contexts/AuthContext";
import { schools_static, majors_static } from "../SubmitAutocomplete.js";

let isDisabled = false;

// const close = (key) => {
//   console.log(
//     "Notification was closed. Either the close button was clicked or duration time elapsed."
//   );
//   notification.close(key);
//   navigate("/register");
// };

// const mustSignUpNotification = () => {
//   const key = `open${Date.now()}`;
//   const tooltp = (
//     <span>
//       We won't spam you, sell your email, or reveal your indentity to anyone.{" "}
//       <span role="img" aria-label="tophat">
//         🕵
//       </span>{" "}
//       <Tooltip
//         className="input-horizontal-spacer"
//         title="Canary ensures all reviews are from real students. All reviews are anonymous and personal info like email is only used to verify your account, nothing more. 🔐"
//       >
//         <QuestionCircleOutlined />
//       </Tooltip>
//     </span>
//   );
//   const btn = (
//     <Button type="primary" size="large" onClick={() => close(key)}>
//       Sign up
//     </Button>
//   );
//   notification.open({
//     placement: "topRight",
//     duration: 5,
//     top: 100,
//     message: "Sign up to help your fellow student! 😊",
//     description: tooltp,
//     btn,
//     key,
//   });
// };

const { Option } = Select;

const dev_is_required = true;

const { TabPane } = Tabs;

const hiddenInfoDisclaimer =
  "To make sure you stay anonymous, we hide your major, school, year, and full-time offer info unless the user is a registered student or school faculty member - your recruiter or company can't see it 🔐";

// const marks_impact = {
//   0: "No impact (busy-work)",
//   1: "Not very impactful",
//   2: "Somewhat impactful",
//   3: "Impactful",
//   4: "Very impactful",
// };

// const marks_prerequisites = {
//   0: "None (they'll teach you what you need to know)",
//   1: "Beginner (need basic knowledge/ experience)",
//   2: "Intermediate (need to be pretty familiar)",
//   3: "Expert (need to have advanced knowledge/ experience)",
// };

// const marks_timeworking = {
//   0: "0-20% (might as well have done nothing)",
//   1: "20-40% worked some, but ton of down time)",
//   2: "40-60% (some days stayed busy, but still good bit of downtime)",
//   3: "60-80% (stayed pretty busy)",
//   4: "80-100% (more or less busy the whole time)",
// };

const years = [
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
];
const semesters = ["Spring", "Summer", "Fall"];

const terms1 = years
  .reduce((acc, year) => {
    let perm = semesters.reduce(
      (a, semester) => [...a, semester + " " + year],
      [] as string[]
    );
    return acc.concat(perm);
  }, [] as string[])
  .map((option, i) => (
    <Option value={option} key={i}>
      {option}
    </Option>
  ));

const terms = terms1.reverse();

function callback(key) {
  console.log(key);
}

const DetailsTab = ({ setSubmitPage, index }) => {
  const [form] = Form.useForm();
  const [cookies, setCookie, removeCookie] = useCookies([
    "name",
    "email",
    "school",
    "majors",
    "year",
  ]);
  const [hasHousingStipend, setHasHousingStipend] = useState(false);
  // const [submitPage, setSubmitPage] = useState("1");

  const { currentUser, logout } = useAuth();

  const [myInfo, setMyInfo] = useState<myInfoInterface>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  // const [unfinished, setUnfinished] = useState(false);
  useEffect(() => {
    async function getUserInfo(userID) {
      console.log("hey");
      database
        .collection("users")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          setMyInfo(doc.data() as myInfoInterface);
          console.log(doc.data());
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if (currentUser) {
    //   isDisabled = false;
    //   getUserInfo(currentUser.uid);
    // }
  }, []);

  // const onFail = (errorInfo) => {
  //   setUnfinished(true);
  //   if (errorInfo.values.remember_personal) {
  //     setCookie("name", errorInfo.values.name || "");
  //     setCookie("school", errorInfo.values.school || "");
  //     setCookie("email", errorInfo.values.email || "");
  //     setCookie("major", errorInfo.values.major || "");
  //     setCookie("other_studies", errorInfo.values.other_studies || "");
  //     setCookie("year", errorInfo.values.year || {});
  //     setCookie("remember_personal", errorInfo.values.remember_personal);
  //   } else {
  //     removeCookie("name");
  //     removeCookie("school");
  //     removeCookie("email");
  //     removeCookie("major");
  //     removeCookie("other_studies");
  //     removeCookie("year");
  //     removeCookie("remember_personal");
  //   }
  // };

  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {currentUser && (
        <div className="section-card about-you">
          <h2 className="submit-section-header about-you-header">
            {" "}
            Application{" "}
          </h2>{" "}
          <>
            {myInfo?.school && (
              <h3 className="school">
                School: <strong>{myInfo?.school}</strong>
              </h3>
            )}
            {myInfo?.major && (
              <h3 className="major">
                Major: <strong>{myInfo?.major}</strong>
              </h3>
            )}
          </>
          <Form.Item
            className=""
            name="major"
            label={
              <>
                <span>
                  Major (companies can't see this 🔒){" "}
                  <Tooltip
                    className="input-horizontal-spacer"
                    title={hiddenInfoDisclaimer}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>{" "}
                </span>{" "}
              </>
            }
            rules={[
              {
                required: dev_is_required,
                message: "Please input your major",
              },
            ]}
          >
            <AutoComplete
              className=""
              options={majors_static}
              placeholder="Please input your major"
              style={{ maxWidth: "320px" }}
              filterOption={(inputValue, option) =>
                option
                  ? option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  : false
              }
            />
          </Form.Item>
          <Form.Item
            name="year"
            rules={[
              {
                required: dev_is_required,
                message: "Please select your year",
              },
            ]}
            label={
              <>
                <span>
                  Year in school when you applied (companies can't see this 🔒){" "}
                  <Tooltip
                    className="input-horizontal-spacer"
                    title={hiddenInfoDisclaimer}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>{" "}
              </>
            }
          >
            <YearInput />
          </Form.Item>
        </div>
      )}

      <div className="section-card internship-details">
        <h2 className="submit-section-header">Internship Details</h2>
        <Form.Item
          className="input-horizontal"
          name="internship_type"
          label="Internship or Co-op"
          rules={[
            {
              required: dev_is_required,
              message: "Please select internship type",
            },
          ]}
        >
          <Radio.Group
            value="internship"
            className="input-horizontal"
            disabled={isDisabled}
          >
            <Radio.Button value="internship">Internship</Radio.Button>
            <Radio.Button value="co-op">Co-op</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          className="input-horizontal"
          name="company_name"
          label="Company name"
          rules={[
            {
              required: dev_is_required,
              message: "Please input the company name",
            },
          ]}
        >
          <AutoComplete
            className="input-horizontal"
            disabled={isDisabled}
            options={companies}
            placeholder="Please input the company"
            style={{ maxWidth: "320px" }}
            filterOption={(inputValue, option) =>
              option
                ? option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                : false
            }
          />
        </Form.Item>
        <Form.Item
          className="input-horizontal"
          name="position"
          label="Position title"
          rules={[
            {
              required: dev_is_required,
              message: "Please input your position title",
            },
          ]}
        >
          <Input
            style={{ maxWidth: "250px" }}
            placeholder="Please input your position title"
            className="input-horizontal"
            disabled={isDisabled}
          />
        </Form.Item>
        <Form.Item
          className="input-horizontal"
          name="team"
          label="Department/Team"
          rules={[
            {
              required: false,
              message: "Please input your department/team",
            },
          ]}
        >
          <Input
            style={{ maxWidth: "250px" }}
            placeholder="Please input your department/team"
            className="input-horizontal"
            disabled={isDisabled}
          />
        </Form.Item>

        <Form.Item
          className=""
          name="is_remote"
          label={
            <span>Was this internship remote/virtual or was it in person?</span>
          }
          rules={[
            {
              required: dev_is_required,
              message:
                "Please specify if this is a remote internship/co-op program",
            },
          ]}
        >
          <Radio.Group disabled={isDisabled} className="">
            <Radio.Button value="In-person">In-person</Radio.Button>
            <Radio.Button value="Remote">Remote</Radio.Button>
            <Radio.Button value="Some of both">Some of both</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.is_remote !== currentValues.is_remote
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue("is_remote") &&
              getFieldValue("is_remote") !== "In-person" ? (
              <Form.Item
                className=""
                name="remote_description"
                label="How did being remote affect the experience?"
                rules={[
                  {
                    required: false,
                    message: "Please describe how the company did being remote",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="How was it being virtual?"
                  disabled={isDisabled}
                  rows={2}
                ></Input.TextArea>
              </Form.Item>
            ) : (
              <div></div>
            );
          }}
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.is_remote !== currentValues.is_remote
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue("is_remote") &&
              getFieldValue("is_remote") !== "Remote" ? (
              <Form.Item
                className="input-horizontal"
                name="location"
                label="Location (city/state/region)"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please input your internship's location",
                  },
                ]}
              >
                <AutoComplete
                  options={locations}
                  disabled={isDisabled}
                  placeholder="Please input your internship's location"
                  style={{ maxWidth: "340px" }}
                  filterOption={(inputValue, option) =>
                    option
                      ? option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      : false
                  }
                  className="input-horizontal"
                />
              </Form.Item>
            ) : (
              <div></div>
            );
          }}
        </Form.Item>

        <Form.Item
          className="input-horizontal"
          name="terms"
          label="Term(s) employed"
          rules={[
            {
              required: false,
              message: "Please select the terms you were employed",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Please select the terms you were employed"
            style={{ width: "100%", maxWidth: "340px" }}
            tokenSeparators={[","]}
            className="input-horizontal"
            disabled={isDisabled}
          >
            {terms}
          </Select>
        </Form.Item>

        <Form.Item
          className="input-horizontal"
          name="pay"
          label="Pay (USD Dollar)"
          extra="Hourly, stipend, etc."
          rules={[
            {
              required: dev_is_required,
              message: "Please input a number for the pay and its type",
            },
          ]}
        >
          <Input.Group compact className="payField">
            <Form.Item
              name={["pay", "amount"]}
              noStyle
              rules={[{ required: true, message: "Amount is required" }]}
            >
              <InputNumber
                disabled={isDisabled}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => `$ ${value}`.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>

            <Form.Item
              name={["pay", "type"]}
              noStyle
              rules={[{ required: true, message: "Pay type is required" }]}
            >
              <Select
                disabled={isDisabled}
                placeholder="Pay Type"
                style={{ maxWidth: "100px" }}
              >
                <Option value="hourly">hourly</Option>
                <Option value="weekly">weekly</Option>
                <Option value="monthly">monthly</Option>
                <Option value="lump">lump</Option>
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="Housing stipend" className="input-horizontal">
          <Radio.Group
            defaultValue="none"
            onChange={(e) => setHasHousingStipend(e.target.value === "yes")}
            className="input-horizontal"
            disabled={isDisabled}
          >
            <Radio.Button value="none">None</Radio.Button>
            <Radio.Button value="yes">Yes</Radio.Button>
          </Radio.Group>
          {hasHousingStipend && (
            <Form.Item
              name="housing_stipend"
              noStyle
              rules={[
                {
                  required: dev_is_required,
                  message: "Please input a number for the housing stipend",
                },
              ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => `$ ${value}`.replace(/\$\s?|(,*)/g, "")}
                placeholder="Please input your pay"
                disabled={isDisabled}
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          name="offer"
          label={
            <>
              <span>
                Did you accept a full-time offer here? (companies can't see this
                🔒){" "}
                <Tooltip
                  className="input-horizontal-spacer"
                  title={hiddenInfoDisclaimer}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>{" "}
            </>
          }
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Radio.Group className="input-horizontal" disabled={isDisabled}>
            <Radio style={verticalStyle} value={0}>
              Yes
            </Radio>
            <Radio style={verticalStyle} value={1}>
              Offered, but declined
            </Radio>
            <Radio style={verticalStyle} value={2}>
              Not offered
            </Radio>
            <Radio style={verticalStyle} value={3}>
              Not applicable (e.g. it's too early)
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.offer !== currentValues.offer
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue("offer") && getFieldValue("offer") > 1 ? (
              <Form.Item
                name="would_accept_offer"
                label="Would you accept a full-time offer here?"
                rules={[{ required: dev_is_required }]}
              >
                <Radio.Group>
                  <Radio style={verticalStyle} value={0}>
                    Yes, definitely
                  </Radio>
                  <Radio style={verticalStyle} value={1}>
                    Maybe, I probably would
                  </Radio>
                  <Radio style={verticalStyle} value={3}>
                    Maybe, but probably not
                  </Radio>
                  <Radio style={verticalStyle} value={2}>
                    No, definitely not
                  </Radio>
                </Radio.Group>
              </Form.Item>
            ) : (
              <div></div>
            );
          }}
        </Form.Item>

        { index==0 ? (
                  <Button
                    className="submit-nextPage"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSubmitPage("2");
                      window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                      });
                    }}
                  >
                   Next <RightCircleOutlined />
                  </Button>
              ) : (
                <>

                  <Button
                    className="submit-nextPage"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSubmitPage(index.toString());
                      window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <LeftCircleOutlined /> Back
                  </Button>
                  <Button
                    className="submit-nextPage"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSubmitPage((index+2).toString());
                      window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Next <RightCircleOutlined />
                  </Button>
                  </>
              )}


        {/*<Button
          className="submit-nextPage"
          type="primary"
          size="large"
          onClick={() => {
            setSubmitPage("1");
            window.scrollTo({
              top: 100,
              behavior: "smooth",
            });
          }}
        >
          <LeftCircleOutlined /> Back
        </Button>
        <Button
          className="submit-nextPage"
          type="primary"
          size="large"
          onClick={() => {
            setSubmitPage("3");
            window.scrollTo({
              top: 100,
              behavior: "smooth",
            });
          }}
        >
          Next <RightCircleOutlined />
        </Button>*/}
      </div>
    </>
  );
};

const verticalStyle = {
  lineHeight: "30px",
  display: "block",
};

export interface PayValue {
  type: "hourly" | "lump" | "monthly" | "weekly";
  amount: number;
  currency: "USD" | "EUR";
}

interface YearInputProps {
  value?: YearValue;
  onChange?: (value: YearValue) => void;
}

const YearInput: React.FC<YearInputProps> = ({ value = {}, onChange }) => {
  const [gradLevel, setGradLevel] = useState<
    "undergraduate" | "graduate" | "graduated"
  >();
  const [year, setYear] = useState<
    "1st" | "2nd" | "3rd" | "4th" | "5th" | "6th+"
  >();

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange({ grad_level: gradLevel, year, ...value, ...changedValue });
    }
  };

  const onGradLevelChange = (e) => {
    let newVal = e.target.value;
    if (!("grad_level" in value)) {
      setGradLevel(newVal);
      if (!("year" in value)) {
        setYear("1st");
      }
    }
    triggerChange({ grad_level: newVal, year: value.year || "1st" });
  };

  const onCurrencyChange = (e) => {
    let newVal = e.target.value;
    if (!("year" in value)) {
      setYear(newVal);
      if (!("grad_level" in value)) {
        setGradLevel("undergraduate");
      }
    }
    triggerChange({
      year: newVal,
      grad_level: value.grad_level || "undergraduate",
    });
  };

  return (
    <span className="year-select">
      <Radio.Group value={value.grad_level} onChange={onGradLevelChange}>
        <Radio.Button value="undergraduate">Undergraduate</Radio.Button>
        <Radio.Button value="graduate">Graduate</Radio.Button>
        <Radio.Button value="graduated">Graduated</Radio.Button>
      </Radio.Group>
      <div className="year-select-number">
        <Radio.Group
          value={value.grad_level === "graduated" ? "" : value.year}
          onChange={onCurrencyChange}
          disabled={value.grad_level === "graduated"}
        >
          <Radio.Button value="1st">1st</Radio.Button>
          <Radio.Button value="2nd">2nd</Radio.Button>
          <Radio.Button value="3rd">3rd</Radio.Button>
          <Radio.Button value="4th">4th</Radio.Button>
          <Radio.Button value="5th">5th</Radio.Button>
          <Radio.Button value="6th+">6th+</Radio.Button>
        </Radio.Group>
      </div>
    </span>
  );
};

export default DetailsTab;
