import React from "react";
import "./style.css";
import { Review, Company, Ratings } from "../../reviews";
import { useMediaQuery } from "react-responsive";
import { RightCircleOutlined, RightOutlined } from "@ant-design/icons";

// This removes the company name from the "See All" link if it's too long (so it doesn't clutter up the UI)
function longNameFix(company_name) {
  if (company_name.length > 20) {
    return "this company";
  } else {
    return company_name;
  }
}

const CompanySearchCard = ({
  name,
  description,
  image,
  reviews,
  company_id,
  num_reviews,
  ratings,
}: {
  name: string;
  description: string;
  image?: string;
  reviews: Review[];
  company_id: string;
  num_reviews: number;
  ratings: Ratings;
}) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  const isMobile = !isDesktop;

  return (
    <div className="company-search">
      <div>
        {false && (
          <>
            <a href={"/companies/" + name}>
              <div className="company-search__header">
                <div className="company-search__left">
                  {/*<Link to={"/?company=" + name}>
                  <img alt={name} src={image} className="company-search__img"></img>
                </Link>*/}

                  <img
                    alt={name}
                    src={image}
                    className="company-search__img"
                  ></img>

                  <span className="company-search__title">
                    <h2 className="company-search__name">
                      {name}
                      {/*<Link className="company-search__position" to={"/?company=" + name}>{name}</Link>*/}
                    </h2>
                    <div className="company-search__description">
                      {description}
                    </div>
                  </span>
                </div>

                <div className="company-search__right">
                  <a
                    className="company-search__seeAll"
                    href={"/companies/" + name}
                  >
                    See all <b>{num_reviews || ""}</b> reviews for{" "}
                    {longNameFix(name)} <RightOutlined />
                  </a>
                </div>
              </div>
            </a>
          </>
        )}

        {(isMobile || isDesktop) && (
          <>
            <a href={"/companies/" + name}>
              <div className="company-search__header">
                <div className="company-search__left_mobile">
                  <img
                    alt={name}
                    src={image}
                    className="company-search__img_mobile"
                  ></img>
                </div>
                <div className="company-search__right_mobile">
                  <h2 className="company-search__name_mobile">
                    {name}
                    {/*<Link className="company-search__position" to={"/?company=" + name}>{name}</Link>*/}
                  </h2>
                  <div className="company-search__description_mobile">
                    {description}
                  </div>
                  <div className="company-search__seeAll_mobile">
                    <a href={"/companies/" + name}>
                      <span className="company-search__seeAll_text">
                        See all <b>{num_reviews || ""}</b> reviews{" "}
                        <RightCircleOutlined />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export const click_filter = "NCR";
export default CompanySearchCard;
