import React, { useState, useEffect } from "react";
import "./style.css";
import { Review, Company, Ratings } from "../../reviews";
import { useMediaQuery } from "react-responsive";
import { RightCircleOutlined, RightOutlined } from "@ant-design/icons";

// This removes the company name from the "See All" link if it's too long (so it doesn't clutter up the UI)
function longNameFix(company_name) {
  if (company_name.length > 20) {
    return "this company";
  } else {
    return company_name;
  }
}

function trimName(company_name) {
  if (company_name.length > 37) {
    company_name = company_name.slice(0, 37 - 1);
    company_name = company_name + "...";
    return company_name;
  } else {
    return company_name;
  }
}

const rateColor = (value) =>
  value >= 4 ? "#2FF495" : value > 2.5 ? "#F9E02B" : "#F23E30"; //2FF495 30ADF2

const TopCompaniesCompanyCard = ({
  name,
  description,
  image,
  reviews,
  company_id,
  num_reviews,
  ratings,
  ranking,
  rating_toShow,
}: {
  name: string;
  description: string;
  image?: string;
  reviews: Review[];
  company_id: string;
  num_reviews: number;
  ratings: Ratings;
  ranking: number;
  rating_toShow: string;
}) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  const isMobile = !isDesktop;
  const [rating, setRating] = useState<number>(ratings.work_avg);
  const [ratingText, setRatingText] = useState<string>("Overall");

  useEffect(() => {
    if (rating_toShow == "rating-overall") {
      setRating(ratings.overall_avg);
      setRatingText("Overall");
    } else if (rating_toShow == "rating-work") {
      setRating(ratings.work_avg);
      setRatingText("Work");
    } else if (rating_toShow == "rating-culture") {
      setRating(ratings.culture_avg);
      setRatingText("Culture");
    }
  }, [
    rating_toShow,
    ratings.culture_avg,
    ratings.overall_avg,
    ratings.work_avg,
  ]);

  return (
    <div>
      {isDesktop && (
        <div className="topCompanies_card">
          <div className="topCompanies__header">
            <div className="topCompanies_rankingAndRating">
              <h2 className="topCompanies_ranking">#{ranking + 1}</h2>
              <div className="topCompanies_avgRatings">
                <div>
                  <p className="topCompanies_rating-title">{ratingText}</p>
                  <div
                    className="topCompanies__overall-rating"
                    style={{ background: rateColor(rating) }}
                  >
                    {rating}
                    <span className="divisor">/5</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="topCompanies__left">
              <img alt={name} src={image} className="topCompanies__img"></img>

              <span className="topCompanies__title">
                <h2 className="topCompanies__name">
                  {trimName(name)}
                  {/*<Link className="topCompanies__position" to={"/?company=" + name}>{name}</Link>*/}
                </h2>
                <div className="topCompanies__description">{description}</div>
              </span>
            </div>

            <div className="topCompanies__right">
              <a className="company-card__seeAll" href={"/companies/" + name}>
                <div>
                  See all <b>{num_reviews || ""}</b> reviews for<br></br>
                  {longNameFix(name)}
                </div>
                <div className="company-card__seeAll_arrow">
                  <RightOutlined />
                </div>
              </a>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div className="topCompanies_card_mobile">
          <div className="topCompanies__left_mobile">
            <div className="topCompanies_rankingAndRating_mobile">
              <h2 className="topCompanies_ranking_mobile">#{ranking + 1}</h2>
              <div className="topCompanies_avgRatings_mobile">
                <div>
                  <p className="topCompanies_rating-title">{ratingText}</p>
                  <div
                    className="topCompanies__overall-rating"
                    style={{ background: rateColor(rating) }}
                  >
                    {rating}
                    <span className="divisor">/5</span>
                  </div>
                </div>
              </div>
            </div>
            <img
              alt={name}
              src={image}
              className="topCompanies__img_mobile"
            ></img>
          </div>
          <a href={"/companies/" + name}>
            <div className="topCompanies__right_mobile">
              <h2 className="topCompanies__name_mobile">
                {name}
                {/*<Link className="topCompanies__position" to={"/?company=" + name}>{name}</Link>*/}
              </h2>

              <div className="topCompanies__description_mobile">
                {description}
              </div>

              <div className="topCompanies__seeAll_mobile">
                <a href={"/companies/" + name}>
                  <span className="topCompanies__seeAll_text">
                    See all <b>{num_reviews || ""}</b> reviews{" "}
                    <RightCircleOutlined />
                  </span>
                </a>
              </div>
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

export default TopCompaniesCompanyCard;
