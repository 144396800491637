import React from "react";
import "./style.css";

import { Review } from "../../reviews";
import {ReviewCard} from "..";
// import ReviewCard from "./ReviewCard";
import { useMediaQuery } from "react-responsive";
import {
  RightCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
// const CompanyMeta = ({}: {}) => <div className="company-meta">test</div>;

// function companyPageURLPackager(company_name) {
//   return company_name.replace("&", "%26"); //.toLowerCase();
// }

// This removes the company name from the "See All" link if it's too long (so it doesn't clutter up the UI)
function longNameFix(company_name) {
  if (company_name.length > 20) {
    return "this company";
  } else {
    return company_name;
  }
}

const CompanyCard = ({
  name,
  description,
  image,
  reviews,
  company_id,
  num_reviews,
}: {
  name: string;
  description: string;
  image?: string;
  reviews: Review[];
  company_id: string;
  num_reviews: number;
}) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  const isMobile = !isDesktop;

  return (
    <div className="company-card">
      <div>
        {isDesktop && (
          <>
            <a href={"/companies/" + name}>
              <div className="company-card__header">
                <div className="company-card__left">
                  {/*<Link to={"/?company=" + name}>
                  <img alt={name} src={image} className="company-card__img"></img>
                </Link>*/}

                  <img
                    alt={name}
                    src={image}
                    className="company-card__img"
                  ></img>

                  <span className="company-card__title">
                    <h2 className="company-card__name">
                      {name}
                      {/*<Link className="company-card__position" to={"/?company=" + name}>{name}</Link>*/}
                    </h2>
                    <div className="company-card__description">
                      {description}
                    </div>
                  </span>
                </div>

                <div className="company-card__middle"></div>

                <div className="company-card__right">
                  <a
                    className="company-card__seeAll"
                    href={"/companies/" + name}
                  >
                    <div>
                      See all <b>{num_reviews || ""}</b> reviews for<br></br>
                      {longNameFix(name)}
                    </div>
                    <div className="company-card__seeAll_arrow">
                      <RightOutlined />
                    </div>
                  </a>
                </div>
              </div>
            </a>
            <div className="company-card__more">
              {/* <Link to="/">More details about this company...</Link> */}
            </div>
            <div className="company-card__reviews">
              {reviews.map((review, i) => (
                <ReviewCard key={i} review={review} />
              ))}
            </div>
          </>
        )}

        {isMobile && (
          <>
            <a href={"/companies/" + name}>
              <div className="company-card__header">
                <div className="company-card__left_mobile">
                  <img
                    alt={name}
                    src={image}
                    className="company-card__img_mobile"
                  ></img>
                </div>
                <div className="company-card__right_mobile">
                  <h2 className="company-card__name_mobile">
                    {name}
                    {/*<Link className="company-card__position" to={"/?company=" + name}>{name}</Link>*/}
                  </h2>
                  <div className="company-card__description_mobile">
                    {description}
                  </div>
                  <div className="company-card__seeAll_mobile">
                    <a href={"/companies/" + name}>
                      <span className="company-card__seeAll_text">
                        See all reviews <RightCircleOutlined />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </a>
            <div className="company-card__more">
              {/* <Link to="/">More details about this company...</Link> */}
            </div>
            <div className="company-card__reviews">
              {reviews.map((review, i) => (
                <ReviewCard key={i} review={review} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const click_filter = "NCR";
export default CompanyCard;
