import { database } from "../database";

const schools = [];

const locations = [{ value: "Atlanta, GA" }];

const companies = [];

// Generate array of all schools in review collection
database
  .collection("schools")
  .orderBy("name", "asc")
  .get()
  .then(function (querySnapshot) {
    // For each document in the companies firestore collection, add the company "name" to the local companies array
    querySnapshot.forEach(function (doc) {
      // doc.data() is never undefined for query doc snapshots

      //console.log("Test: " + doc.data().name);
      schools.push({ value: doc.data().name });
    });
  })
  .catch(function (error) {
    console.log("Error getting documents: ", error);
  });

// Generate array of all schools in review collection
const majors_live = [];
database
  .collection("majors_live")
  .orderBy("name", "asc")
  .get()
  .then(function (querySnapshot) {
    // For each document in the companies firestore collection, add the company "name" to the local companies array
    querySnapshot.forEach(function (doc) {
      // doc.data() is never undefined for query doc snapshots

      //console.log("Test: " + doc.data().name);
      majors_live.push({ value: doc.data().name });
    });
  })
  .catch(function (error) {
    console.log("Error getting documents: ", error);
  });

const schools_live = [];
database
  .collection("schools_live")
  .orderBy("name", "asc")
  .get()
  .then(function (querySnapshot) {
    // For each document in the companies firestore collection, add the company "name" to the local companies array
    querySnapshot.forEach(function (doc) {
      // doc.data() is never undefined for query doc snapshots

      //console.log("Test: " + doc.data().name);
      schools_live.push({ value: doc.data().name });
    });
  })
  .catch(function (error) {
    console.log("Error getting documents: ", error);
  });

// Generate array of all companies in companies collection
database
  .collection("companies_public")
  .orderBy("name", "asc")
  .get()
  .then(function (querySnapshot) {
    // For each document in the companies firestore collection, add the company "name" to the local companies array
    querySnapshot.forEach(function (doc) {
      // doc.data() is never undefined for query doc snapshots

      //console.log("Test: " + doc.data().name);
      companies.push({ value: doc.data().name });
    });
  })
  .catch(function (error) {
    console.log("Error getting documents: ", error);
  });

const majors_static = [
  { value: "Accountancy" },
  { value: "Accounting" },
  { value: "Accounting and Information Systems" },
  { value: "Accounting Technician" },
  { value: "Acting" },
  { value: "Actuarial Science" },
  { value: "Adult Development & Aging/Gerontology" },
  { value: "Advertising" },
  { value: "Aeronautical/Aerospace Engineering Technologies" },
  { value: "Aeronautics and Astronautics" },
  { value: "Aerospace Engineering" },
  { value: "Aerospace/Aeronautical Engineering" },
  { value: "African American and African Studies" },
  { value: "African American Studies" },
  { value: "African and African Diaspora Studies" },
  { value: "African and Middle Eastern Studies" },
  { value: "African Languages, Literatures, and Linguistics" },
  { value: "African Studies" },
  { value: "African-American Studies" },
  { value: "Afroamerican and African Studies" },
  { value: "Agri-Accounting" },
  { value: "Agribusiness" },
  { value: "Agribusiness and Applied Economics" },
  { value: "Agribusiness Operations" },
  { value: "Agribusiness, Markets & Management" },
  { value: "Agricultural and Biological Engineering" },
  { value: "Agricultural and Consumer Economics" },
  { value: "Agricultural Business & Management" },
  { value: "Agricultural Business and Management" },
  { value: "Agricultural Communication" },
  { value: "Agricultural Communications - Advertising" },
  { value: "Agricultural Communications - Journalism" },
  { value: "Agricultural Economics" },
  { value: "Agricultural Education" },
  { value: "Agricultural Journalism" },
  { value: "Agricultural Leadership, Education, and Communications" },
  { value: "Agricultural Mechanization" },
  { value: "Agricultural Production" },
  { value: "Agricultural Sciences" },
  { value: "Agricultural Systems Management" },
  { value: "Agricultural Technology Management" },
  { value: "Agricultural/Bioengineering" },
  { value: "Agricultural/Biological Engineering and Bioengineering" },
  { value: "Agriculture" },
  { value: "Agriculture, Exploring" },
  { value: "Agriculture, General" },
  { value: "Agriscience Education" },
  { value: "Agronomy" },
  { value: "Agronomy & Crop Science" },
  { value: "Agronomy and Crop Science" },
  { value: "Air Traffic Control" },
  { value: "Aircraft Mechanics/Technology" },
  { value: "Aircraft Piloting & Navigation" },
  { value: "Alcohol/Drug Abuse Counseling" },
  { value: "American Culture" },
  { value: "American History" },
  { value: "American Indian Studies" },
  { value: "American Indian/Native American Studies" },
  { value: "American Literature" },
  { value: "American Literature and Culture" },
  { value: "American Sign Language" },
  { value: "American Studies" },
  { value: "American/English Literature" },
  { value: "Anatomy" },
  { value: "Ancient and Medieval Studies" },
  { value: "Ancient History and Classics" },
  { value: "Ancient Near East and Egyptology" },
  { value: "Ancient Studies" },
  { value: "Animal and Poultry Sciences" },
  { value: "Animal Behavior and Ethology" },
  { value: "Animal Science" },
  { value: "Animal Sciences" },
  { value: "Animation and Special Effects" },
  { value: "Anthropological Sciences" },
  { value: "Anthropology" },
  { value: "Applied Exercise Science" },
  { value: "Applied Inter­national Studies" },
  { value: "Applied Language and Intercultural Studies" },
  { value: "Applied Mathematics" },
  { value: "Applied Physics" },
  { value: "Aquaculture" },
  { value: "Aquatic Biology" },
  { value: "Arabic" },
  { value: "Archaeology and Materials" },
  { value: "Archeology" },
  { value: "Architectural Drafting/CAD Technology" },
  { value: "Architectural Engineering" },
  { value: "Architectural Engineering Technology" },
  { value: "Architectural Environmental Design" },
  { value: "Architectural History" },
  { value: "Architectural Studies" },
  { value: "Architecture" },
  { value: "Architecture, General" },
  { value: "Area Studies, General (e.g., African, Middle Eastern)" },
  { value: "Art" },
  { value: "Art and Art History" },
  { value: "Art and Design" },
  { value: "Art Education" },
  { value: "Art Education (K-12)" },
  { value: "Art History" },
  { value: "Art History, Criticism & Conservation" },
  { value: "Art Therapy" },
  { value: "Art, Culture and Technology" },
  { value: "Art, General" },
  { value: "Artificial Intelligence and Robotics" },
  { value: "Arts and Entertainment Technology" },
  { value: "Arts and Ideas in the Humanities" },
  { value: "Arts and Sciences, Undecided" },
  { value: "Arts Management" },
  { value: "Asian American Studies" },
  { value: "Asian and Asian Diaspora Studies" },
  { value: "Asian Area Studies" },
  { value: "Asian Humanities" },
  { value: "Asian Languages & Literatures" },
  { value: "Asian Languages and Linguistics new" },
  { value: "Asian Religions" },
  { value: "Asian Studies" },
  { value: "Asian-American Studies" },
  { value: "Astronomy" },
  { value: "Astronomy and Astrophysics" },
  { value: "Astrophysics" },
  { value: "Athletic Training" },
  { value: "Atmospheric and Oceanic Sciences" },
  { value: "Atmospheric and Oceanic Sciences/Mathematics" },
  { value: "Atmospheric Chemistry" },
  { value: "Atmospheric Science" },
  { value: "Atmospheric Sciences" },
  { value: "Atmospheric Sciences & Meteorology" },
  { value: "Autobody Repair/Technology" },
  { value: "Automotive Engineering" },
  { value: "Automotive Engineering Technology" },
  { value: "Automotive Mechanics/Technology" },
  { value: "Aviation" },
  { value: "Aviation & Airway Science, General" },
  { value: "Aviation Management" },
  { value: "Aviation Management & Operations" },
  { value: "Avionics Technology" },
  { value: "Bakery Science" },
  { value: "Banking & Financial Support Services" },
  { value: "Behavioral Neuroscience" },
  { value: "Bible/Biblical Studies" },
  { value: "Biblical Studies" },
  { value: "Biochemical Sciences" },
  { value: "Biochemistry" },
  { value: "Biochemistry & Biophysics" },
  { value: "Bioenergy" },
  { value: "Bioengineering" },
  { value: "Bioethics" },
  { value: "Biological Engineering" },
  { value: "Biological Sciences (Biology)" },
  { value: "Biological Systems Engineering" },
  { value: "Biology" },
  { value: "Biology, General" },
  { value: "Biology, Health, and Society" },
  { value: "Biology, Marine" },
  { value: "Biomedical Engineering" },
  { value: "Biomedical Science" },
  { value: "Biomolecular Science" },
  { value: "Biophysics" },
  { value: "Biopsychology" },
  { value: "Biopsychology, Cognition, and Neuroscience" },
  { value: "Biotechnology" },
  { value: "Botany/Plant Biology" },
  { value: "Brain and Cognitive Sciences" },
  { value: "Building Construction" },
  { value: "Business" },
  { value: "Business Administration" },
  { value: "Business Administration & Management, General" },
  { value: "Business Administration, Undecided" },
  { value: "Business Administration/Management" },
  { value: "Business Analytics" },
  { value: "Business Communications" },
  { value: "Business Economics" },
  { value: "Business Education" },
  { value: "Business Information Technology" },
  { value: "Business Management" },
  { value: "Business/Management Quantitative Methods, General" },
  { value: "Business/Managerial Economics" },
  { value: "Canadian Studies" },
  { value: "Career & Technical Education" },
  { value: "Career and Technical Education - Agriculture Education" },
  { value: "Caribbean Studies" },
  { value: "Cell Biology" },
  { value: "Cell/Cellular Biology" },
  { value: "Cellular and Molecular Biomedical Science" },
  { value: "Central and East European Languages and Cultures" },
  { value: "Ceramic Engineering" },
  { value: "Ceramics" },
  { value: "Chemical and Biomolecular Engineering" },
  { value: "Chemical and Biomolecular Engineering – Biotechnology Option" },
  { value: "Chemical Engineering" },
  { value: "Chemical Physics" },
  { value: "Chemical Science" },
  { value: "Chemical-Biological Engineering" },
  { value: "Chemistry" },
  { value: "Chemistry and Biology" },
  { value: "Chemistry/Materials Science" },
  { value: "Chicana and Chicano Studies" },
  { value: "Child and Youth Studies" },
  { value: "Child Care" },
  { value: "Child Care Services Management" },
  { value: "Child Development" },
  { value: "Childhood Pre-Education" },
  { value: "Chinese" },
  { value: "Chiropractic" },
  { value: "Chiropractic (Pre-Chiropractic)" },
  { value: "Church Music" },
  { value: "Cinema" },
  { value: "Cinema/Film" },
  { value: "Cinematography and Film/Video Production" },
  { value: "Cinematography/Film/Video Production" },
  { value: "Circulation Technology" },
  { value: "City and Regional Planning" },
  { value: "City/Urban/Regional Planning" },
  { value: "Civil and Environmental Engineering" },
  { value: "Civil and Environmental Systems" },
  { value: "Civil Engineering" },
  { value: "Civil Engineering Technology" },
  { value: "Classical Archaeology" },
  { value: "Classical Civilization" },
  { value: "Classical Languages and Literatures" },
  { value: "Classical Studies" },
  { value: "Classical/Ancient Languages & Literatures" },
  { value: "Classics" },
  { value: "Climate and Meteorology" },
  { value: "Climate Science new" },
  { value: "Clinical Neuroscience" },
  { value: "Clinical Psychology" },
  { value: "Cognitive and Behavioral Neuroscience" },
  { value: "Cognitive Psychology" },
  { value: "Cognitive Science" },
  { value: "College Majors List: Repair, Production and Construction" },
  { value: "Communication" },
  { value: "Communication and Media" },
  { value: "Communication Disorder Services (e.g., Speech Pathology)" },
  { value: "Communication Disorders" },
  { value: "Communication Studies" },
  { value: "Communications Studies/Speech Communication and Rhetoric" },
  { value: "Communications Technology, General" },
  { value: "Communications, General" },
  { value: "Community and Global Public Health" },
  { value: "Community Economic Development" },
  { value: "Community Health" },
  { value: "Community Leadership" },
  { value: "Community Organization & Advocacy" },
  { value: "Comparative and World Literature" },
  { value: "Comparative Culture and Identity" },
  { value: "Comparative Literature" },
  { value: "Comparative Media Studies" },
  { value: "Comparative Studies" },
  { value: "Composition" },
  { value: "Computation and Cognition" },
  { value: "Computational and Systems Biology" },
  { value: "Computational and Systems Neuroscience" },
  { value: "Computational Media" },
  { value: "Computational Media & Digital Media (BS/MS)" },
  { value: "Computational Modeling and Data Analytics" },
  { value: "Computer & Information Sciences, General" },
  { value: "Computer and Information Science" },
  { value: "Computer Engineering" },
  { value: "Computer Engineering Technology" },
  { value: "Computer Graphics" },
  { value: "Computer Networking/Telecommunications" },
  { value: "Computer Science" },
  { value: "Computer Science & Programming" },
  { value: "Computer Science and Advertising" },
  { value: "Computer Science and Animal Sciences" },
  { value: "Computer Science and Anthropology" },
  { value: "Computer Science and Astronomy" },
  { value: "Computer Science and Chemistry" },
  { value: "Computer Science and Crop Sciences" },
  { value: "Computer Science and Economics" },
  { value: "Computer Science and Engineering" },
  { value: "Computer Science and Engineering and Computer Science" },
  { value: "Computer Science and Geography & Geographic Information Science" },
  { value: "Computer Science and Linguistics" },
  { value: "Computer Science and Molecular Biology" },
  { value: "Computer Science and Music" },
  { value: "Computer Science and Philosophy" },
  { value: "Computer Software & Media Applications" },
  { value: "Computer System Administration" },
  { value: "Computer Systems Analysis" },
  { value: "Computing" },
  { value: "Construction Engineering and Management" },
  { value: "Construction Engineering/Management" },
  { value: "Construction Management" },
  { value: "Construction Systems Management" },
  { value: "Construction Trades (e.g., carpentry, plumbing, electrical)" },
  { value: "Construction/Building Technology" },
  { value: "Consumer & Family Economics" },
  { value: "Consumer and Family Financial Services" },
  { value: "Consumer Economics & Finance" },
  { value: "Consumer Studies" },
  { value: "Corrections" },
  { value: "Cosmetology/Hairstyling" },
  { value: "Costume Design and Technology" },
  { value: "Counseling" },
  { value: "Counseling & Student Services" },
  { value: "Court Reporting" },
  { value: "Crafts" },
  { value: "Creative Technologies" },
  { value: "Creative Writing" },
  { value: "Creative Writing and Literature" },
  { value: "Criminal Justice" },
  { value: "Criminal Science" },
  { value: "Criminology" },
  { value: "Criminology and Criminal Justice Studies" },
  { value: "Crop and Soil Sciences" },
  { value: "Crop Management and Soil Conservation" },
  { value: "Crop Sciences" },
  { value: "Culinary Arts" },
  { value: "Culinary Arts/Chef Training" },
  { value: "Culinary Science" },
  { value: "Curriculum & Instruction" },
  { value: "Dairy Cattle Production and Management" },
  { value: "Dairy Science" },
  { value: "Dance" },
  { value: "Dance (BA)" },
  { value: "Dance (BFA)" },
  { value: "Data Analytics" },
  { value: "Data Management Technology" },
  { value: "Data Processing" },
  { value: "Data Science" },
  { value: "Dental Assisting" },
  { value: "Dental Hygiene" },
  { value: "Dental Hygiene (baccalaureate completion program)" },
  { value: "Dentistry (Pre-Dentistry)" },
  { value: "Design" },
  { value: "Design & Visual Communications, General" },
  { value: "Developmental Psychology" },
  { value: "Diagnostic Medical Sonography" },
  { value: "Diesel Mechanics/Technology" },
  { value: "Dietetics" },
  { value: "Digital Communications and Media/Multimedia" },
  { value: "Digital Communications/Media" },
  { value: "Divinity/Ministry" },
  { value: "Drafting/CAD Technology, General" },
  { value: "Drama" },
  { value: "Drawing" },
  { value: "Early Childhood Education" },
  { value: "Early Childhood Education (Birth-Grade 2)" },
  { value: "Earth and Atmospheric Sciences" },
  { value: "Earth and Environmental Science" },
  { value: "Earth and Environmental Sciences" },
  { value: "Earth Sciences" },
  { value: "Earth, Atmospheric, and Planetary Sciences" },
  { value: "Earth, Society, and Environmental Sustainability" },
  { value: "East Asian Languages and Cultures" },
  { value: "East Asian Studies" },
  { value: "East European Studies" },
  { value: "Ecology" },
  { value: "Ecology, Behavior, and Evolution" },
  { value: "Ecology, Evolution, and Biodiversity" },
  { value: "Econometrics and Quantitative Economics" },
  { value: "Economics" },
  { value: "Economics – Business" },
  { value: "Economics and International Affairs" },
  { value: "Education" },
  { value: "Education – Early Childhood Education" },
  { value: "Education – Integrated Language Arts/English Education" },
  { value: "Education – Middle Childhood Education" },
  { value: "Education – Science and Mathematics Education" },
  { value: "Education – Special Education" },
  { value: "Education – Teaching English to Speakers of Other Languages" },
  { value: "Education – Technical Education and Training" },
  { value: "Education – World Language Education" },
  { value: "Education (combined major only)" },
  { value: "Education Administration" },
  { value: "Education of the Deaf" },
  { value: "Educational Administration" },
  { value: "Educational Psychology" },
  { value: "Electrical and Computer Engineering" },
  { value: "Electrical Engineering" },
  { value: "Electrical Engineering and Computer Science" },
  { value: "Electrical Science and Engineering" },
  { value: "Electrical, Electronics & Communications Engineering" },
  { value: "Electrical, Electronics Engineering Technologies" },
  { value: "Electrical/Electronics Equip Installation & Repair" },
  { value: "Electromechanical/Biomedical Engineering Technologies" },
  { value: "Elementary Education" },
  { value: "Elementary Education (Grades 1-6)" },
  { value: "Elementary Education (PK-6)" },
  { value: "Elementary Teacher Education" },
  { value: "Emergency Medical Technology" },
  { value: "Energy Studies" },
  { value: "Engineering" },
  { value: "Engineering (Pre-Engineering), General" },
  { value: "Engineering Mechanics" },
  { value: "Engineering Physics" },
  { value: "Engineering Science and Mechanics" },
  { value: "Engineering Technology" },
  { value: "Engineering Technology, General" },
  { value: "English" },
  { value: "English Composition" },
  { value: "English Language & Literature, General" },
  { value: "English Language Arts Education" },
  { value: "English Literature" },
  { value: "English-as-a-Second-Language Education" },
  { value: "English/Language Arts Education" },
  { value: "Entomology" },
  { value: "Entrepreneurship" },
  { value: "Entrepreneurship & Innovation" },
  { value: "Entrepreneurship and Innovation" },
  { value: "Environment" },
  { value: "Environment and Natural Resources" },
  { value: "Environment and Natural Resources, Undecided" },
  { value: "Environment and Sustainability" },
  { value: "Environment, Economy, Development and Sustainability" },
  { value: "Environmental Conservation and Society" },
  { value: "Environmental Control Technologies" },
  { value: "Environmental Design/Architecture" },
  { value: "Environmental Economics & Policy" },
  { value: "Environmental Economics: Management and Policy" },
  { value: "Environmental Engineering" },
  { value: "Environmental Engineering Science" },
  { value: "Environmental Geology (combined majors only)" },
  { value: "Environmental Health Engineering" },
  { value: "Environmental Horticulture" },
  { value: "Environmental Informatics" },
  { value: "Environmental Policy and Decision Making" },
  { value: "Environmental Policy and Planning" },
  { value: "Environmental Resources Management" },
  { value: "Environmental Science" },
  { value: "Environmental Studies" },
  { value: "Environmental/Environmental Health Engineering" },
  { value: "Epidemiology" },
  { value: "Equine Studies" },
  { value: "Ethnic Studies" },
  { value: "EUropean and Transatlantic Studies" },
  { value: "European Area Studies" },
  { value: "European History" },
  { value: "European Language and Transcultural Studies new" },
  {
    value:
      "European Languages and Transcultural Studies with French and Francophone",
  },
  { value: "European Languages and Transcultural Studies with German" },
  { value: "European Languages and Transcultural Studies with Italian" },
  { value: "European Languages and Transcultural Studies with Scandinavian" },
  { value: "European Studies" },
  { value: "Evolution and Ecology" },
  { value: "Evolutionary Anthropology" },
  { value: "Exercise Science Education" },
  { value: "Exercise Science/Physiology/Kinesiology" },
  { value: "Experimental Neuroscience" },
  { value: "Experimental Pathology" },
  { value: "Experimental Psychology" },
  { value: "Explore Science (undecided)" },
  { value: "Exploring Life Sciences (undecided)" },
  { value: "Family & Consumer Sciences, General" },
  { value: "Family and Consumer Sciences" },
  { value: "Farm Management" },
  { value: "Fashion and Retail Studies" },
  { value: "Fashion Design" },
  { value: "Fashion Merchandising" },
  { value: "Fashion Merchandising and Design" },
  { value: "Fashion/Apparel Design" },
  { value: "Feed Science" },
  { value: "Fiber, Textiles, and Weaving Arts" },
  { value: "Film" },
  { value: "Film Studies" },
  { value: "Film, Television, and Media" },
  { value: "Finance" },
  { value: "Finance in Agri-Business" },
  { value: "Finance, General" },
  { value: "Financial Planning" },
  { value: "Financial Planning & Services" },
  { value: "Fine/Studio Arts" },
  { value: "Fire Protection & Safety Technology" },
  { value: "Fish Conservation" },
  { value: "Flexible Nuclear Science and Engineering Degree" },
  { value: "Floral Design and Marketing" },
  { value: "Floriculture" },
  { value: "Food & Nutrition" },
  { value: "Food and Health Systems Economics" },
  { value: "Food Business Management" },
  { value: "Food Science" },
  { value: "Food Science & Human Nutrition" },
  { value: "Food Science and Technology" },
  { value: "Food Sciences & Technology" },
  { value: "Food, Agricultural and Biological Engineering" },
  { value: "Foreign Languages Education" },
  { value: "Foreign Languages/Literatures, General" },
  { value: "Forensic Science" },
  { value: "Forestry" },
  { value: "Forestry, Fisheries and Wildlife" },
  { value: "French" },
  { value: "French and Francophone Studies" },
  { value: "French Language & Literature" },
  { value: "Funeral Services & Mortuary Science" },
  { value: "Furniture Design" },
  { value: "Game Design" },
  { value: "Gay and Lesbian Studies" },
  { value: "Gender and Health" },
  { value: "Gender and Women's Studies" },
  { value: "Gender Studies" },
  { value: "General Engineering" },
  { value: "General Studies" },
  { value: "Genetics" },
  { value: "Geographic Information Science" },
  { value: "Geography" },
  { value: "Geography and Geographic Information Science" },
  { value: "Geography/Environmental Studies" },
  { value: "Geological & Earth Sciences" },
  { value: "Geological Engineering" },
  { value: "Geology" },
  { value: "Geology (combined majors only)" },
  { value: "Geology/Engineering Geology" },
  { value: "Geophysics" },
  { value: "Geosciences" },
  { value: "German" },
  { value: "German Language & Literature" },
  { value: "Germanic Studies" },
  { value: "Gerontology" },
  { value: "Global Economics and Modern Languages" },
  { value: "Global Environment and Health" },
  { value: "Global Studies" },
  { value: "Government" },
  { value: "Graphic & Printing Equipment Operation" },
  { value: "Graphic Design" },
  { value: "Greek" },
  { value: "Greek (Ancient) Language and Literature" },
  { value: "Greek (Modern) Language and Culture" },
  { value: "Greek and Latin" },
  { value: "Greenhouse and Nursery Management" },
  { value: "Health & Physical Education/Fitness" },
  { value: "Health Administration" },
  { value: "Health and Rehabilitation Sciences, Undecided" },
  { value: "Health and Wellness Innovation in Health Care" },
  { value: "Health Education" },
  { value: "Health Information Management and Systems" },
  { value: "Health Promotion, Nutrition and Exercise Science" },
  { value: "Health Science" },
  { value: "Health Sciences Program" },
  { value: "Health Sciences, Interdisciplinary" },
  { value: "Health Services Administration, General" },
  { value: "Health-Related Professions & Services, General" },
  { value: "Health/Medical Technology, General" },
  { value: "Heating/Air Conditioner/Refrigeration Install/Repair" },
  { value: "Hebrew" },
  { value: "Hebrew and Jewish Studies" },
  { value: "Hispanic-American, Puerto Rican, and Chicano Studies" },
  { value: "Historic Preservation" },
  { value: "History" },
  { value: "History and Social Sciences Education" },
  { value: "History of Architecture, Art and Design" },
  { value: "History of Art" },
  { value: "History, Technology, and Society" },
  { value: "Home Economics" },
  { value: "Horse Production and Management" },
  { value: "Horticultural Science" },
  { value: "Horticulture" },
  { value: "Horticulture Operations & Management" },
  { value: "Horticulture Science" },
  { value: "Hospital/Facilities Administration" },
  { value: "Hospitality" },
  { value: "Hospitality and Tourism Management" },
  { value: "Hospitality Management" },
  { value: "Hotel/Motel Management" },
  { value: "Human Biology and Society" },
  { value: "Human Development" },
  { value: "Human Development and Family Science" },
  { value: "Human Development and Family Studies" },
  { value: "Human Nutrition" },
  { value: "Human Nutrition, Foods, and Exercise" },
  { value: "Human Resources" },
  { value: "Human Resources Development/Training" },
  { value: "Human Resources Management" },
  { value: "Human Services" },
  { value: "Humanities and Engineering" },
  { value: "Humanities and Science" },
  { value: "Humanities for Public Service" },
  { value: "Hydraulic Power and Motion Control" },
  { value: "Hydraulic Service and Repair" },
  { value: "Illustration" },
  { value: "Individual Field of Concentration" },
  { value: "Industrial and Operations Engineering" },
  { value: "Industrial and Systems Engineering" },
  { value: "Industrial Design" },
  { value: "Industrial Engineering" },
  { value: "Industrial Management" },
  { value: "Industrial Production Technologies" },
  { value: "Industrial Psychology" },
  { value: "Informatics" },
  { value: "Information" },
  { value: "Information Science" },
  { value: "Information Sciences" },
  { value: "Information Systems" },
  { value: "Information Technology" },
  { value: "Insurance" },
  { value: "Insurance & Risk Management" },
  { value: "Integrated Major in Mathematics and English" },
  { value: "Integrative Biology" },
  { value: "Interarts Performance" },
  { value: "Interdisciplinary Astronomy" },
  { value: "Interdisciplinary Chemical Sciences" },
  { value: "Interdisciplinary Physics" },
  { value: "Interdisciplinary Studies" },
  { value: "Interior Architecture" },
  { value: "Interior Design" },
  { value: "International Affairs" },
  { value: "International Affairs (BS/MS)" },
  { value: "International Affairs and Modern Languages" },
  { value: "International Agriculture" },
  { value: "International Business" },
  { value: "International Business Management" },
  { value: "International Development" },
  { value: "International Development Studies" },
  { value: "International Public Policy" },
  { value: "International Relations" },
  { value: "International Relations & Affairs" },
  { value: "International Security, Norms, and Cooperation" },
  { value: "International Studies" },
  { value: "International Trade and Development" },
  { value: "Investments & Securities" },
  { value: "Iranian Studies" },
  { value: "Islamic Studies" },
  { value: "Italian" },
  { value: "Italian Studies" },
  { value: "Japanese" },
  { value: "Jazz & Contemporary Improvisation" },
  { value: "Jazz Performance" },
  { value: "Jazz Studies" },
  { value: "Jewelry and Metalsmithing" },
  { value: "Jewish Studies" },
  { value: "Journalism" },
  { value: "Journalism, Broadcast" },
  { value: "Journalism, Print" },
  { value: "Judaic Studies" },
  { value: "Junior High/Middle School Education" },
  { value: "Kinesiology" },
  { value: "Korean" },
  { value: "Labor Studies new" },
  { value: "Labor/Industrial Relations" },
  { value: "Land Use Planning and Management" },
  { value: "Landscape Architecture" },
  { value: "Landscape Horticulture" },
  { value: "Landscape Horticulture and Design" },
  { value: "Languages, Literatures, and Cultures" },
  { value: "Languages, Spanish" },
  { value: "Latin" },
  { value: "Latin American and Caribbean Studies" },
  { value: "Latin American and Latino/a Studies" },
  { value: "Latin American Area Studies" },
  { value: "Latin American Studies" },
  { value: "Latin Language and Literature" },
  { value: "Latina/Latino Studies" },
  { value: "Latino/Chicano Studies" },
  { value: "Law (Pre-Law)" },
  { value: "Law Enforcement" },
  { value: "Learning and Education Studies" },
  { value: "Legal Administrative Assisting/Secretarial" },
  { value: "Legal Studies, General" },
  { value: "Liberal Arts" },
  { value: "Liberal Arts & General Studies" },
  { value: "Liberal Arts and Human Sciences (undecided)" },
  { value: "Library Science" },
  { value: "library-1147815_640" },
  { value: "Lighting Design and Technology" },
  { value: "Linguistics" },
  { value: "Linguistics and Anthropology" },
  { value: "Linguistics and Asian Languages and Cultures" },
  { value: "Linguistics and Computer Science" },
  { value: "Linguistics and English" },
  { value: "Linguistics and French" },
  { value: "Linguistics and Italian" },
  { value: "Linguistics and Philosophy" },
  { value: "Linguistics and Psychology" },
  { value: "Linguistics and Scandinavian Languages" },
  { value: "Linguistics and Spanish" },
  { value: "Linguistics, Applied" },
  { value: "List of College Degrees: Architecture" },
  {
    value: "List of College Degrees: Community, Family, and Personal Services",
  },
  { value: "List of College Degrees: Education" },
  { value: "List of College Degrees: English and Foreign Languages" },
  { value: "List of College Degrees: Health Sciences and Technologies" },
  { value: "List of College Degrees: Sciences: Biological and Physical" },
  { value: "List of College Majors: Business" },
  { value: "List of College Majors: Engineering" },
  { value: "List of College Majors: Social Sciences and Law" },
  { value: "Literature" },
  { value: "Literature, Media, and Communication" },
  { value: "Literature, Media, and Communication & Digital Media (BS/MS)" },
  { value: "Livestock Production and Management" },
  { value: "Logistics & Materials Management" },
  { value: "Logistics Management" },
  { value: "Lyric Theatre" },
  { value: "Machine Tool Technology" },
  { value: "Management" },
  { value: "Management Information Systems" },
  { value: "Managerial Economics" },
  { value: "Marine Biology" },
  { value: "Marine Science" },
  { value: "Marine/Aquatic Biology" },
  { value: "Marketing" },
  { value: "Marketing Management & Research" },
  { value: "Mass Communication" },
  { value: "Mass Communications" },
  { value: "Massage Therapy" },
  { value: "Materials Science" },
  { value: "Materials Science and Engineering" },
  { value: "Materials Science and Engineering (BS/MS)" },
  { value: "Mathematical Economics" },
  { value: "Mathematical Sciences" },
  { value: "Mathematics" },
  { value: "Mathematics and Computer Science" },
  { value: "Mathematics Education" },
  { value: "Mathematics for Teaching" },
  { value: "Mathematics of Computation" },
  { value: "Mathematics of Finance and Risk Management" },
  { value: "Mathematics with Computer Science" },
  { value: "Mathematics, Applied" },
  { value: "Mathematics, Data Theory new" },
  { value: "Mathematics, Financial Actuarial" },
  { value: "Mathematics, General" },
  { value: "Mathematics/Applied Science" },
  { value: "Mathematics/Economics" },
  { value: "Mechanical and Ocean Engineering" },
  { value: "Mechanical Drafting/CAD Technology" },
  { value: "Mechanical Engineering" },
  { value: "Mechanical Engineering Technology" },
  { value: "Mechanics & Repairers, General" },
  { value: "Media and Cinema Studies" },
  { value: "Media and Screen Studies" },
  { value: "Media Arts" },
  { value: "Medical Anthropology" },
  { value: "Medical Assisting" },
  { value: "Medical Laboratory Science" },
  { value: "Medical Laboratory Technology" },
  { value: "Medical Office/Secretarial" },
  { value: "Medical Radiologic Technology" },
  { value: "Medical Records" },
  { value: "Medical Technology" },
  { value: "Medical/Clinical Assisting, General" },
  { value: "Medicinal Chemistry" },
  { value: "Medicine (Pre-Medicine)" },
  { value: "Medieval and Renaissance Studies" },
  { value: "Mental Health Counseling" },
  { value: "Mental Health Services" },
  { value: "Merchandising and Buying Operations" },
  { value: "Metallurgical Engineering" },
  { value: "Meteorology" },
  { value: "Metropolitan Food and Environmental Systems" },
  { value: "Microbiology" },
  { value: "Microbiology & Immunology" },
  { value: "Microbiology, Immunology, and Molecular Genetics" },
  { value: "Middle East Studies" },
  { value: "Middle Eastern and North African Studies" },
  { value: "Middle Eastern Languages & Literatures" },
  { value: "Middle Eastern Studies" },
  { value: "Middle Grades Education (Grades 5-8)" },
  { value: "Military Science" },
  { value: "Military Technologies" },
  { value: "Mineral Engineering" },
  { value: "Mining and Mineral Engineering" },
  { value: "Missions" },
  { value: "Modern Greek" },
  { value: "Molecular and Cellular Biology" },
  { value: "Molecular Biology" },
  { value: "Molecular Genetics" },
  { value: "Molecular, Cell, and Developmental Biology" },
  { value: "Molecular, Cellular, and Developmental Biology" },
  { value: "Mortuary Science" },
  { value: "Movement Science" },
  { value: "Moving-Image Production" },
  { value: "Multi/Interdisciplinary Studies" },
  { value: "Multimedia Journalism" },
  { value: "Multimedia/Animation/Special Effects" },
  { value: "Museum Studies" },
  { value: "Music" },
  { value: "Music – Composition" },
  { value: "Music – Education" },
  { value: "Music – Jazz Studies" },
  { value: "Music – Musicology" },
  { value: "Music – Performance (orchestral instruments)" },
  { value: "Music – Performance (piano)" },
  { value: "Music – Performance (voice)" },
  { value: "Music – Theory" },
  { value: "Music Composition" },
  { value: "Music Education" },
  { value: "Music Education (K-12)" },
  { value: "Music History" },
  { value: "Music Instrumental Performance" },
  { value: "Music Management" },
  { value: "Music Open Studies" },
  { value: "Music Technology" },
  { value: "Music Theory" },
  { value: "Music Therapy" },
  { value: "Music Voice Performance" },
  { value: "Music, General" },
  { value: "Music, Performance" },
  { value: "Music, Theory & Composition" },
  { value: "Musical Theater" },
  { value: "Musical Theatre" },
  { value: "Musicology" },
  { value: "Nanomedicine" },
  { value: "Nanoscience" },
  { value: "National Security and Foreign Affairs" },
  { value: "Native American Studies" },
  { value: "Natural Resource Management" },
  { value: "Natural Resources and Environmental Sciences" },
  { value: "Natural Resources Conservation" },
  { value: "Natural Resources Conservation, General" },
  { value: "Natural Resources Management" },
  { value: "Naval Architecture" },
  { value: "Naval Architecture and Marine Engineering" },
  { value: "Neurobiology" },
  { value: "Neuroscience" },
  { value: "Nordic Studies new" },
  { value: "North American Area Studies" },
  { value: "Nuclear and Radiological Engineering" },
  { value: "Nuclear Engineering" },
  { value: "Nuclear Engineering and Radiological Sciences" },
  { value: "Nuclear Medicine Technology" },
  { value: "Nuclear Science and Engineering" },
  { value: "Nuclear, Plasma, and Radiological Engineering" },
  { value: "Nursing" },
  { value: "Nursing (RN to BSN)" },
  { value: "Nursing, Practical/Vocational" },
  { value: "Nursing, Registered" },
  { value: "Nutrition" },
  { value: "Occupational Therapy" },
  { value: "Occupational Therapy Assisting" },
  { value: "Ocean Engineering" },
  { value: "Oceanography" },
  { value: "Office Supervision & Management" },
  { value: "Operations Management" },
  { value: "Operations Management & Supervision" },
  { value: "Optometry (Pre-Optometry)" },
  { value: "Organ" },
  { value: "Organizational and Community Leadership" },
  { value: "Organizational Behavior" },
  { value: "Organizational Behavior Studies" },
  { value: "Organizational Studies" },
  { value: "Osteopathic Medicine" },
  { value: "Packaging Systems and Design" },
  { value: "Painting" },
  { value: "Paleontology" },
  { value: "Paralegal/Legal Assistant" },
  { value: "Parks, Recreation, & Leisure, General" },
  { value: "Parks/Rec/Leisure Facilities Management" },
  { value: "Pastoral Studies" },
  { value: "Peace Studies" },
  { value: "Performing Arts Technology" },
  { value: "Personal Services, General" },
  { value: "Petroleum Engineering" },
  { value: "Pharmaceutical Sciences" },
  { value: "Pharmacology" },
  { value: "Pharmacy" },
  { value: "Pharmacy (Pre-Pharmacy)" },
  { value: "Philosophy" },
  { value: "Philosophy, Politics and Economics" },
  { value: "Philosophy, Politics, and Economics" },
  { value: "Photography" },
  { value: "Photojournalism" },
  { value: "Physical Education" },
  { value: "Physical Education & Coaching" },
  { value: "Physical Sciences, General" },
  { value: "Physical Therapy" },
  { value: "Physical Therapy (Pre-Physical Therapy)" },
  { value: "Physical Therapy Assisting" },
  { value: "Physician Assistant" },
  { value: "Physician Assisting" },
  { value: "Physics" },
  { value: "Physics, Biomedical" },
  { value: "Physics, Engineering" },
  { value: "Physiological Psychology" },
  { value: "Physiological Science" },
  { value: "Piano" },
  { value: "Planetary Science" },
  { value: "Planning" },
  { value: "Plant Biology" },
  { value: "Plant Biotechnology" },
  { value: "Plant Health Management" },
  { value: "Plant Pathology" },
  { value: "Playwriting and Screenwriting" },
  { value: "Policy, International Trade & Development" },
  { value: "Polish" },
  { value: "Political Communication" },
  { value: "Political Economy and Development" },
  { value: "Political Science" },
  { value: "Political Science & Government" },
  { value: "Polymer Chemistry" },
  { value: "Polymers and Soft Matter" },
  { value: "Portuguese" },
  { value: "Portuguese and Brazilian Studies" },
  { value: "Postsecondary Education" },
  { value: "Power Equipment" },
  { value: "Pre-Dentistry" },
  { value: "Pre-Law" },
  { value: "Pre-Medicine" },
  { value: "Pre-Optometry" },
  { value: "Pre-Pharmacy" },
  { value: "Pre-Seminary" },
  { value: "Pre-Veterinary Medicine" },
  { value: "Precision Production Trades, General" },
  { value: "Printmaking" },
  { value: "Professional and Technical Writing" },
  { value: "Professional Golf Management" },
  { value: "Professional Golf Management 1 + 3" },
  { value: "Program" },
  { value: "Property Management" },
  { value: "Protective Services, General" },
  { value: "Psychiatric/Mental Health Technician" },
  { value: "Psychobiology" },
  { value: "Psychology" },
  { value: "Psychology, Clinical & Counseling" },
  { value: "Psychology, General" },
  { value: "Public Administration" },
  { value: "Public Administration & Services, General" },
  { value: "Public Affairs & Public Policy Analysis" },
  { value: "Public Health" },
  { value: "Public Health Sciences" },
  { value: "Public Management, Leadership and Policy" },
  { value: "Public Policy" },
  { value: "Public Policy & Law" },
  { value: "Public Policy Analysis" },
  { value: "Public Relations" },
  { value: "Public Relations & Organizational Communication" },
  { value: "Public Speaking" },
  { value: "Purchasing/Procurement/Contracts Management" },
  { value: "Pure Mathematics" },
  { value: "Quality Control & Safety Technologies" },
  { value: "Radio & Television Broadcasting" },
  { value: "Radio & Television Broadcasting Technology" },
  { value: "Radio and Television" },
  { value: "Radiologic Sciences and Therapy" },
  { value: "Radiologic Technology" },
  { value: "Range Science and Management" },
  { value: "Real Estate" },
  { value: "Real Estate and Urban Analysis" },
  { value: "Recording Arts Technology" },
  { value: "Recreation Management" },
  { value: "Recreation, Sport and Tourism" },
  { value: "Rehabilitation Services" },
  { value: "Rehabilitation Studies" },
  { value: "Rehabilitation Therapy" },
  { value: "Religion" },
  { value: "Religion and Culture" },
  { value: "Religion, Study of" },
  { value: "Religious Education" },
  { value: "Religious Studies" },
  { value: "Residential Environments and Design" },
  { value: "Respiratory Therapy" },
  { value: "Respiratory Therapy Technology" },
  { value: "Restaurant/Food Services Management" },
  { value: "Risk Management" },
  { value: "Romance Languages and Literatures" },
  { value: "Romance Studies" },
  { value: "Rural Sociology" },
  { value: "Russian" },
  { value: "Russian and Eurasian Studies" },
  { value: "Russian Language and Literature" },
  { value: "Russian Studies" },
  { value: "Russian, East European, and Eurasian Studies" },
  { value: "Sales, Merchandising, & Marketing, General" },
  { value: "Scandinavian Studies" },
  { value: "Scenic Design" },
  { value: "Scenic Technology" },
  { value: "Science Education" },
  { value: "Science, Technology and Society" },
  { value: "Sciences" },
  { value: "Sculpture" },
  { value: "Secondary Education" },
  { value: "Secondary Education: Biology" },
  { value: "Secondary Education: Chemistry" },
  { value: "Secondary Education: Earth Science" },
  { value: "Secondary Education: English" },
  { value: "Secondary Education: Mathematics" },
  { value: "Secondary Education: Physics" },
  { value: "Secondary Education: Social Studies" },
  { value: "Secondary Teacher Education" },
  { value: "Secretarial Studies & Office Administration" },
  { value: "Sign Language, American" },
  { value: "Slavic Languages and Literatures" },
  { value: "Slavic Studies" },
  { value: "Small Business Management/Operations" },
  { value: "Smart and Sustainable Cities" },
  { value: "Social Psychology" },
  { value: "Social Sciences Air Transportation" },
  { value: "Social Sciences, General" },
  { value: "Social Studies/Sciences Education" },
  { value: "Social Theory and Practice" },
  { value: "Social Work" },
  { value: "Social Work (Chicago Program)" },
  { value: "Sociology" },
  { value: "Soil Science" },
  { value: "Sound Design and Technology" },
  { value: "Sound Engineering" },
  { value: "South Asian Studies" },
  { value: "Southeast Asia Studies" },
  { value: "Space and Science Engineering" },
  { value: "Spanish" },
  { value: "Spanish and Community and Culture" },
  { value: "Spanish and Linguistics" },
  { value: "Spanish and Portuguese" },
  { value: "Spanish Language & Literature" },
  { value: "Special Education" },
  { value: "Speech and Hearing Science" },
  { value: "Speech Pathology" },
  { value: "Sport & Fitness Administration/Management" },
  { value: "Sport and Leisure Studies" },
  { value: "Sport Industry" },
  { value: "Sport Management" },
  { value: "Sports Coaching, Recreation and Physical Education" },
  { value: "Sports Management" },
  { value: "Sports Media and Analytics" },
  { value: "Sports/Commercial Turf Equipment" },
  { value: "Stage Management" },
  { value: "Statistics" },
  { value: "Statistics and Computer Science" },
  { value: "Statistics and Data Science" },
  { value: "Strategic Business Development and Entrepreneurship" },
  { value: "Strings" },
  { value: "Structural Biology" },
  { value: "Studio Art" },
  { value: "Studio Art (BA)" },
  { value: "Studio Art (BFA)" },
  { value: "Supply Chain Management" },
  { value: "Surgical Technology" },
  { value: "Surveying" },
  { value: "Surveying Technology" },
  { value: "Sustainable Agriculture" },
  { value: "Sustainable Biomaterials" },
  { value: "Sustainable Design" },
  { value: "Sustainable Plant Systems" },
  { value: "Sustainable Resource Management" },
  { value: "Systems Biology" },
  { value: "Systems Engineering and Design" },
  { value: "Teacher Assisting/Aide Education" },
  { value: "Teacher Education" },
  { value: "Teacher Education, General" },
  { value: "Teacher Education, Subject-Specific" },
  { value: "Teacher Education: French (K-12)" },
  { value: "Teacher Education: German (K-12)" },
  { value: "Teacher Education: Kinesiology - Physical Education (K-12)" },
  { value: "Teacher Education: Spanish (K-12)" },
  { value: "Teaching English as a Second Language" },
  { value: "Technical Systems Management" },
  { value: "Technical Writing" },
  { value: "Technology Education" },
  { value: "Textile & Apparel" },
  { value: "Textile Engineering" },
  { value: "Theater Arts" },
  { value: "Theatre" },
  { value: "Theatre & Drama" },
  { value: "Theatre Arts" },
  { value: "Theatre Arts/Drama" },
  { value: "Theatre Studies" },
  { value: "Theology" },
  { value: "Theology, General" },
  { value: "Therapy & Rehabilitation, General" },
  { value: "Tourism" },
  { value: "Tourism & Travel Marketing" },
  { value: "Toxicology" },
  { value: "Toxicology and Environmental Health" },
  { value: "Transportation & Materials Moving (e.g., air, ground, & marine)" },
  { value: "Travel/Tourism Management" },
  { value: "Turfgrass Management" },
  { value: "Turfgrass Science" },
  { value: "Undeclared" },
  { value: "Urban Planning" },
  { value: "Urban Science and Planning with Computer Science" },
  { value: "Urban Studies" },
  { value: "Urban Studies and Planning" },
  { value: "Urban Studies/Urban Affairs" },
  { value: "Urban Technology" },
  { value: "Veterinarian Assisting/Technology" },
  { value: "Veterinary Medicine (Pre-Veterinarian)" },
  { value: "Visual Communication" },
  { value: "Visual Communication Design" },
  { value: "Vocational Rehabilitation Counseling" },
  { value: "Voice" },
  { value: "Water: Resources, Policy, and Management" },
  { value: "Web Design" },
  { value: "Webmaster and Web Management" },
  { value: "Webpage Design" },
  { value: "Welding Engineering" },
  { value: "Welding Technology" },
  { value: "Wildlife & Wildlands Management" },
  { value: "Wildlife Conservation" },
  { value: "Wildlife Management" },
  { value: "Winds and Percussion" },
  { value: "Women's Studies" },
  { value: "Women's, Gender and Sexuality Studies" },
  { value: "Women’s and Gender Studies" },
  { value: "World Literatures" },
  { value: "World Politics" },
  { value: "Writing" },
  { value: "Youth Ministries" },
  { value: "Zoology" },
];

const schools_static = [
  { value: "A.T. Still University" },
  { value: "Abilene Christian University" },
  { value: "Abraham Baldwin Agricultural College" },
  { value: "Academy of Art University" },
  { value: "Adams State University" },
  { value: "Adelphi University" },
  { value: "Adler Graduate School" },
  { value: "Adler University" },
  { value: "Adrian College" },
  { value: "AdventHealth University" },
  { value: "Agnes Scott College" },
  { value: "Air Force Institute of Technology" },
  { value: "Alabama A&M University" },
  { value: "Alabama State University" },
  { value: "Alaska Bible College" },
  { value: "Alaska Pacific University" },
  { value: "Albany College of Pharmacy and Health Sciences" },
  { value: "Albany Law School" },
  { value: "Albany Medical College" },
  { value: "Albany State University" },
  { value: "Albertus Magnus College" },
  { value: "Albion College" },
  { value: "Albright College" },
  { value: "Alcorn State University" },
  { value: "Alderson Broaddus University" },
  { value: "Alfred State College" },
  { value: "Alfred University" },
  { value: "Alice Lloyd College" },
  { value: "Allegheny College" },
  { value: "Allen College" },
  { value: "Allen University" },
  { value: "Alliant International University" },
  { value: "Alma College" },
  { value: "Alvernia University" },
  { value: "Alverno College" },
  { value: "Amberton University" },
  { value: "American Baptist College" },
  { value: "American Film Institute Conservatory" },
  { value: "American International College" },
  { value: "American Jewish University" },
  { value: "American University" },
  { value: "Amherst College" },
  { value: "Anderson University" },
  { value: "Anderson University, Indiana" },
  { value: "Andrews University" },
  { value: "Angelo State University" },
  { value: "Anna Maria College" },
  { value: "Antioch University" },
  { value: "Antioch University Los Angeles" },
  { value: "Antioch University New England" },
  { value: "Antioch University Santa Barbara" },
  { value: "Antioch University Seattle" },
  { value: "Appalachian Bible College" },
  { value: "Appalachian College of Pharmacy" },
  { value: "Appalachian State University" },
  { value: "Aquinas College" },
  { value: "Aquinas College, Tennessee" },
  { value: "Arcadia University" },
  { value: "Arizona Christian University" },
  { value: "Arizona State University" },
  { value: "Arkansas Baptist College" },
  { value: "Arkansas State University" },
  { value: "Arkansas Tech University" },
  { value: "Arlington Baptist University" },
  { value: "Art Academy of Cincinnati" },
  { value: "ArtCenter College of Design" },
  { value: "Asbury University" },
  { value: "Ashland University" },
  { value: "Assumption College" },
  { value: "Athens State University" },
  { value: "Atlanta Metropolitan State College" },
  { value: "Auburn University" },
  { value: "Auburn University at Montgomery" },
  { value: "Augsburg University" },
  { value: "Augusta University" },
  { value: "Augustana College" },
  { value: "Augustana University" },
  { value: "Aurora University" },
  { value: "Austin College" },
  { value: "Austin Peay State University" },
  { value: "Ave Maria University" },
  { value: "Averett University" },
  { value: "Avila University" },
  { value: "Azusa Pacific University" },
  { value: "Babson College" },
  { value: "Bacone College" },
  { value: "Baker College" },
  { value: "Baker University" },
  { value: "Baldwin Wallace University" },
  { value: "Ball State University" },
  { value: "Bank Street College of Education" },
  { value: "Baptist Bible College" },
  { value: "Baptist Health Sciences University" },
  { value: "Baptist University of the Americas" },
  { value: "Barclay College" },
  { value: "Bard College" },
  { value: "Bard College at Simon's Rock" },
  { value: "Barnard College" },
  { value: "Barry University" },
  { value: "Barton College" },
  { value: "Baruch College, CUNY" },
  { value: "Bastyr University" },
  { value: "Bates College" },
  { value: "Bay Path University" },
  { value: "Bay State College" },
  { value: "Baylor College of Medicine" },
  { value: "Baylor University" },
  { value: "Beacon College" },
  { value: "Becker College" },
  { value: "Belhaven University" },
  { value: "Bellarmine University" },
  { value: "Bellevue College" },
  { value: "Bellevue University" },
  { value: "Bellin College" },
  { value: "Belmont Abbey College" },
  { value: "Belmont University" },
  { value: "Beloit College" },
  { value: "Bemidji State University" },
  { value: "Benedict College" },
  { value: "Benedictine College" },
  { value: "Benedictine University" },
  { value: "Benjamin Franklin Institute of Technology" },
  { value: "Bennett College" },
  { value: "Bennington College" },
  { value: "Bentley University" },
  { value: "Berea College" },
  { value: "Berkeley College" },
  { value: "Berklee College of Music" },
  { value: "Berry College" },
  { value: "Bethany College" },
  { value: "Bethany College, Kansas" },
  { value: "Bethany Lutheran College" },
  { value: "Bethel College" },
  { value: "Bethel University" },
  { value: "Bethesda University" },
  { value: "Bethune-Cookman University" },
  { value: "Beulah Heights University" },
  { value: "Binghamton University, State University of New York" },
  { value: "Biola University" },
  { value: "Birmingham-Southern College" },
  { value: "Bismarck State College" },
  { value: "Black Hills State University" },
  { value: "Blackburn College" },
  { value: "Blessing-Rieman College of Nursing and Health Sciences" },
  { value: "Bloomfield College" },
  { value: "Bloomsburg University of Pennsylvania" },
  { value: "Blue Mountain College" },
  { value: "Bluefield College" },
  { value: "Bluefield State College" },
  { value: "Bluffton University" },
  { value: "Bob Jones University" },
  { value: "Boise Bible College" },
  { value: "Boise State University" },
  { value: "Boricua College" },
  { value: "Boston Architectural College" },
  { value: "Boston College" },
  { value: "Boston Graduate School of Psychoanalysis" },
  { value: "Boston University" },
  { value: "Bowdoin College" },
  { value: "Bowie State University" },
  { value: "Bowling Green State University" },
  { value: "Bradley University" },
  { value: "Brandeis University" },
  { value: "Brandman University" },
  { value: "Brazosport College" },
  { value: "Brenau University" },
  { value: "Brescia University" },
  { value: "Brevard College" },
  { value: "Brewton-Parker College" },
  { value: "Briar Cliff University" },
  { value: "Bridgewater College" },
  { value: "Bridgewater State University" },
  { value: "Brigham Young University" },
  { value: "Brigham Young University-Hawaii" },
  { value: "Brigham Young University-Idaho" },
  { value: "Brooklyn College" },
  { value: "Brooklyn Law School" },
  { value: "Broward College" },
  { value: "Brown University" },
  { value: "Bryan College" },
  { value: "Bryan College of Health Sciences" },
  { value: "Bryant and Stratton College" },
  { value: "Bryant University" },
  { value: "Bryn Athyn College" },
  { value: "Bryn Mawr College" },
  { value: "Bucknell University" },
  { value: "Buena Vista University" },
  { value: "Buffalo State College" },
  { value: "Bushnell University" },
  { value: "Butler University" },
  { value: "Cabarrus College of Health Sciences" },
  { value: "Cabrini University" },
  { value: "Cairn University" },
  { value: "Caldwell University" },
  { value: "California Baptist University" },
  { value: "California College of the Arts" },
  { value: "California Institute of Integral Studies" },
  { value: "California Institute of Technology" },
  { value: "California Institute of the Arts" },
  { value: "California Lutheran University" },
  { value: "California Polytechnic State University, San Luis Obispo" },
  { value: "California State Polytechnic University, Pomona" },
  { value: "California State University Channel Islands" },
  { value: "California State University Maritime Academy" },
  { value: "California State University San Marcos" },
  { value: "California State University, Bakersfield" },
  { value: "California State University, Chico" },
  { value: "California State University, Dominguez Hills" },
  { value: "California State University, East Bay" },
  { value: "California State University, Fresno" },
  { value: "California State University, Fullerton" },
  { value: "California State University, Long Beach" },
  { value: "California State University, Los Angeles" },
  { value: "California State University, Monterey Bay" },
  { value: "California State University, Northridge" },
  { value: "California State University, Sacramento" },
  { value: "California State University, San Bernardino" },
  { value: "California State University, Stanislaus" },
  { value: "California University of Pennsylvania" },
  { value: "California Western School of Law" },
  { value: "Calumet College of St. Joseph" },
  { value: "Calvary University" },
  { value: "Calvin University" },
  { value: "Cambridge College" },
  { value: "Cameron University" },
  { value: "Campbell University" },
  { value: "Campbellsville University" },
  { value: "Canisius College" },
  { value: "Capital University" },
  { value: "Capitol Technology University" },
  { value: "Cardinal Stritch University" },
  { value: "Carleton College" },
  { value: "Carlow University" },
  { value: "Carnegie Mellon University" },
  { value: "Carolina University" },
  { value: "Carroll College" },
  { value: "Carroll University" },
  { value: "Carson-Newman University" },
  { value: "Carthage College" },
  { value: "Case Western Reserve University" },
  { value: "Castleton University" },
  { value: "Catawba College" },
  { value: "Cazenovia College" },
  { value: "Cedar Crest College" },
  { value: "Cedarville University" },
  { value: "Centenary College of Louisiana" },
  { value: "Centenary University" },
  { value: "Central Baptist College" },
  { value: "Central Christian College of Kansas" },
  { value: "Central Christian College of the Bible" },
  { value: "Central College" },
  { value: "Central Connecticut State University" },
  { value: "Central Methodist University" },
  { value: "Central Michigan University" },
  { value: "Central Penn College" },
  { value: "Central State University" },
  { value: "Central Washington University" },
  { value: "Centralia College" },
  { value: "Centre College" },
  { value: "Chadron State College" },
  { value: "Chamberlain University" },
  { value: "Chaminade University of Honolulu" },
  { value: "Champlain College" },
  { value: "Chapman University" },
  { value: "Charles R. Drew University of Medicine and Science" },
  { value: "Charleston Southern University" },
  { value: "Chatham University" },
  { value: "Chestnut Hill College" },
  { value: "Cheyney University of Pennsylvania" },
  { value: "Chicago State University" },
  { value: "Chipola College" },
  { value: "Chowan University" },
  { value: "Christian Brothers University" },
  { value: "Christopher Newport University" },
  { value: "City University of Seattle" },
  { value: "Claflin University" },
  { value: "Claremont Graduate University" },
  { value: "Claremont McKenna College" },
  { value: "Clarion University of Pennsylvania" },
  { value: "Clark Atlanta University" },
  { value: "Clark University" },
  { value: "Clarke University" },
  { value: "Clarks Summit University" },
  { value: "Clarkson College" },
  { value: "Clarkson University" },
  { value: "Clayton State University" },
  { value: "Clear Creek Baptist Bible College" },
  { value: "Cleary University" },
  { value: "Clemson University" },
  { value: "Cleveland Institute of Art" },
  { value: "Cleveland Institute of Music" },
  { value: "Cleveland State University" },
  { value: "Cleveland University-Kansas City" },
  { value: "Coastal Carolina University" },
  { value: "Coe College" },
  { value: "Cogswell University of Silicon Valley" },
  { value: "Coker University" },
  { value: "Colby College" },
  { value: "Colby-Sawyer College" },
  { value: "Colgate University" },
  { value: "College for Creative Studies" },
  { value: "College of Biblical Studies" },
  { value: "College of Central Florida" },
  { value: "College of Charleston" },
  { value: "College of Coastal Georgia" },
  { value: "College of Mount Saint Vincent" },
  { value: "College of Our Lady of the Elms" },
  { value: "College of Saint Benedict/Saint John's University" },
  { value: "College of Saint Mary" },
  { value: "College of Southern Nevada" },
  { value: "College of Staten Island" },
  { value: "College of the Atlantic" },
  { value: "College of the Holy Cross" },
  { value: "College of the Ozarks" },
  { value: "College of William & Mary" },
  { value: "Colorado Christian University" },
  { value: "Colorado College" },
  { value: "Colorado Mesa University" },
  { value: "Colorado School of Mines" },
  { value: "Colorado State University" },
  { value: "Colorado State University-Pueblo" },
  { value: "Colorado Technical University" },
  { value: "Columbia Basin College" },
  { value: "Columbia College" },
  { value: "Columbia College Chicago" },
  { value: "Columbia College Hollywood" },
  { value: "Columbia College, South Carolina" },
  { value: "Columbia International University" },
  { value: "Columbia University in the City of New York" },
  { value: "Columbus College of Art and Design" },
  { value: "Columbus State University" },
  { value: "Concord University" },
  { value: "Concordia College" },
  { value: "Concordia College-New York" },
  { value: "Concordia University Ann Arbor" },
  { value: "Concordia University Chicago" },
  { value: "Concordia University Irvine" },
  { value: "Concordia University Texas" },
  { value: "Concordia University Wisconsin" },
  { value: "Concordia University, Nebraska" },
  { value: "Concordia University, St. Paul" },
  { value: "Connecticut College" },
  { value: "Converse College" },
  { value: "Conway School of Landscape Design" },
  { value: "Coppin State University" },
  { value: "Corban University" },
  { value: "Cornell College" },
  { value: "Cornell University" },
  { value: "Cornerstone University" },
  { value: "Cornish College of the Arts" },
  { value: "Cottey College" },
  { value: "Covenant College" },
  { value: "Cox College" },
  { value: "Cranbrook Academy of Art" },
  { value: "Creighton University" },
  { value: "Criswell College" },
  { value: "Crowley's Ridge College" },
  { value: "Crown College" },
  { value: "Culver-Stockton College" },
  { value: "Cumberland University" },
  { value: "CUNY School of Law" },
  { value: "Curry College" },
  { value: "Curtis Institute of Music" },
  { value: "Daemen College" },
  { value: "Dakota State University" },
  { value: "Dakota Wesleyan University" },
  { value: "Dallas Baptist University" },
  { value: "Dallas Christian College" },
  { value: "Dalton State College" },
  { value: "Dartmouth College" },
  { value: "Davenport University" },
  { value: "Davidson College" },
  { value: "Davis and Elkins College" },
  { value: "Davis College" },
  { value: "Daytona State College" },
  { value: "Dean College" },
  { value: "Defiance College" },
  { value: "Delaware State University" },
  { value: "Delaware Valley University" },
  { value: "Delta State University" },
  { value: "Denison University" },
  { value: "Denver College of Nursing" },
  { value: "DePaul University" },
  { value: "DePauw University" },
  { value: "Des Moines University" },
  { value: "DeSales University" },
  { value: "DeVry University" },
  { value: "Dharma Realm Buddhist University" },
  { value: "Dickinson College" },
  { value: "Dickinson State University" },
  { value: "Dillard University" },
  { value: "Dixie State University" },
  { value: "Doane University" },
  { value: "Dominican College" },
  { value: "Dominican School of Philosophy & Theology" },
  { value: "Dominican University" },
  { value: "Dominican University of California" },
  { value: "Donnelly College" },
  { value: "Dordt University" },
  { value: "Drake University" },
  { value: "Drew University" },
  { value: "Drexel University" },
  { value: "Drury University" },
  { value: "Duke University" },
  { value: "Dunwoody College of Technology" },
  { value: "Duquesne University" },
  { value: "D'Youville College" },
  { value: "Earlham College" },
  { value: "East Carolina University" },
  { value: "East Central University" },
  { value: "East Georgia State College" },
  { value: "East Stroudsburg University" },
  { value: "East Tennessee State University" },
  { value: "East Texas Baptist University" },
  { value: "Eastern Connecticut State University" },
  { value: "Eastern Illinois University" },
  { value: "Eastern Kentucky University" },
  { value: "Eastern Mennonite University" },
  { value: "Eastern Michigan University" },
  { value: "Eastern Nazarene College" },
  { value: "Eastern New Mexico University" },
  { value: "Eastern Oregon University" },
  { value: "Eastern University" },
  { value: "Eastern Virginia Medical School" },
  { value: "Eastern Washington University" },
  { value: "East-West University" },
  { value: "Ecclesia College" },
  { value: "Eckerd College" },
  { value: "ECPI University" },
  { value: "Edgewood College" },
  { value: "Edinboro University of Pennsylvania" },
  { value: "Edward Waters College" },
  { value: "Elizabeth City State University" },
  { value: "Elizabethtown College" },
  { value: "Elmhurst University" },
  { value: "Elmira College" },
  { value: "Elon University" },
  { value: "Embry-Riddle Aeronautical University" },
  { value: "Emerson College" },
  { value: "Emmanuel College" },
  { value: "Emmanuel College, Georgia" },
  { value: "Emmaus Bible College" },
  { value: "Emory & Henry College" },
  { value: "Emory University" },
  { value: "Emporia State University" },
  { value: "Endicott College" },
  { value: "Erskine College" },
  { value: "Eureka College" },
  { value: "Evangel University" },
  { value: "Everglades University" },
  { value: "Fairfield University" },
  { value: "Fairleigh Dickinson University" },
  { value: "Fairmont State University" },
  { value: "Faith Baptist Bible College and Theological Seminary" },
  { value: "Farmingdale State College" },
  { value: "Fashion Institute of Technology" },
  { value: "Faulkner University" },
  { value: "Fayetteville State University" },
  { value: "Felician University" },
  { value: "Ferris State University" },
  { value: "Ferrum College" },
  { value: "Fielding Graduate University" },
  { value: "Finlandia University" },
  { value: "Fisher College" },
  { value: "Fisk University" },
  { value: "Fitchburg State University" },
  { value: "Five Towns College" },
  { value: "Flagler College" },
  { value: "Florida Agricultural and Mechanical University" },
  { value: "Florida Atlantic University" },
  { value: "Florida College" },
  { value: "Florida Gateway College" },
  { value: "Florida Gulf Coast University" },
  { value: "Florida Institute of Technology" },
  { value: "Florida International University" },
  { value: "Florida Memorial University" },
  { value: "Florida National University" },
  { value: "Florida Southern College" },
  { value: "Florida SouthWestern State College" },
  { value: "Florida State College at Jacksonville" },
  { value: "Florida State University" },
  { value: "Fontbonne University" },
  { value: "Fordham University" },
  { value: "Fort Hays State University" },
  { value: "Fort Lewis College" },
  { value: "Fort Valley State University" },
  { value: "Framingham State University" },
  { value: "Francis Marion University" },
  { value: "Franciscan Missionaries of Our Lady University" },
  { value: "Franciscan University of Steubenville" },
  { value: "Franklin and Marshall College" },
  { value: "Franklin College" },
  { value: "Franklin Pierce University" },
  { value: "Franklin University" },
  { value: "Franklin W. Olin College of Engineering" },
  { value: "Freed-Hardeman University" },
  { value: "Fresno Pacific University" },
  { value: "Friends University" },
  { value: "Frostburg State University" },
  { value: "Furman University" },
  { value: "Gallaudet University" },
  { value: "Gannon University" },
  { value: "Gardner-Webb University" },
  { value: "Geisinger Commonwealth School of Medicine" },
  { value: "Geneva College" },
  { value: "George Fox University" },
  { value: "George Mason University" },
  { value: "George Washington University" },
  { value: "Georgetown College" },
  { value: "Georgetown University" },
  { value: "Georgia College and State University" },
  { value: "Georgia Gwinnett College" },
  { value: "Georgia Institute of Technology" },
  { value: "Georgia Southern University" },
  { value: "Georgia Southwestern State University" },
  { value: "Georgia State University" },
  { value: "Georgian Court University" },
  { value: "Gettysburg College" },
  { value: "Glenville State College" },
  { value: "Goddard College" },
  { value: "God's Bible School and College" },
  { value: "Golden Gate University" },
  { value: "Goldey-Beacom College" },
  { value: "Goldfarb School of Nursing at Barnes-Jewish College" },
  { value: "Gonzaga University" },
  { value: "Good Samaritan College of Nursing and Health Science" },
  { value: "Goodwin University" },
  { value: "Gordon College" },
  { value: "Gordon State College" },
  { value: "Goshen College" },
  { value: "Goucher College" },
  { value: "Governors State University" },
  { value: "Grace Christian University" },
  { value: "Grace College" },
  { value: "Graceland University" },
  { value: "Grambling State University" },
  { value: "Grand Canyon University" },
  { value: "Grand Valley State University" },
  { value: "Grand View University" },
  { value: "Granite State College" },
  { value: "Gratz College" },
  { value: "Great Basin College" },
  { value: "Great Lakes Christian College" },
  { value: "Greensboro College" },
  { value: "Greenville University" },
  { value: "Grinnell College" },
  { value: "Grove City College" },
  { value: "Guilford College" },
  { value: "Gulf Coast State College" },
  { value: "Gustavus Adolphus College" },
  { value: "Gwynedd Mercy University" },
  { value: "Hamilton College" },
  { value: "Hamline University" },
  { value: "Hampden-Sydney College" },
  { value: "Hampshire College" },
  { value: "Hampton University" },
  { value: "Hannibal-LaGrange University" },
  { value: "Hanover College" },
  { value: "Harding University" },
  { value: "Hardin-Simmons University" },
  { value: "Harrisburg University of Science and Technology" },
  { value: "Harris-Stowe State University" },
  { value: "Hartwick College" },
  { value: "Harvard University" },
  { value: "Harvey Mudd College" },
  { value: "Hastings College" },
  { value: "Haverford College" },
  { value: "Hawaii Pacific University" },
  { value: "Hebrew College" },
  { value: "Heidelberg University" },
  { value: "Helene Fuld College of Nursing" },
  { value: "Henderson State University" },
  { value: "Hendrix College" },
  { value: "Heritage Christian University" },
  { value: "Heritage University" },
  { value: "Herzing University" },
  { value: "High Point University" },
  { value: "Hilbert College" },
  { value: "Hillsdale College" },
  { value: "Hiram College" },
  { value: "Hobart and William Smith Colleges" },
  { value: "Hodges University" },
  { value: "Hofstra University" },
  { value: "Hollins University" },
  { value: "Holy Apostles College and Seminary" },
  { value: "Holy Cross College" },
  { value: "Holy Family University" },
  { value: "Holy Names University" },
  { value: "Hood College" },
  { value: "Hope College" },
  { value: "Hope International University" },
  { value: "Houghton College" },
  { value: "Houston Baptist University" },
  { value: "Howard Payne University" },
  { value: "Howard University" },
  { value: "Hult International Business School" },
  { value: "Humboldt State University" },
  { value: "Humphreys University" },
  { value: "Hunter College, CUNY" },
  { value: "Huntingdon College" },
  { value: "Huntington University" },
  { value: "Husson University" },
  { value: "Huston-Tillotson University" },
  { value: "Icahn School of Medicine at Mount Sinai" },
  { value: "Idaho State University" },
  { value: "Illinois College" },
  { value: "Illinois College of Optometry" },
  { value: "Illinois Institute of Technology" },
  { value: "Illinois State University" },
  { value: "Illinois Wesleyan University" },
  { value: "Immaculata University" },
  { value: "Indian River State College" },
  { value: "Indiana Institute of Technology" },
  { value: "Indiana State University" },
  { value: "Indiana University - Purdue University Indianapolis" },
  { value: "Indiana University Bloomington" },
  { value: "Indiana University East" },
  { value: "Indiana University Fort Wayne" },
  { value: "Indiana University Kokomo" },
  { value: "Indiana University Northwest" },
  { value: "Indiana University of Pennsylvania" },
  { value: "Indiana University South Bend" },
  { value: "Indiana University Southeast" },
  { value: "Indiana Wesleyan University" },
  { value: "Iona College" },
  { value: "Iowa State University" },
  { value: "Iowa Wesleyan University" },
  { value: "Irell and Manella Graduate School of Biological Sciences" },
  { value: "Ithaca College" },
  { value: "Jackson State University" },
  { value: "Jacksonville State University" },
  { value: "Jacksonville University" },
  { value: "James Madison University" },
  { value: "Jamestown Business College" },
  { value: "Jarvis Christian College" },
  { value: "John Brown University" },
  { value: "John Carroll University" },
  { value: "John F. Kennedy University" },
  { value: "John Jay College of Criminal Justice" },
  { value: "John Paul the Great Catholic University" },
  { value: "Johns Hopkins University" },
  { value: "Johnson and Wales University" },
  { value: "Johnson C. Smith University" },
  { value: "Johnson University" },
  { value: "Judson College" },
  { value: "Judson University" },
  { value: "Juniata College" },
  { value: "Kalamazoo College" },
  { value: "Kansas City Art Institute" },
  { value: "Kansas City University" },
  { value: "Kansas State University" },
  { value: "Kansas Wesleyan University" },
  { value: "Kean University" },
  { value: "Keck Graduate Institute" },
  { value: "Keene State College" },
  { value: "Keiser University" },
  { value: "Kennesaw State University" },
  { value: "Kent State University" },
  { value: "Kentucky Christian University" },
  { value: "Kentucky State University" },
  { value: "Kentucky Wesleyan College" },
  { value: "Kenyon College" },
  { value: "Kettering College" },
  { value: "Kettering University" },
  { value: "Keuka College" },
  { value: "Keystone College" },
  { value: "King University" },
  { value: "King's College" },
  { value: "Knox College" },
  { value: "Kutztown University of Pennsylvania" },
  { value: "Kuyper College" },
  { value: "La Roche University" },
  { value: "La Salle University" },
  { value: "La Sierra University" },
  { value: "Labouré College" },
  { value: "Lafayette College" },
  { value: "LaGrange College" },
  { value: "Laguna College of Art and Design" },
  { value: "Lake Erie College" },
  { value: "Lake Forest College" },
  { value: "Lake Forest Graduate School of Management" },
  { value: "Lake Superior State University" },
  { value: "Lake Washington Institute of Technology" },
  { value: "Lakeland University" },
  { value: "Lakeview College of Nursing" },
  { value: "Lamar University" },
  { value: "Lancaster Bible College" },
  { value: "Lander University" },
  { value: "Landmark College" },
  { value: "Lane College" },
  { value: "Langston University" },
  { value: "Lasell University" },
  { value: "Lawrence Technological University" },
  { value: "Lawrence University" },
  { value: "Le Moyne College" },
  { value: "Lebanon Valley College" },
  { value: "Lee University" },
  { value: "Lees-McRae College" },
  { value: "Lehigh University" },
  { value: "Lehman College, CUNY" },
  { value: "LeMoyne-Owen College" },
  { value: "Lenoir-Rhyne University" },
  { value: "Lesley University" },
  { value: "LeTourneau University" },
  { value: "Lewis & Clark College" },
  { value: "Lewis University" },
  { value: "Lewis-Clark State College" },
  { value: "Liberty University" },
  { value: "Life Pacific College" },
  { value: "Life University" },
  { value: "LIM College" },
  { value: "Limestone College" },
  { value: "Lincoln Christian University" },
  { value: "Lincoln College" },
  { value: "Lincoln Memorial University" },
  { value: "Lincoln University" },
  { value: "Lincoln University, Missouri" },
  { value: "Lindenwood University" },
  { value: "Lindsey Wilson College" },
  { value: "Linfield University" },
  { value: "Lipscomb University" },
  { value: "Livingstone College" },
  { value: "Lock Haven University" },
  { value: "Logan University" },
  { value: "Loma Linda University" },
  { value: "Long Island University" },
  { value: "Longwood University" },
  { value: "Loras College" },
  { value: "Louisiana College" },
  { value: "Louisiana State University" },
  { value: "Louisiana State University Health Sciences Center" },
  { value: "Louisiana State University Health Sciences Center-Shreveport" },
  { value: "Louisiana State University in Shreveport" },
  { value: "Louisiana State University of Alexandria" },
  { value: "Louisiana Tech University" },
  { value: "Lourdes University" },
  { value: "Loyola Marymount University" },
  { value: "Loyola University Chicago" },
  { value: "Loyola University Maryland" },
  { value: "Loyola University New Orleans" },
  { value: "Lubbock Christian University" },
  { value: "Luther College" },
  { value: "Lycoming College" },
  { value: "Lynn University" },
  { value: "Lyon College" },
  { value: "Macalester College" },
  { value: "MacMurray College" },
  { value: "Madonna University" },
  { value: "Magdalen College of the Liberal Arts" },
  { value: "Maharishi International University" },
  { value: "Maine College of Art" },
  { value: "Maine Maritime Academy" },
  { value: "Malone University" },
  { value: "Manchester University" },
  { value: "Manhattan Christian College" },
  { value: "Manhattan College" },
  { value: "Manhattan School of Music" },
  { value: "Manhattanville College" },
  { value: "Mansfield University of Pennsylvania" },
  { value: "Maranatha Baptist University" },
  { value: "Maria College" },
  { value: "Marian University" },
  { value: "Marian University, Wisconsin" },
  { value: "Marietta College" },
  { value: "Marist College" },
  { value: "Marlboro College" },
  { value: "Marquette University" },
  { value: "Mars Hill University" },
  { value: "Marshall B. Ketchum University" },
  { value: "Marshall University" },
  { value: "Martin Luther College" },
  { value: "Martin Methodist College" },
  { value: "Martin University" },
  { value: "Mary Baldwin University" },
  { value: "Maryland Institute College of Art" },
  { value: "Maryland University of Integrative Health" },
  { value: "Marymount California University" },
  { value: "Marymount Manhattan College" },
  { value: "Marymount University" },
  { value: "Maryville College" },
  { value: "Maryville University" },
  { value: "Marywood University" },
  { value: "Massachusetts College of Art and Design" },
  { value: "Massachusetts College of Liberal Arts" },
  { value: "Massachusetts Institute of Technology" },
  { value: "Massachusetts Maritime Academy" },
  { value: "Massachusetts School of Law" },
  { value: "Mayo Clinic College of Medicine and Science" },
  { value: "Mayville State University" },
  { value: "McDaniel College" },
  { value: "McKendree University" },
  { value: "McMurry University" },
  { value: "McNeese State University" },
  { value: "McPherson College" },
  { value: "MCPHS University" },
  { value: "Medaille College" },
  { value: "Medgar Evers College" },
  { value: "Medical College of Wisconsin" },
  { value: "Medical University of South Carolina" },
  { value: "Meharry Medical College" },
  { value: "Memphis College of Art" },
  { value: "Menlo College" },
  { value: "Mercer University" },
  { value: "Mercy College" },
  { value: "Mercy College of Health Sciences" },
  { value: "Mercy College of Ohio" },
  { value: "Mercyhurst University" },
  { value: "Meredith College" },
  { value: "Merrimack College" },
  { value: "Messiah University" },
  { value: "Methodist College" },
  { value: "Methodist University" },
  { value: "Metropolitan College of New York" },
  { value: "Metropolitan State University" },
  { value: "Metropolitan State University of Denver" },
  { value: "MGH Institute of Health Professions" },
  { value: "Miami Dade College" },
  { value: "Miami University" },
  { value: "Michigan School of Psychology" },
  { value: "Michigan State University" },
  { value: "Michigan Technological University" },
  { value: "Mid-America Christian University" },
  { value: "MidAmerica Nazarene University" },
  { value: "Mid-Atlantic Christian University" },
  { value: "Middle Georgia State University" },
  { value: "Middle Tennessee School of Anesthesia" },
  { value: "Middle Tennessee State University" },
  { value: "Middlebury College" },
  { value: "Midland College" },
  { value: "Midland University" },
  { value: "Midway University" },
  { value: "Midwestern State University" },
  { value: "Midwestern University" },
  { value: "Miles College" },
  { value: "Millersville University of Pennsylvania" },
  { value: "Milligan University" },
  { value: "Millikin University" },
  { value: "Mills College" },
  { value: "Millsaps College" },
  { value: "Milwaukee Institute of Art and Design" },
  { value: "Milwaukee School of Engineering" },
  { value: "Minneapolis College of Art and Design" },
  { value: "Minnesota State University Moorhead" },
  { value: "Minnesota State University, Mankato" },
  { value: "Minot State University" },
  { value: "Misericordia University" },
  { value: "Mississippi College" },
  { value: "Mississippi State University" },
  { value: "Mississippi University for Women" },
  { value: "Mississippi Valley State University" },
  { value: "Missouri Baptist University" },
  { value: "Missouri Southern State University" },
  { value: "Missouri State University" },
  { value: "Missouri University of Science and Technology" },
  { value: "Missouri Valley College" },
  { value: "Missouri Western State University" },
  { value: "Mitchell College" },
  { value: "Mitchell Hamline School of Law" },
  { value: "Molloy College" },
  { value: "Monmouth College" },
  { value: "Monmouth University" },
  { value: "Monroe College" },
  { value: "Montana State University" },
  { value: "Montana State University Billings" },
  { value: "Montana State University-Northern" },
  { value: "Montana Tech" },
  { value: "Montclair State University" },
  { value: "Montreat College" },
  { value: "Montserrat College of Art" },
  { value: "Moody Bible Institute" },
  { value: "Moore College of Art and Design" },
  { value: "Moravian College" },
  { value: "Morehead State University" },
  { value: "Morehouse College" },
  { value: "Morehouse School of Medicine" },
  { value: "Morgan State University" },
  { value: "Morningside College" },
  { value: "Morris College" },
  { value: "Morrisville State College" },
  { value: "Mount Aloysius College" },
  { value: "Mount Carmel College of Nursing" },
  { value: "Mount Holyoke College" },
  { value: "Mount Marty University" },
  { value: "Mount Mary University" },
  { value: "Mount Mercy University" },
  { value: "Mount Saint Mary College" },
  { value: "Mount Saint Mary's University" },
  { value: "Mount St. Joseph University" },
  { value: "Mount St. Mary's University" },
  { value: "Mount Vernon Nazarene University" },
  { value: "Muhlenberg College" },
  { value: "Multnomah University" },
  { value: "Murray State University" },
  { value: "Muskingum University" },
  { value: "Naropa University" },
  { value: "National American University" },
  { value: "National Defense University" },
  { value: "National Louis University" },
  { value: "National University" },
  { value: "National University of Health Sciences" },
  { value: "Naval Postgraduate School" },
  { value: "Nazarene Bible College" },
  { value: "Nazareth College" },
  { value: "Nebraska Christian College" },
  { value: "Nebraska Methodist College" },
  { value: "Nebraska Wesleyan University" },
  { value: "Neumann University" },
  { value: "Nevada State College" },
  { value: "New College of Florida" },
  { value: "New England College" },
  { value: "New England College of Optometry" },
  { value: "New England Conservatory" },
  { value: "New England Institute of Technology" },
  { value: "New England Law | Boston" },
  { value: "New Hope Christian College" },
  { value: "New Jersey City University" },
  { value: "New Jersey Institute of Technology" },
  { value: "New Mexico Highlands University" },
  { value: "New Mexico Institute of Mining and Technology" },
  { value: "New Mexico State University" },
  { value: "New York Academy of Art" },
  { value: "New York City College of Technology, CUNY" },
  { value: "New York College of Podiatric Medicine" },
  { value: "New York Institute of Technology" },
  { value: "New York Law School" },
  { value: "New York Medical College" },
  { value: "New York School of Interior Design" },
  { value: "New York University" },
  { value: "Newberry College" },
  { value: "Newman University" },
  { value: "NewSchool of Architecture and Design" },
  { value: "Niagara University" },
  { value: "Nicholls State University" },
  { value: "Nichols College" },
  { value: "Norfolk State University" },
  { value: "North Carolina Agricultural and Technical State University" },
  { value: "North Carolina Central University" },
  { value: "North Carolina State University" },
  { value: "North Carolina Wesleyan College" },
  { value: "North Central College" },
  { value: "North Central University" },
  { value: "North Dakota State University" },
  { value: "North Greenville University" },
  { value: "North Park University" },
  { value: "Northeast Ohio Medical University" },
  { value: "Northeastern Illinois University" },
  { value: "Northeastern State University" },
  { value: "Northeastern University" },
  { value: "Northern Arizona University" },
  { value: "Northern Illinois University" },
  { value: "Northern Kentucky University" },
  { value: "Northern Michigan University" },
  { value: "Northern New Mexico College" },
  { value: "Northern State University" },
  { value: "Northern Vermont University" },
  { value: "Northland College" },
  { value: "Northpoint Bible College" },
  { value: "Northwest Florida State College" },
  { value: "Northwest Missouri State University" },
  { value: "Northwest Nazarene University" },
  { value: "Northwest University" },
  { value: "Northwestern College" },
  { value: "Northwestern Health Sciences University" },
  { value: "Northwestern Oklahoma State University" },
  { value: "Northwestern State University of Louisiana" },
  { value: "Northwestern University" },
  { value: "Northwood University" },
  { value: "Norwich University" },
  { value: "Notre Dame College" },
  { value: "Notre Dame de Namur University" },
  { value: "Notre Dame of Maryland University" },
  { value: "Nova Southeastern University" },
  { value: "Nyack College" },
  { value: "Oak Hills Christian College" },
  { value: "Oakland City University" },
  { value: "Oakland University" },
  { value: "Oakwood University" },
  { value: "Oberlin College" },
  { value: "Occidental College" },
  { value: "Oglethorpe University" },
  { value: "Ohio Christian University" },
  { value: "Ohio Dominican University" },
  { value: "Ohio Northern University" },
  { value: "Ohio University" },
  { value: "Ohio University Southern" },
  { value: "Ohio University-Chillicothe" },
  { value: "Ohio Valley University" },
  { value: "Ohio Wesleyan University" },
  { value: "Oklahoma Baptist University" },
  { value: "Oklahoma Christian University" },
  { value: "Oklahoma City University" },
  { value: "Oklahoma Panhandle State University" },
  { value: "Oklahoma State University" },
  { value: "Oklahoma Wesleyan University" },
  { value: "Old Dominion University" },
  { value: "Olivet College" },
  { value: "Olivet Nazarene University" },
  { value: "Oral Roberts University" },
  { value: "Oregon Health & Science University" },
  { value: "Oregon Institute of Technology" },
  { value: "Oregon State University" },
  { value: "Otis College of Art and Design" },
  { value: "Ottawa University" },
  { value: "Otterbein University" },
  { value: "Ouachita Baptist University" },
  { value: "Our Lady of the Lake University" },
  { value: "Ozark Christian College" },
  { value: "Pace University" },
  { value: "Pacific Lutheran University" },
  { value: "Pacific Northwest College of Art" },
  { value: "Pacific Oaks College" },
  { value: "Pacific Union College" },
  { value: "Pacific University" },
  { value: "Pacifica Graduate Institute" },
  { value: "Paine College" },
  { value: "Palm Beach Atlantic University" },
  { value: "Palm Beach State College" },
  { value: "Palo Alto University" },
  { value: "Pardee RAND Graduate School" },
  { value: "Park University" },
  { value: "Parker University" },
  { value: "Paul Quinn College" },
  { value: "Paul Smith's College" },
  { value: "Peirce College" },
  { value: "Penn State College of Medicine" },
  { value: "Penn State University" },
  { value: "Pennsylvania Academy of the Fine Arts" },
  { value: "Pennsylvania College of Art and Design" },
  { value: "Pennsylvania College of Health Sciences" },
  { value: "Pennsylvania College of Technology" },
  { value: "Pensacola State College" },
  { value: "Pepperdine University" },
  { value: "Peru State College" },
  { value: "Pfeiffer University" },
  { value: "Philander Smith College" },
  { value: "Piedmont College" },
  { value: "Pillar College" },
  { value: "Pine Manor College" },
  { value: "Pittsburg State University" },
  { value: "Pitzer College" },
  { value: "Plaza College" },
  { value: "Plymouth State University" },
  { value: "Point Loma Nazarene University" },
  { value: "Point Park University" },
  { value: "Point University" },
  { value: "Polk State College" },
  { value: "Pomona College" },
  { value: "Pontifical Faculty of the Immaculate Conception" },
  { value: "Portland State University" },
  { value: "Post University" },
  { value: "Prairie View A&M University" },
  { value: "Pratt Institute" },
  { value: "Presbyterian College" },
  { value: "Prescott College" },
  { value: "Presentation College" },
  { value: "Princeton University" },
  { value: "Principia College" },
  { value: "Providence Christian College" },
  { value: "Providence College" },
  { value: "Purchase College, State University of New York" },
  { value: "Purdue University" },
  { value: "Purdue University Fort Wayne" },
  { value: "Purdue University Northwest" },
  { value: "Queens College, City University of New York" },
  { value: "Queens University of Charlotte" },
  { value: "Quincy University" },
  { value: "Quinnipiac University" },
  { value: "Radford University" },
  { value: "Ramapo College of New Jersey" },
  { value: "Randolph College" },
  { value: "Randolph-Macon College" },
  { value: "Rasmussen College" },
  { value: "Reed College" },
  { value: "Regent University" },
  { value: "Regis College" },
  { value: "Regis University" },
  { value: "Reinhardt University" },
  { value: "Relay Graduate School of Education" },
  { value: "Rensselaer Polytechnic Institute" },
  { value: "Research College of Nursing" },
  { value: "Resurrection University" },
  { value: "Rhode Island College" },
  { value: "Rhode Island School of Design" },
  { value: "Rhodes College" },
  { value: "Richmont Graduate University" },
  { value: "Rider University" },
  { value: "Ringling College of Art and Design" },
  { value: "Ripon College" },
  { value: "Rivier University" },
  { value: "Roanoke College" },
  { value: "Robert Morris University" },
  { value: "Roberts Wesleyan College" },
  { value: "Rochester Institute of Technology" },
  { value: "Rochester University" },
  { value: "Rockford University" },
  { value: "Rockhurst University" },
  { value: "Rocky Mountain College" },
  { value: "Rocky Mountain College of Art and Design" },
  { value: "Rocky Mountain University of Health Professions" },
  { value: "Rocky Vista University" },
  { value: "Roger Williams University" },
  { value: "Rogers State University" },
  { value: "Rollins College" },
  { value: "Roosevelt University" },
  { value: "Rosalind Franklin University of Medicine and Science" },
  { value: "Rose-Hulman Institute of Technology" },
  { value: "Roseman University of Health Sciences" },
  { value: "Rosemont College" },
  { value: "Rowan University" },
  { value: "Rush University" },
  { value: "Rust College" },
  { value: "Rutgers, The State University of New Jersey" },
  { value: "Sacred Heart University" },
  { value: "Saginaw Valley State University" },
  { value: "Saint Anselm College" },
  { value: "Saint Anthony College of Nursing" },
  { value: "Saint Augustine College" },
  { value: "Saint Augustine's University" },
  { value: "Saint Elizabeth University" },
  { value: "Saint Francis Medical Center College of Nursing" },
  { value: "Saint Francis University" },
  { value: "Saint Joseph's College of Maine" },
  { value: "Saint Joseph's University" },
  { value: "Saint Leo University" },
  { value: "Saint Louis University" },
  { value: "Saint Martin's University" },
  { value: "Saint Mary-of-the-Woods College" },
  { value: "Saint Mary's College" },
  { value: "Saint Mary's College of California" },
  { value: "Saint Mary's University of Minnesota" },
  { value: "Saint Michael's College" },
  { value: "Saint Peter's University" },
  { value: "Saint Vincent College" },
  { value: "Saint Xavier University" },
  { value: "Salem College" },
  { value: "Salem State University" },
  { value: "Salem University" },
  { value: "Salisbury University" },
  { value: "Salus University" },
  { value: "Salve Regina University" },
  { value: "Sam Houston State University" },
  { value: "Samford University" },
  { value: "Samuel Merritt University" },
  { value: "San Diego Christian College" },
  { value: "San Diego State University" },
  { value: "San Francisco Art Institute" },
  { value: "San Francisco Conservatory of Music" },
  { value: "San Francisco State University" },
  { value: "San Joaquin College of Law" },
  { value: "San José State University" },
  { value: "Santa Clara University" },
  { value: "Santa Fe College" },
  { value: "Sarah Lawrence College" },
  { value: "Savannah College of Art and Design" },
  { value: "Savannah State University" },
  { value: "Saybrook University" },
  { value: "School of the Art Institute of Chicago" },
  { value: "School of Visual Arts" },
  { value: "Schreiner University" },
  { value: "Scripps College" },
  { value: "Seattle Central College" },
  { value: "Seattle Pacific University" },
  { value: "Seattle University" },
  { value: "Seminole State College of Florida" },
  { value: "Seton Hall University" },
  { value: "Seton Hill University" },
  { value: "Sewanee: The University of the South" },
  { value: "Shaw University" },
  { value: "Shawnee State University" },
  { value: "Shenandoah University" },
  { value: "Shepherd University" },
  { value: "Shippensburg University of Pennsylvania" },
  { value: "Shorter University" },
  { value: "Siena College" },
  { value: "Siena Heights University" },
  { value: "Sierra Nevada University" },
  { value: "Simmons University" },
  { value: "Simpson College" },
  { value: "Simpson University" },
  { value: "SIT Graduate Institute" },
  { value: "Skidmore College" },
  { value: "Slippery Rock University of Pennsylvania" },
  { value: "Smith College" },
  { value: "Snow College" },
  { value: "Sofia University" },
  { value: "Soka University of America" },
  { value: "Sonoma State University" },
  { value: "South Carolina State University" },
  { value: "South College" },
  { value: "South Dakota School of Mines and Technology" },
  { value: "South Dakota State University" },
  { value: "South Georgia State College" },
  { value: "South Texas College" },
  { value: "South Texas College of Law" },
  { value: "South University" },
  { value: "Southeast Missouri State University" },
  { value: "Southeastern Baptist College" },
  { value: "Southeastern Louisiana University" },
  { value: "Southeastern Oklahoma State University" },
  { value: "Southeastern University" },
  { value: "Southern Adventist University" },
  { value: "Southern Arkansas University" },
  { value: "Southern California Institute of Architecture" },
  { value: "Southern College of Optometry" },
  { value: "Southern Connecticut State University" },
  { value: "Southern Illinois University Carbondale" },
  { value: "Southern Illinois University Edwardsville" },
  { value: "Southern Methodist University" },
  { value: "Southern Nazarene University" },
  { value: "Southern New Hampshire University" },
  { value: "Southern Oregon University" },
  { value: "Southern University and A&M College" },
  { value: "Southern University at New Orleans" },
  { value: "Southern University Law Center" },
  { value: "Southern Utah University" },
  { value: "Southern Virginia University" },
  { value: "Southern Wesleyan University" },
  { value: "Southwest Baptist University" },
  { value: "Southwest Minnesota State University" },
  { value: "Southwest University of Visual Arts" },
  { value: "Southwestern Adventist University" },
  { value: "Southwestern Assemblies of God University" },
  { value: "Southwestern Christian University" },
  { value: "Southwestern College" },
  { value: "Southwestern College, Santa Fe" },
  { value: "Southwestern Law School" },
  { value: "Southwestern Oklahoma State University" },
  { value: "Southwestern University" },
  { value: "Spalding University" },
  { value: "Spelman College" },
  { value: "Spring Arbor University" },
  { value: "Spring Hill College" },
  { value: "Springfield College" },
  { value: "St Andrews University" },
  { value: "St John's College" },
  { value: "St Petersburg College" },
  { value: "St. Ambrose University" },
  { value: "St. Bonaventure University" },
  { value: "St. Catherine University" },
  { value: "St. Cloud State University" },
  { value: "St. Edward's University" },
  { value: "St. Francis College" },
  { value: "St. John Fisher College" },
  { value: "St. Johns River State College" },
  { value: "St. John's University" },
  { value: "St. Joseph's College" },
  { value: "St. Lawrence University" },
  { value: "St. Louis Christian College" },
  { value: "St. Mary's College of Maryland" },
  { value: "St. Mary's University" },
  { value: "St. Norbert College" },
  { value: "St. Olaf College" },
  { value: "St. Thomas Aquinas College" },
  { value: "St. Thomas University" },
  { value: "Stanford University" },
  { value: "State College of Florida-Manatee-Sarasota" },
  { value: "State University of New York at Fredonia" },
  { value: "State University of New York at New Paltz" },
  { value: "State University of New York at Oswego" },
  { value: "State University of New York College at Plattsburgh" },
  { value: "Stephen F. Austin State University" },
  { value: "Stephens College" },
  { value: "Sterling College" },
  { value: "Sterling College, Vermont" },
  { value: "Stetson University" },
  { value: "Stevens Institute of Technology" },
  { value: "Stevenson University" },
  { value: "Stillman College" },
  { value: "Stockton University" },
  { value: "Stonehill College" },
  { value: "Stony Brook University" },
  { value: "Strayer University" },
  { value: "Suffolk University" },
  { value: "Sul Ross State University" },
  { value: "Sullivan University" },
  { value: "SUNY Canton" },
  { value: "SUNY Cobleskill" },
  { value: "SUNY College at Old Westbury" },
  { value: "SUNY College at Oneonta" },
  { value: "SUNY College of Environmental Science and Forestry" },
  { value: "SUNY College of Optometry" },
  { value: "SUNY Cortland" },
  { value: "SUNY Delhi" },
  { value: "SUNY Downstate Medical Center" },
  { value: "SUNY Empire State College" },
  { value: "SUNY Geneseo" },
  { value: "SUNY Maritime College" },
  { value: "SUNY Polytechnic Institute" },
  { value: "SUNY Upstate Medical University" },
  { value: "Susquehanna University" },
  { value: "Swarthmore College" },
  { value: "Sweet Briar College" },
  { value: "Syracuse University" },
  { value: "Tabor College" },
  { value: "Talladega College" },
  { value: "Tarleton State University" },
  { value: "Taylor University" },
  { value: "Temple University" },
  { value: "Tennessee State University" },
  { value: "Tennessee Tech University" },
  { value: "Tennessee Wesleyan University" },
  { value: "Texas A&M International University" },
  { value: "Texas A&M University" },
  { value: "Texas A&M University at Galveston" },
  { value: "Texas A&M University-Commerce" },
  { value: "Texas A&M University-Corpus Christi" },
  { value: "Texas A&M University-Kingsville" },
  { value: "Texas A&M University-Texarkana" },
  { value: "Texas Christian University" },
  { value: "Texas College" },
  { value: "Texas Lutheran University" },
  { value: "Texas Southern University" },
  { value: "Texas State University" },
  { value: "Texas Tech University" },
  { value: "Texas Tech University Health Sciences Center" },
  { value: "Texas Wesleyan University" },
  { value: "Texas Woman's University" },
  { value: "The American College of Financial Services" },
  { value: "The Baptist College of Florida" },
  { value: "The Catholic University of America" },
  { value: "The Chicago School of Professional Psychology" },
  { value: "The Citadel, The Military College of South Carolina" },
  { value: "The City College of New York" },
  { value: "The College at Brockport" },
  { value: "The College of Idaho" },
  { value: "The College of New Jersey" },
  { value: "The College of Saint Rose" },
  { value: "The College of St. Scholastica" },
  { value: "The College of Westchester" },
  { value: "The College of Wooster" },
  { value: "The Cooper Union for the Advancement of Science and Art" },
  { value: "The Culinary Institute of America" },
  { value: "The Evergreen State College" },
  { value: "The Graduate Center, CUNY" },
  { value: "The Institute of World Politics" },
  { value: "The Juilliard School" },
  { value: "The King's College" },
  { value: "The King's University" },
  { value: "The Master's University" },
  { value: "The New School" },
  { value: "The Ohio State University" },
  { value: "The Rockefeller University" },
  { value: "The Sage Colleges" },
  { value: "The School of Architecture" },
  { value: "The State University of New York at Potsdam" },
  { value: "The University of Alabama" },
  { value: "The University of Alabama in Huntsville" },
  { value: "The University of Arizona" },
  { value: "The University of Findlay" },
  { value: "The University of Maine" },
  { value: "The University of Memphis" },
  { value: "The University of Montana" },
  { value: "The University of Montana Western" },
  { value: "The University of Oklahoma" },
  { value: "The University of Oklahoma Health Sciences Center" },
  { value: "The University of Tampa" },
  { value: "The University of Tennessee at Chattanooga" },
  { value: "The University of Tennessee at Martin" },
  { value: "The University of Tennessee Health Science Center" },
  { value: "The University of Tennessee, Knoxville" },
  { value: "The University of Texas at Arlington" },
  { value: "The University of Texas at Austin" },
  { value: "The University of Texas at Dallas" },
  { value: "The University of Texas at El Paso" },
  { value: "The University of Texas at San Antonio" },
  { value: "The University of Texas at Tyler" },
  { value: "The University of Texas Health Science Center at Houston" },
  { value: "The University of Texas Health Science Center at San Antonio" },
  { value: "The University of Texas Health Science Center at Tyler" },
  { value: "The University of Texas MD Anderson Cancer Center" },
  { value: "The University of Texas Medical Branch at Galveston" },
  { value: "The University of Texas Permian Basin" },
  { value: "The University of the Arts" },
  { value: "The University of Tulsa" },
  { value: "The University of Utah" },
  { value: "The University of Virginia's College at Wise" },
  { value: "The University of West Alabama" },
  { value: "The University of West Los Angeles" },
  { value: "The Wright Institute" },
  { value: "Thiel College" },
  { value: "Thomas Aquinas College" },
  { value: "Thomas College" },
  { value: "Thomas Edison State University" },
  { value: "Thomas Jefferson School of Law" },
  { value: "Thomas Jefferson University" },
  { value: "Thomas More College of Liberal Arts" },
  { value: "Thomas More University" },
  { value: "Thomas University" },
  { value: "Tiffin University" },
  { value: "Toccoa Falls College" },
  { value: "Tougaloo College" },
  { value: "Touro College" },
  { value: "Touro University California" },
  { value: "Towson University" },
  { value: "Transylvania University" },
  { value: "Trevecca Nazarene University" },
  { value: "Trine University" },
  { value: "Trinity Bible College" },
  { value: "Trinity Christian College" },
  { value: "Trinity College" },
  { value: "Trinity College of Florida" },
  { value: "Trinity College of Nursing & Health Sciences" },
  { value: "Trinity International University" },
  { value: "Trinity University" },
  { value: "Trinity Washington University" },
  { value: "Tri-State Bible College" },
  { value: "Trocaire College" },
  { value: "Troy University" },
  { value: "Truett McConnell University" },
  { value: "Truman State University" },
  { value: "Tufts University" },
  { value: "Tulane University" },
  { value: "Tusculum University" },
  { value: "Tuskegee University" },
  { value: "Uniformed Services University of the Health Sciences" },
  { value: "Union College" },
  { value: "Union College, Kentucky" },
  { value: "Union College, Nebraska" },
  { value: "Union Institute & University" },
  { value: "Union University" },
  { value: "United States Air Force Academy" },
  { value: "United States Coast Guard Academy" },
  { value: "United States Merchant Marine Academy" },
  { value: "United States Military Academy" },
  { value: "United States Naval Academy" },
  { value: "United States Sports Academy" },
  { value: "United States University" },
  { value: "Unity College" },
  { value: "University at Albany, State University of New York" },
  { value: "University at Buffalo, State University of New York" },
  { value: "University of Advancing Technology" },
  { value: "University of Akron" },
  { value: "University of Alabama at Birmingham" },
  { value: "University of Alaska Anchorage" },
  { value: "University of Alaska Fairbanks" },
  { value: "University of Alaska Southeast" },
  { value: "University of Arkansas" },
  { value: "University of Arkansas - Fort Smith" },
  { value: "University of Arkansas at Little Rock" },
  { value: "University of Arkansas at Monticello" },
  { value: "University of Arkansas at Pine Bluff" },
  { value: "University of Arkansas for Medical Sciences" },
  { value: "University of Baltimore" },
  { value: "University of Bridgeport" },
  { value: "University of California, Berkeley" },
  { value: "University of California, Davis" },
  { value: "University of California, Hastings College of the Law" },
  { value: "University of California, Irvine" },
  { value: "University of California, Los Angeles" },
  { value: "University of California, Merced" },
  { value: "University of California, Riverside" },
  { value: "University of California, San Diego" },
  { value: "University of California, San Francisco" },
  { value: "University of California, Santa Barbara" },
  { value: "University of California, Santa Cruz" },
  { value: "University of Central Arkansas" },
  { value: "University of Central Florida" },
  { value: "University of Central Missouri" },
  { value: "University of Central Oklahoma" },
  { value: "University of Charleston" },
  { value: "University of Chicago" },
  { value: "University of Cincinnati" },
  { value: "University of Colorado Boulder" },
  { value: "University of Colorado Colorado Springs" },
  { value: "University of Colorado Denver" },
  { value: "University of Connecticut" },
  { value: "University of Dallas" },
  { value: "University of Dayton" },
  { value: "University of Delaware" },
  { value: "University of Denver" },
  { value: "University of Detroit Mercy" },
  { value: "University of Dubuque" },
  { value: "University of Evansville" },
  { value: "University of Florida" },
  { value: "University of Georgia" },
  { value: "University of Hartford" },
  { value: "University of Hawaii at Hilo" },
  { value: "University of Hawaii at Manoa" },
  { value: "University of Hawaii-West Oahu" },
  { value: "University of Health Sciences and Pharmacy in St. Louis" },
  { value: "University of Holy Cross" },
  { value: "University of Houston" },
  { value: "University of Houston-Clear Lake" },
  { value: "University of Houston-Downtown" },
  { value: "University of Houston-Victoria" },
  { value: "University of Idaho" },
  { value: "University of Illinois at Chicago" },
  { value: "University of Illinois at Springfield" },
  { value: "University of Illinois at Urbana-Champaign" },
  { value: "University of Indianapolis" },
  { value: "University of Iowa" },
  { value: "University of Jamestown" },
  { value: "University of Kansas" },
  { value: "University of Kentucky" },
  { value: "University of La Verne" },
  { value: "University of Louisiana at Lafayette" },
  { value: "University of Louisiana at Monroe" },
  { value: "University of Louisville" },
  { value: "University of Lynchburg" },
  { value: "University of Maine at Augusta" },
  { value: "University of Maine at Farmington" },
  { value: "University of Maine at Fort Kent" },
  { value: "University of Maine at Machias" },
  { value: "University of Maine at Presque Isle" },
  { value: "University of Mary" },
  { value: "University of Mary Hardin-Baylor" },
  { value: "University of Mary Washington" },
  { value: "University of Maryland" },
  { value: "University of Maryland Eastern Shore" },
  { value: "University of Maryland, Baltimore" },
  { value: "University of Maryland, Baltimore County" },
  { value: "University of Massachusetts Amherst" },
  { value: "University of Massachusetts Boston" },
  { value: "University of Massachusetts Dartmouth" },
  { value: "University of Massachusetts Lowell" },
  { value: "University of Massachusetts Medical School" },
  { value: "University of Miami" },
  { value: "University of Michigan" },
  { value: "University of Michigan-Dearborn" },
  { value: "University of Michigan-Flint" },
  { value: "University of Minnesota-Twin Cities" },
  { value: "University of Mississippi" },
  { value: "University of Mississippi Medical Center" },
  { value: "University of Missouri" },
  { value: "University of Missouri-Kansas City" },
  { value: "University of Missouri-St. Louis" },
  { value: "University of Mobile" },
  { value: "University of Montevallo" },
  { value: "University of Mount Olive" },
  { value: "University of Mount Union" },
  { value: "University of Nebraska at Kearney" },
  { value: "University of Nebraska at Omaha" },
  { value: "University of Nebraska Medical Center" },
  { value: "University of Nebraska-Lincoln" },
  { value: "University of Nevada, Las Vegas" },
  { value: "University of Nevada, Reno" },
  { value: "University of New England" },
  { value: "University of New Hampshire" },
  { value: "University of New Haven" },
  { value: "University of New Mexico" },
  { value: "University of New Orleans" },
  { value: "University of North Alabama" },
  { value: "University of North Carolina at Asheville" },
  { value: "University of North Carolina at Chapel Hill" },
  { value: "University of North Carolina at Charlotte" },
  { value: "University of North Carolina at Greensboro" },
  { value: "University of North Carolina at Pembroke" },
  { value: "University of North Carolina School of the Arts" },
  { value: "University of North Carolina Wilmington" },
  { value: "University of North Dakota" },
  { value: "University of North Florida" },
  { value: "University of North Georgia" },
  { value: "University of North Texas" },
  { value: "University of North Texas Health Science Center" },
  { value: "University of Northern Colorado" },
  { value: "University of Northern Iowa" },
  { value: "University of Northwestern - St. Paul" },
  { value: "University of Northwestern Ohio" },
  { value: "University of Notre Dame" },
  { value: "University of Oregon" },
  { value: "University of Pennsylvania" },
  { value: "University of Pikeville" },
  { value: "University of Pittsburgh" },
  { value: "University of Portland" },
  { value: "University of Providence" },
  { value: "University of Puget Sound" },
  { value: "University of Redlands" },
  { value: "University of Rhode Island" },
  { value: "University of Richmond" },
  { value: "University of Rio Grande" },
  { value: "University of Rochester" },
  { value: "University of Saint Francis" },
  { value: "University of Saint Joseph" },
  { value: "University of Saint Mary" },
  { value: "University of San Diego" },
  { value: "University of San Francisco" },
  { value: "University of Science and Arts of Oklahoma" },
  { value: "University of Scranton" },
  { value: "University of Sioux Falls" },
  { value: "University of South Alabama" },
  { value: "University of South Carolina" },
  { value: "University of South Carolina-Aiken" },
  { value: "University of South Carolina-Beaufort" },
  { value: "University of South Carolina-Upstate" },
  { value: "University of South Dakota" },
  { value: "University of South Florida" },
  { value: "University of Southern California" },
  { value: "University of Southern Indiana" },
  { value: "University of Southern Maine" },
  { value: "University of Southern Mississippi" },
  { value: "University of St. Augustine for Health Sciences" },
  { value: "University of St. Francis" },
  { value: "University of St. Thomas" },
  { value: "University of St. Thomas" },
  { value: "University of Texas Rio Grande Valley" },
  { value: "University of Texas Southwestern Medical Center" },
  { value: "University of the Cumberlands" },
  { value: "University of the District of Columbia" },
  { value: "University of the Incarnate Word" },
  { value: "University of the Ozarks" },
  { value: "University of the Pacific" },
  { value: "University of the Potomac" },
  { value: "University of the Sciences in Philadelphia" },
  { value: "University of the Southwest" },
  { value: "University of the West" },
  { value: "University of Toledo" },
  { value: "University of Valley Forge" },
  { value: "University of Vermont" },
  { value: "University of Virginia" },
  { value: "University of Washington" },
  { value: "University of West Florida" },
  { value: "University of West Georgia" },
  { value: "University of Western States" },
  { value: "University of Wisconsin-Eau Claire" },
  { value: "University of Wisconsin-Green Bay" },
  { value: "University of Wisconsin-La Crosse" },
  { value: "University of Wisconsin-Madison" },
  { value: "University of Wisconsin-Milwaukee" },
  { value: "University of Wisconsin-Oshkosh" },
  { value: "University of Wisconsin-Parkside" },
  { value: "University of Wisconsin-Platteville" },
  { value: "University of Wisconsin-River Falls" },
  { value: "University of Wisconsin-Stevens Point" },
  { value: "University of Wisconsin-Stout" },
  { value: "University of Wisconsin-Superior" },
  { value: "University of Wisconsin-Whitewater" },
  { value: "University of Wyoming" },
  { value: "Upper Iowa University" },
  { value: "Ursinus College" },
  { value: "Ursuline College" },
  { value: "Utah State University" },
  { value: "Utah Valley University" },
  { value: "Utica College" },
  { value: "Valdosta State University" },
  { value: "Valencia College" },
  { value: "Valley City State University" },
  { value: "Valparaiso University" },
  { value: "Vanderbilt University" },
  { value: "VanderCook College of Music" },
  { value: "Vanguard University of Southern California" },
  { value: "Vassar College" },
  { value: "Vaughn College of Aeronautics and Technology" },
  { value: "Vermont College of Fine Arts" },
  { value: "Vermont Law School" },
  { value: "Vermont Technical College" },
  { value: "Villa Maria College" },
  { value: "Villanova University" },
  { value: "Vincennes University" },
  { value: "Virginia Commonwealth University" },
  { value: "Virginia Military Institute" },
  { value: "Virginia Tech" },
  { value: "Virginia State University" },
  { value: "Virginia Union University" },
  { value: "Virginia Wesleyan University" },
  { value: "Viterbo University" },
  { value: "Voorhees College" },
  { value: "Wabash College" },
  { value: "Wade College" },
  { value: "Wagner College" },
  { value: "Wake Forest University" },
  { value: "Waldorf University" },
  { value: "Walla Walla University" },
  { value: "Walsh College" },
  { value: "Walsh University" },
  { value: "Warner Pacific College" },
  { value: "Warner University" },
  { value: "Warren Wilson College" },
  { value: "Wartburg College" },
  { value: "Washburn University" },
  { value: "Washington & Jefferson College" },
  { value: "Washington Adventist University" },
  { value: "Washington and Lee University" },
  { value: "Washington College" },
  { value: "Washington State University" },
  { value: "Washington University in St. Louis" },
  { value: "Watkins College of Art" },
  { value: "Wayland Baptist University" },
  { value: "Wayne State College" },
  { value: "Wayne State University" },
  { value: "Waynesburg University" },
  { value: "Webb Institute" },
  { value: "Webber International University" },
  { value: "Weber State University" },
  { value: "Webster University" },
  { value: "Welch College" },
  { value: "Wellesley College" },
  { value: "Wells College" },
  { value: "Wentworth Institute of Technology" },
  { value: "Wesley College" },
  { value: "Wesleyan College" },
  { value: "Wesleyan University" },
  { value: "West Chester University of Pennsylvania" },
  { value: "West Coast University-Los Angeles" },
  { value: "West Liberty University" },
  { value: "West Texas A&M University" },
  { value: "West Virginia School of Osteopathic Medicine" },
  { value: "West Virginia State University" },
  { value: "West Virginia University" },
  { value: "West Virginia University at Parkersburg" },
  { value: "West Virginia Wesleyan College" },
  { value: "Western Carolina University" },
  { value: "Western Colorado University" },
  { value: "Western Connecticut State University" },
  { value: "Western Illinois University" },
  { value: "Western Kentucky University" },
  { value: "Western Michigan University" },
  { value: "Western Nevada College" },
  { value: "Western New England University" },
  { value: "Western New Mexico University" },
  { value: "Western Oregon University" },
  { value: "Western University of Health Sciences" },
  { value: "Western Washington University" },
  { value: "Westfield State University" },
  { value: "Westminster College" },
  { value: "Westminster College, Missouri" },
  { value: "Westminster College, Pennsylvania" },
  { value: "Westmont College" },
  { value: "Wheaton College" },
  { value: "Wheaton College, Massachusetts" },
  { value: "Wheeling University" },
  { value: "Whitman College" },
  { value: "Whittier College" },
  { value: "Whitworth University" },
  { value: "Wichita State University" },
  { value: "Widener University" },
  { value: "Wilberforce University" },
  { value: "Wiley College" },
  { value: "Wilkes University" },
  { value: "Willamette University" },
  { value: "William Carey University" },
  { value: "William James College" },
  { value: "William Jessup University" },
  { value: "William Jewell College" },
  { value: "Rice University" },
  { value: "William Paterson University" },
  { value: "William Peace University" },
  { value: "William Penn University" },
  { value: "William Woods University" },
  { value: "Williams Baptist University" },
  { value: "Williams College" },
  { value: "Wilmington College" },
  { value: "Wilmington University" },
  { value: "Wilson College" },
  { value: "Wingate University" },
  { value: "Winona State University" },
  { value: "Winston-Salem State University" },
  { value: "Winthrop University" },
  { value: "Wisconsin Lutheran College" },
  { value: "Wisconsin School of Professional Psychology" },
  { value: "Wittenberg University" },
  { value: "Wofford College" },
  { value: "WON Institute of Graduate Studies" },
  { value: "Woodbury University" },
  { value: "Worcester Polytechnic Institute" },
  { value: "Worcester State University" },
  { value: "Wright State University" },
  { value: "Xavier University" },
  { value: "Xavier University of Louisiana" },
  { value: "Yale University" },
  { value: "Yeshiva University" },
  { value: "York College" },
  { value: "York College of Pennsylvania" },
  { value: "York College, City University of New York" },
  { value: "Young Harris College" },
  { value: "Youngstown State University" },
];

export {
  schools,
  schools_static,
  locations,
  majors_static,
  companies,
  majors_live,
  schools_live,
};
