import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "./style.css";
import { locations, companies } from "../SubmitAutocomplete.js";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { useCookies } from "react-cookie";
import { RouteComponentProps, navigate } from "@reach/router";
import {
  Input,
  Form,
  Button,
  Select,
  Radio,
  Tooltip,
  Rate,
  Checkbox,
  InputNumber,
  Tabs,
  AutoComplete,
  notification,
} from "antd/es";
import {
  QuestionCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { YearValue, MyInfo as myInfoInterface } from "../../reviews";
import { database } from "../../database";
// import { createEmitAndSemanticDiagnosticsBuilderProgram } from "typescript";
import { useAuth } from "../../contexts/AuthContext";

const close = (key) => {
  console.log(
    "Notification was closed. Either the close button was clicked or duration time elapsed."
  );
  notification.close(key);
  navigate("/register");
};

const mustSignUpNotification = () => {
  const key = `open${Date.now()}`;
  const tooltp = (
    <span>
      We won't spam you, sell your email, or reveal your indentity to anyone.{" "}
      <span role="img" aria-label="tophat">
        🕵
      </span>{" "}
      <Tooltip
        className="input-horizontal-spacer"
        title="Canary ensures all reviews are from real students. All reviews are anonymous and personal info like email is only used to verify your account, nothing more. 🔐"
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </span>
  );
  const btn = (
    <Button type="primary" size="large" onClick={() => close(key)}>
      Sign up
    </Button>
  );
  notification.open({
    placement: "topRight",
    duration: 5,
    top: 100,
    message: "Sign up to help your fellow student! 😊",
    description: tooltp,
    btn,
    key,
  });
};

const { Option } = Select;

const dev_is_required = true;

const { TabPane } = Tabs;

const years = [
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
];
const semesters = ["Spring", "Summer", "Fall"];

const terms1 = years
  .reduce((acc, year) => {
    let perm = semesters.reduce(
      (a, semester) => [...a, semester + " " + year],
      [] as string[]
    );
    return acc.concat(perm);
  }, [] as string[])
  .map((option, i) => (
    <Option value={option} key={i}>
      {option}
    </Option>
  ));

const terms = terms1.reverse();

function callback(key) {
  console.log(key);
}

const MyExperienceTab = ({ setSubmitPage, index }) => {
  const [form] = Form.useForm();
  const [cookies, setCookie, removeCookie] = useCookies([
    "name",
    "email",
    "school",
    "majors",
    "year",
  ]);
  const [hasHousingStipend, setHasHousingStipend] = useState(false);
  // const [submitPage, setSubmitPage] = useState("1");

  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  const isMobile = !isDesktop;

  let isDisabled = false;

  let rowNumber = 2;
  if (isDesktop) {
    rowNumber = 2;
  } else {
    rowNumber = 4;
  }

  const { currentUser, logout } = useAuth();

  const [myInfo, setMyInfo] = useState<myInfoInterface>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  // const [unfinished, setUnfinished] = useState(false);
  useEffect(() => {
    async function getUserInfo(userID) {
      console.log("hey");
      database
        .collection("users")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          setMyInfo(doc.data() as myInfoInterface);
          console.log(doc.data());
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if (currentUser) {
    //   isDisabled = false;
    //   getUserInfo(currentUser.uid);
    // }
  }, []);

  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="section-card internship-experience">
        <h2 className="submit-section-header">What I did</h2>

        <Form.Item
          name="work_rating"
          label="Rate the work you did (0.5 = boring and useless, 5 = fascinating and engaging)"
          rules={[
            {
              required: dev_is_required,
              message: "Please rate the work you did",
            },
          ]}
        >
          <Rate disabled={isDisabled} count={5} allowHalf defaultValue={0} />
        </Form.Item>
        <Form.Item
          name="culture_rating"
          label="Rate the company culture (0.5 = toxic and discouraging, 5 = warm and inspiring)"
          rules={[
            {
              required: dev_is_required,
              message: "Please rate your company's culture",
            },
          ]}
        >
          <Rate disabled={isDisabled} count={5} allowHalf defaultValue={0} />
        </Form.Item>
        <Form.Item
          name="overall_rating"
          label="Rate the overall experience"
          rules={[
            {
              required: dev_is_required,
              message: "Please rate your overall experience",
            },
          ]}
        >
          <Rate disabled={isDisabled} count={5} allowHalf defaultValue={0} />
        </Form.Item>
        <Form.Item
          className="input-horizontal"
          name="tools_often"
          label="Software/Tools you used most often"
          rules={[
            {
              required: dev_is_required,
              message: "Please input the tools(s) you used often",
            },
          ]}
        >
          <Select
            className="input-horizontal"
            disabled={isDisabled}
            mode="tags"
            placeholder="Please input the tools(s) you used often"
            dropdownStyle={{ display: "none" }}
            style={{ width: "250px", maxWidth: "340px" }}
            tokenSeparators={[","]}
          ></Select>
        </Form.Item>
        <Form.Item
          name="impact"
          className="input-horizontal"
          label="How impactful was your work?"
          rules={[
            {
              required: dev_is_required,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            //mode="multiple"
            placeholder="Select"
            style={{ width: "170px", maxWidth: "340px" }}
            //tokenSeparators={[","]}
            className="input-horizontal"
            disabled={isDisabled}
            options={[
              { value: "No impact" },
              { value: "Not very impactful" },
              { value: "Somewhat impactful" },
              { value: "Impactful" },
              { value: "Very impactful" },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item
          name="work_time"
          className="input-horizontal"
          label="How much of the time did you spend working?"
          rules={[
            {
              required: dev_is_required,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder="Select"
            style={{ width: "170px", maxWidth: "340px" }}
            className="input-horizontal"
            disabled={isDisabled}
            options={[
              { value: "0-20% (no work)" },
              { value: "20-40%" },
              { value: "40-60%" },
              { value: "60-80%" },
              { value: "80-100% (very busy)" },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item
          name="project_description"
          label={
            <span>
              Describe your main project(s). Please explain what you{" "}
              <b>actually</b> did - don't overhype it ;)
            </span>
          }
          rules={[
            {
              required: dev_is_required,
              message: "Please describe your internship",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Describe your main project - there's room for any other comments later!"
            disabled={isDisabled}
            rows={2}
          ></Input.TextArea>
        </Form.Item>

        <Form.Item
          name="recommend"
          label="Would recommend it to people who..."
          rules={[
            {
              required: false,
              message:
                "Please describe who you would recommend this internship to",
            },
          ]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          name="pros"
          label="Pros:"
          className="input-horizontal"
          rules={[
            {
              required: false,
              message: "",
            },
          ]}
        >
          <Input.TextArea
            className="input-horizontal"
            placeholder="The good stuff..."
            style={{ maxWidth: "350px" }}
            disabled={isDisabled}
            rows={rowNumber / 2}
          />
        </Form.Item>

        <Form.Item
          name="cons"
          label="Cons:"
          className="input-horizontal"
          rules={[
            {
              required: false,
              message: "",
            },
          ]}
        >
          <Input.TextArea
            placeholder="The bad stuff..."
            className="input-horizontal"
            style={{ maxWidth: "350px" }}
            disabled={isDisabled}
            rows={rowNumber / 2}
          />
        </Form.Item>

        <Form.Item
          name="optional_remarks"
          label="Anything else you want to share? Like it? Hate it? What did you learn? Add any additonal thoughts/advice/stories here!"
        >
          <Input.TextArea disabled={isDisabled} rows={3}></Input.TextArea>
        </Form.Item>

         { index==0 ? (
                  <Button
                    className="submit-nextPage"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSubmitPage("2");
                      window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                      });
                    }}
                  >
                   Next <RightCircleOutlined />
                  </Button>
              ) : (
                <>

                  <Button
                    className="submit-nextPage"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSubmitPage(index.toString());
                      window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <LeftCircleOutlined /> Back
                  </Button>
                  <Button
                    className="submit-nextPage"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSubmitPage((index+2).toString());
                      window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Next <RightCircleOutlined />
                  </Button>
                  </>
              )}

        {/*<Button
          className="submit-nextPage"
          type="primary"
          size="large"
          onClick={() => {
            setSubmitPage("2");
            window.scrollTo({
              top: 100,
              behavior: "smooth",
            });
          }}
        >
          <LeftCircleOutlined /> Back
        </Button>
        <Button
          className="submit-nextPage"
          type="primary"
          size="large"
          onClick={() => {
            setSubmitPage("4");
            window.scrollTo({
              top: 100,
              behavior: "smooth",
            });
          }}
        >
          Next <RightCircleOutlined />
        </Button>*/}
      </div>
    </>
  );
};

const verticalStyle = {
  lineHeight: "30px",
  display: "block",
};

export default MyExperienceTab;
