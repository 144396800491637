import React from "react";
import "./style.css";

import { RouteComponentProps } from "@reach/router";
import { Button, Row, Col } from "antd/es";
import { LinkedinOutlined } from "@ant-design/icons";
import paul from "../../images/paul.jpg";
import christen from "../../images/christen.jpg";
import andrea from "../../images/andrea.jpg";
import stephen from "../../images/sterling.jpg";
import mohan from "../../images/mohan.jpg";
import vamsi from "../../images/vamsi.jpg";
import jack from "../../images/jack.jpg";
import sims from "../../images/sims.png";
import noah from "../../images/noah.jpg";

import bird_front from "../../images/icons/bird_front.png";
import point from "../../images/icons/point.png";

const About = (props: RouteComponentProps) => {
  return (
    <div className="">
      <h1 className="align-center">
        About Canary <img className="icon-emoji-big" src={bird_front}></img>
      </h1>
      <div className="content-card">
        <h2>The story</h2>
        <p>
          As students at Georgia Tech who went through the internship process
          multiple times, we consistently found a lack of honest or helpful
          information on what internships were actually like. This led to
          disappointing experiences or missed opportunities. Job descriptions
          were often misleading or vague, while job review sites were focused on
          full-time positions and asked questions like CEO approval rating or
          yearly salary, questions less relevant to students looking for a great
          ~3 month, resume-building, learning experience. We wanted our
          classmates to be able to share their experiences with one another in
          an easy and effective way so that we would all be able to make more
          informed decisions and get internships we would love.
        </p>
      </div>
      <div className="content-card">
        <h2>The platform</h2>
        <p>
          The result? We built Canary, an open platform for students to share
          about their experiences and help their classmates get the internship
          of their dreams. Canary empowers students to avoid bad internships,
          find great ones, and prepare themselves to land it.
        </p>
      </div>

      <div className="team">
        <h2 className="section-header">Our team</h2>
        <Row gutter={[16, 8]}>
          <Col className="align-center" span={12}>
            <img
              className="profile-pic"
              alt="Sims Pettway Co-founder"
              src={sims}
            ></img>
            <h2 className="name">
              Sims Pettway{" "}
              <span>
                <a href="https://www.linkedin.com/in/sims-pettway-bb3470158/">
                  <LinkedinOutlined />
                </a>
              </span>
            </h2>
            <h3 className="title">
              Co-founder<br></br>Georgia Tech '21
            </h3>
          </Col>
          <Col className="align-center" span={12}>
            {" "}
            <img
              className="profile-pic"
              alt="Stephen Ralph Co-founder"
              src={stephen}
            ></img>
            <h2 className="name">
              Stephen Ralph{" "}
              <span>
                <a href="https://www.linkedin.com/in/seralphjr/">
                  <LinkedinOutlined />
                </a>
              </span>
            </h2>
            <h3 className="title">
              Co-founder<br></br>Georgia Tech '21
            </h3>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col className="align-center" span={12}>
            <img
              className="profile-pic"
              alt="Jack DiMarco Software Engineer Georgia Tech '21"
              src={jack}
            ></img>
            <h2 className="name">
              Jack DiMarco{" "}
              <span>
                <a href="https://www.linkedin.com/in/jackdimarco/">
                  <LinkedinOutlined />
                </a>
              </span>
            </h2>
            <h3 className="title">
              Software Engineer<br></br>Georgia Tech '21
            </h3>
          </Col>
          <Col className="align-center" span={12}>
            <img
              className="profile-pic"
              alt="Mohan Dodda Software Engineer Georgia Tech '22"
              src={mohan}
            ></img>
            <h2 className="name">
              Mohan Dodda{" "}
              <span>
                <a href="https://www.linkedin.com/in/mohandodda/">
                  <LinkedinOutlined />
                </a>
              </span>
            </h2>
            <h3 className="title">
              Software Engineer<br></br>Georgia Tech '21
            </h3>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col className="align-center" span={24}>
            <img
              className="profile-pic"
              alt="Noah Waldron Co-founder, Advisor Georgia Tech '19"
              src={noah}
            ></img>
            <h2 className="name">
              Noah Waldron{" "}
              <span>
                <a href="https://www.linkedin.com/in/noah-waldron-433707137/">
                  <LinkedinOutlined />
                </a>
              </span>
            </h2>
            <h3 className="title">
              Co-founder, Advisor<br></br>Georgia Tech '19
            </h3>
          </Col>
        </Row>
      </div>

      <div className="advisors">
        <h2 className="section-header">Our advisors</h2>
        <Row gutter={[16, 8]}>
          <Col className="align-center" span={12}>
            <img
              className="profile-pic"
              alt="Christen Steele  Director of Career Services Georgia Tech College of Computing"
              src={christen}
            ></img>
            <h2 className="name">
              Christen Steele{" "}
              <span>
                <a href="https://www.linkedin.com/in/christensteele/">
                  <LinkedinOutlined />
                </a>
              </span>
            </h2>
            <h3 className="title">
              Director of Career Services<br></br>Georgia Tech<br></br> College
              of Computing
            </h3>
          </Col>
          <Col className="align-center" span={12}>
            {" "}
            <img
              className="profile-pic"
              alt="Paul Fowler Executive Director<br></br>Emory Career Center"
              src={paul}
            ></img>
            <h2 className="name">
              Paul Fowler{" "}
              <span>
                <a href="https://www.linkedin.com/in/paul-fowler-0a606b22/">
                  <LinkedinOutlined />
                </a>
              </span>
            </h2>
            <h3 className="title">
              Executive Director<br></br>Emory Career Center
            </h3>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col className="align-center" span={24}>
            <img
              className="profile-pic"
              alt="Andrea Comsa Director of Co-op and Experiential Education<br></br>Georgia Tech"
              src={andrea}
            ></img>
            <h2 className="name">
              Andrea Comsa{" "}
              <span>
                <a href="https://www.linkedin.com/in/andrea-comsa-fekete-mba-phr-9166593/">
                  <LinkedinOutlined />
                </a>
              </span>
            </h2>
            <h3 className="title">
              Director of Co-op and Experiential Education<br></br>Georgia Tech
            </h3>
          </Col>
        </Row>
      </div>

      <div className="content-card-FAQbutton">
        <h2>
          Questions?{" "}
          <Button type="primary" size="large" target="__blank" href="/FAQ">
            <img className="icon-emoji" src={point}></img>
            FAQ Page
          </Button>
        </h2>
      </div>

      <h3 className="content-card align-center">
        Want to help grow the Canary community at <b>your</b> school?
        <br></br>
        Reach out to us at{" "}
        <a href="mailto:feedback@canarystudent.com">
          feedback@canarystudent.com
        </a>
      </h3>
    </div>
  );
};

export default About;
