import React, { useState, useEffect } from "react";
import "./style.css";
import { locations, companies } from "../SubmitAutocomplete.js";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { useCookies } from "react-cookie";
import { RegisterPortal } from "..";
import { RouteComponentProps, navigate, useLocation } from "@reach/router";
import {
  Input,
  Form,
  Button,
  Select,
  Radio,
  Tooltip,
  Rate,
  Checkbox,
  InputNumber,
  Tabs,
  AutoComplete,
  notification,
  Alert,
} from "antd/es";
import {
  QuestionCircleOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { YearValue, MyInfo as myInfoInterface } from "../../reviews";
import { database } from "../../database";
// import { createEmitAndSemanticDiagnosticsBuilderProgram } from "typescript";
import { useAuth } from "../../contexts/AuthContext";
import { useMediaQuery } from "react-responsive";
import reviewsIcon from "../../images/icons/reviews.png";
import pencil from "../../images/icons/pencil.png";
import people from "../../images/icons/people.png";
import register from "../../images/icons/register.png";
import login from "../../images/icons/login.png";
import bird_front from "../../images/icons/bird_front.png";
import medal from "../../images/icons/medal.png";
import lightbulb from "../../images/icons/lightbulb.png";
import student from "../../images/icons/student.png";
import office from "../../images/icons/office.png";
import strong from "../../images/icons/strong.png";

const close = (key) => {
  console.log(
    "Notification was closed. Either the close button was clicked or duration time elapsed."
  );
  notification.close(key);
  navigate("/register");
};

const mustSignUpNotification = () => {
  const key = `open${Date.now()}`;
  const tooltp = (
    <span>
      We won't spam you, sell your email, or reveal your indentity to anyone.{" "}
      <span role="img" aria-label="tophat">
        🕵
      </span>{" "}
      <Tooltip
        className="input-horizontal-spacer"
        title="Canary ensures all reviews are from real students. All reviews are anonymous and personal info like email is only used to verify your account, nothing more. 🔐"
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </span>
  );
  const btn = (
    <Button type="primary" size="large" onClick={() => close(key)}>
      Sign up
    </Button>
  );
  notification.open({
    placement: "topRight",
    duration: 5,
    top: 100,
    message: "Sign up to help your fellow student! 😊",
    description: tooltp,
    btn,
    key,
  });
};

const { Option } = Select;

const dev_is_required = true;

const { TabPane } = Tabs;

// const marks_impact = {
//   0: "No impact (busy-work)",
//   1: "Not very impactful",
//   2: "Somewhat impactful",
//   3: "Impactful",
//   4: "Very impactful",
// };

// const marks_prerequisites = {
//   0: "None (they'll teach you what you need to know)",
//   1: "Beginner (need basic knowledge/ experience)",
//   2: "Intermediate (need to be pretty familiar)",
//   3: "Expert (need to have advanced knowledge/ experience)",
// };

// const marks_timeworking = {
//   0: "0-20% (might as well have done nothing)",
//   1: "20-40% worked some, but ton of down time)",
//   2: "40-60% (some days stayed busy, but still good bit of downtime)",
//   3: "60-80% (stayed pretty busy)",
//   4: "80-100% (more or less busy the whole time)",
// };

const years = [
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
];
const semesters = ["Spring", "Summer", "Fall"];

const terms1 = years
  .reduce((acc, year) => {
    let perm = semesters.reduce(
      (a, semester) => [...a, semester + " " + year],
      [] as string[]
    );
    return acc.concat(perm);
  }, [] as string[])
  .map((option, i) => (
    <Option value={option} key={i}>
      {option}
    </Option>
  ));

const terms = terms1.reverse();

function callback(key) {
  console.log(key);
}

const DynamicRule = () => {
  const [form] = Form.useForm();
  const [cookies, setCookie, removeCookie] = useCookies([
    "name",
    "email",
    "school",
    "majors",
    "year",
  ]);
  const [hasHousingStipend, setHasHousingStipend] = useState(false);
  const [submitPage, setSubmitPage] = useState("1");

  const { currentUser, logout } = useAuth();

  const [myInfo, setMyInfo] = useState<myInfoInterface>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [unfinished, setUnfinished] = useState(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  const [accountCreated, setAccountCreated] = useState(false);
  const isMobile = !isDesktop;
  const location = useLocation();
  var urlParams = new URLSearchParams(location?.search);

  let rowNumber = 2;
  if (isDesktop) {
    rowNumber = 2;
  } else {
    rowNumber = 4;
  }

  useEffect(() => {
    async function getUserInfo(userID) {
      //console.log("hey");
      database
        .collection("users")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          setMyInfo(doc.data() as myInfoInterface);
          console.log(doc.data());
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (currentUser) {
      setIsDisabled(false);
      getUserInfo(currentUser.uid);
    }
  }, [currentUser]);

  const onSuccess = (values) => {
    if (values.remember_personal) {
      setCookie("name", values.name || "");
      setCookie("school", values.school || "");
      setCookie("email", values.email || "");
      setCookie("major", values.major || "");
      setCookie("other_studies", values.other_studies || "");
      setCookie("year", values.year || {});
      setCookie("remember_personal", values.remember_personal);
    } else {
      removeCookie("name");
      removeCookie("school");
      removeCookie("email");
      removeCookie("major");
      removeCookie("other_studies");
      removeCookie("year");
      removeCookie("remember_personal");
    }
    const review = {
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      timestamp: { seconds: new Date().getTime() / 1000 },
      company: {
        name: values.company_name || "",
        description: "",
        image: "",
      },
      position: values.position || "",
      team: values.team || "",
      internship_type: values.internship_type || "",
      structured_program: values.structured_program || "",
      location: values.location || "",
      terms: values.terms || [],
      pay: "",
      paydict: {
        type: values.pay.type || "",
        amount: values.pay.amount || "",
        currency: "USD",
      },

      overall_rating: values.overall_rating,
      culture_rating: values.culture_rating,
      work_rating: values.work_rating,
      year: Object.assign(
        { year: "1st", grad_level: "undergraduate" },
        values.year
      ),
      other_studies: values.other_studies || "",
      housing_stipend: values.housing_stipend || "",
      recommend: values.recommend || "",
      not_recommend: values.not_recommend || "",
      tools: {
        often: values.tools_often || [],
        occasionally: values.tools_occasionally || [],
        rarely: values.tools_rarely || [],
      },
      description: values.description || "",
      offer: values.offer === 0 ? 0 : values.offer || "",
      would_accept_offer:
        values.would_accept_offer === 0 ? 0 : values.would_accept_offer || "",
      impact: values.impact || "",
      prerequisites: values.prerequisites || "",
      expectations: values.expectations || "",
      expectations_description: values.expectations_description || "",
      work_time: values.work_time || "",
      interview_advice: values.interview_advice || "",
      optional_remarks: values.optional_remarks || "",
      rounds: values.rounds || "",
      formats: values.formats || [],
      interview_types: values.interview_types || [],
      interview_types_new: values.interview_types_new || [],
      feedback: values.feedback || "",
      is_visible: false,
      project_description: values.project_description || "",
      is_remote: values.is_remote || "",
      remote_description: values.remote_description || "",
      feedback_length: values.feedback_length || "",
      interview_application: values.interview_application || "",
      interview_application_new: values.interview_application_new || "",
      interview_questions: values.interview_questions || "",
      permission: values.permission || "",
      major: myInfo?.major || "",
      school: myInfo?.school || "",
      benefits: values.benefits || "",
      pros: values.pros || "",
      cons: values.cons || "",
      _referral: values.referral || "",
    };
    // console.log(review);

    database
      .collection("users")
      .doc(currentUser.uid)
      .update({
        user_reviews: firebase.firestore.FieldValue.arrayUnion(review.id),
      })
      .then(() => {
        database
          .collection("review")
          .doc(review.id)
          .set(review)
          .then(() => {
            navigate("/submit-success");
          })
          .catch((err) => {
            console.log(err);
            navigate("/submit-error");
          });
      })
      .catch((err) => {
        console.log(err);
        navigate("/submit-error");
      });
    // const sendReviewCollectionToAlgolia = firebase.
  };

  const onFail = (errorInfo) => {
    setUnfinished(true);
    if (errorInfo.values.remember_personal) {
      setCookie("name", errorInfo.values.name || "");
      setCookie("school", errorInfo.values.school || "");
      setCookie("email", errorInfo.values.email || "");
      setCookie("major", errorInfo.values.major || "");
      setCookie("other_studies", errorInfo.values.other_studies || "");
      setCookie("year", errorInfo.values.year || {});
      setCookie("remember_personal", errorInfo.values.remember_personal);
    } else {
      removeCookie("name");
      removeCookie("school");
      removeCookie("email");
      removeCookie("major");
      removeCookie("other_studies");
      removeCookie("year");
      removeCookie("remember_personal");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Form
        scrollToFirstError={true}
        onFinish={onSuccess}
        onFinishFailed={onFail}
        form={form}
        layout="vertical"
        name="dynamic_rule"
      >
        {/*{!currentUser && (
          <div className="section-card mustSignUp">
            <h1>Sign up to leave a review!</h1>
            <p className="signup-disclaimer-submit">
              (So we can verify you're actually a student!)
            </p>

            <Button
              className="submit-alertButton"
              href="/register"
              type="primary"
              size="large"
            >
              <span role="img" aria-label="hand writing">
                ✍️
              </span>{" "}
              Sign up
            </Button>
            <Button
              className="submit-alertButton"
              href="/login"
              type="primary"
              size="large"
            >
              <span role="img" aria-label="key">
                🔑
              </span>{" "}
              Log In
            </Button>
          </div>
        )}*/}

        <Tabs
          defaultActiveKey="1"
          activeKey={submitPage}
          onChange={callback}
          onTabClick={(key, e) => {
            setSubmitPage(key);
          }}
        >
          <TabPane tab="Details" key="1">
            <div className="section-card about-you">
              <h2 className="submit-section-header about-you-header">
                {" "}
                {(currentUser && <>About You </>) || <>Create Account </>}
                <Tooltip
                  className="input-horizontal-spacer"
                  title="Reviews are anonymous - they only show your school and major, not your username or email."
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </h2>{" "}
              {(currentUser && (
                <>
                  {myInfo?.school && (
                    <h3 className="school">
                      School: <strong>{myInfo?.school}</strong>
                    </h3>
                  )}
                  {myInfo?.major && (
                    <h3 className="major">
                      Major: <strong>{myInfo?.major}</strong>
                    </h3>
                  )}
                  {accountCreated && (
                    <Alert
                      message="Account successfully created"
                      description="Continue on to leaving review"
                      type="success"
                      showIcon
                      className="accountCreatedAlert"
                    />
                  )}
                </>
              )) || (
                <>
                  <h4 className="about-you-description">
                    Creating an account let's you have control of your review
                    after you write it! Only school and major will be included
                    in your review.
                  </h4>
                  <RegisterPortal
                    setIsDisabled={setIsDisabled}
                    setMyInfo={setMyInfo}
                    portalStyle="inLine"
                    setAccountCreated={setAccountCreated}
                  />
                </>
              )}
            </div>

            <div className="section-card internship-details">
              <h2 className="submit-section-header">Internship Details</h2>
              <Form.Item
                name="year"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please select your year",
                  },
                ]}
                label={
                  <>
                    <span>
                      Year in school when you applied to the job (
                      <span style={{ fontWeight: 800 }}>not{"  "}</span>
                      {"  "}what year you are now)
                    </span>
                  </>
                }
              >
                <YearInput isDisabled={isDisabled} />
              </Form.Item>

              <Form.Item
                className="input-horizontal"
                name="company_name"
                label="Company name"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please input the company name",
                  },
                ]}
              >
                <AutoComplete
                  className="input-horizontal"
                  disabled={isDisabled}
                  options={companies}
                  defaultValue={urlParams.get("company") || ""}
                  placeholder="Please input the company"
                  style={{ maxWidth: "320px" }}
                  filterOption={(inputValue, option) =>
                    option
                      ? option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      : false
                  }
                />
              </Form.Item>
              <Form.Item
                className="input-horizontal"
                name="position"
                label="Position title"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please input your position title",
                  },
                ]}
              >
                <Input
                  style={{ maxWidth: "250px" }}
                  defaultValue={urlParams.get("position") || ""}
                  placeholder="E.g., Marketing Intern"
                  className="input-horizontal"
                  disabled={isDisabled}
                />
              </Form.Item>
              <Form.Item
                className="input-horizontal"
                name="team"
                label="Department/Team"
                rules={[
                  {
                    required: false,
                    message: "Please input your department/team",
                  },
                ]}
              >
                <Input
                  style={{ maxWidth: "250px" }}
                  placeholder="Please input your department/team"
                  className="input-horizontal"
                  disabled={isDisabled}
                />
              </Form.Item>

              <Form.Item
                className="remote-select"
                name="is_remote"
                label={
                  <span>
                    Was this internship remote/virtual or was it in person?
                  </span>
                }
                rules={[
                  {
                    required: dev_is_required,
                    message:
                      "Please specify if this is a remote internship/co-op program",
                  },
                ]}
              >
                <Radio.Group disabled={isDisabled} className="">
                  <Radio.Button value="In-person">In-person</Radio.Button>
                  <Radio.Button value="Remote">Remote</Radio.Button>
                  <Radio.Button value="Some of both">Some of both</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.is_remote !== currentValues.is_remote
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("is_remote") &&
                    getFieldValue("is_remote") !== "In-person" ? (
                    <Form.Item
                      className=""
                      name="remote_description"
                      label="How did being remote affect the experience?"
                      rules={[
                        {
                          required: false,
                          message:
                            "Please describe how the company did being remote",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="How was it being virtual?"
                        disabled={isDisabled}
                        rows={2}
                      ></Input.TextArea>
                    </Form.Item>
                  ) : (
                    <div></div>
                  );
                }}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.is_remote !== currentValues.is_remote
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("is_remote") &&
                    getFieldValue("is_remote") !== "Remote" ? (
                    <Form.Item
                      className="input-horizontal"
                      name="location"
                      label="Location (city/state/region)"
                      rules={[
                        {
                          required: false,
                          message: "Please input your internship's location",
                        },
                      ]}
                    >
                      <AutoComplete
                        options={locations}
                        disabled={isDisabled}
                        defaultValue={urlParams.get("location") || ""}
                        placeholder="Please input your internship's location"
                        style={{ maxWidth: "340px" }}
                        filterOption={(inputValue, option) =>
                          option
                            ? option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            : false
                        }
                        className="input-horizontal"
                      />
                    </Form.Item>
                  ) : (
                    <div></div>
                  );
                }}
              </Form.Item>

              <Form.Item
                className="input-horizontal"
                name="terms"
                label="Term(s) employed"
                rules={[
                  {
                    required: false,
                    message: "Please select the terms you were employed",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please select the terms you were employed"
                  style={{ width: "100%", maxWidth: "340px" }}
                  tokenSeparators={[","]}
                  className="input-horizontal"
                  disabled={isDisabled}
                >
                  {terms}
                </Select>
              </Form.Item>

              <Form.Item
                className="input-horizontal"
                name="pay"
                label="Pay (USD)"
                extra="Hourly, stipend, etc."
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please input a number for the pay and its type",
                  },
                ]}
              >
                <Input.Group compact className="payField">
                  <Form.Item
                    name={["pay", "amount"]}
                    noStyle
                    rules={[{ required: true, message: "Amount is required" }]}
                  >
                    <InputNumber
                      disabled={isDisabled}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) =>
                        `$ ${value}`.replace(/\$\s?|(,*)/g, "")
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name={["pay", "type"]}
                    noStyle
                    rules={[
                      { required: true, message: "Pay type is required" },
                    ]}
                  >
                    <Select
                      disabled={isDisabled}
                      placeholder="Pay Type"
                      style={{ maxWidth: "100px" }}
                    >
                      <Option value="hourly">hourly</Option>
                      <Option value="weekly">weekly</Option>
                      <Option value="monthly">monthly</Option>
                      <Option value="lump">lump</Option>
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              {/*
              <Form.Item label="Housing stipend" className="input-horizontal">
                <Radio.Group
                  defaultValue="none"
                  onChange={(e) =>
                    setHasHousingStipend(e.target.value === "yes")
                  }
                  className="input-horizontal"
                  disabled={isDisabled}
                >
                  <Radio.Button value="none">None</Radio.Button>
                  <Radio.Button value="provided">Housing provided</Radio.Button>
                  <Radio.Button value="yes">Yes</Radio.Button>
                </Radio.Group>
                {hasHousingStipend && (
                  <Form.Item
                    name="housing_stipend"
                    noStyle
                    rules={[
                      {
                        required: dev_is_required,
                        message:
                          "Please input a number for the housing stipend",
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) =>
                        `$ ${value}`.replace(/\$\s?|(,*)/g, "")
                      }
                      placeholder="Please input your pay"
                      disabled={isDisabled}
                    />
                  </Form.Item>
                )}
              </Form.Item>
                    */}
              <Form.Item
                className="input-horizontal"
                name="benefits"
                label="Housing stipend or other benefits?"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{ maxWidth: "250px" }}
                  placeholder="Free lunch, discounts, etc."
                  className="input-horizontal"
                  disabled={isDisabled}
                />
              </Form.Item>

              <Form.Item
                name="offer"
                className="input-horizontal"
                label="Did you accept a full-time offer here?"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select
                  style={{ width: 220 }}
                  placeholder="Select"
                  className="input-horizontal"
                  disabled={isDisabled}
                >
                  <Option value="0">Yes</Option>
                  <Option value="1">Offered, but declined</Option>
                  <Option value="2">Not offerred</Option>
                  <Option value="3">Not applicable (e.g., too early)</Option>
                  <Option value="4">Prefer not to say</Option>
                </Select>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.offer !== currentValues.offer
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("offer") &&
                    getFieldValue("offer") > 1 ? (
                    <Form.Item
                      name="would_accept_offer"
                      label="Would you accept a full-time offer here?"
                      rules={[{ required: true }]}
                      className="input-horizontal"
                    >
                      <Select
                        style={{ width: 220 }}
                        placeholder="Select"
                        className="input-horizontal"
                        disabled={isDisabled}
                      >
                        <Option value="0">Yes, definitely</Option>
                        <Option value="1">Maybe, I probably would</Option>
                        <Option value="2">No, definitely not</Option>
                        <Option value="3">Maybe, but probably not</Option>
                        <Option value="4">Prefer not to say</Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <div></div>
                  );
                }}
              </Form.Item>
              <Button
                className="submit-nextPage"
                type="primary"
                size="large"
                onClick={() => {
                  setSubmitPage("2");
                  window.scrollTo(0, 0);
                }}
              >
                Next <RightCircleOutlined />
              </Button>
            </div>
          </TabPane>
          <TabPane tab="My experience" key="2">
            <div className="section-card internship-experience">
              <h2 className="submit-section-header">My experience</h2>

              <Form.Item
                name="work_rating"
                label="Rate the work you did (0.5 = boring and useless, 5 = fascinating and engaging)"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please rate the work you did",
                  },
                ]}
              >
                <Rate
                  className="rating-stars"
                  disabled={isDisabled}
                  count={5}
                  allowHalf
                  defaultValue={0}
                />
              </Form.Item>
              <Form.Item
                name="culture_rating"
                label="Rate the company culture (0.5 = toxic and discouraging, 5 = warm and inspiring)"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please rate your company's culture",
                  },
                ]}
              >
                <Rate
                  disabled={isDisabled}
                  count={5}
                  allowHalf
                  defaultValue={0}
                />
              </Form.Item>
              <Form.Item
                name="overall_rating"
                label="Rate the overall experience"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please rate your overall experience",
                  },
                ]}
              >
                <Rate
                  disabled={isDisabled}
                  count={5}
                  allowHalf
                  defaultValue={0}
                />
              </Form.Item>
              <Form.Item
                className="input-horizontal"
                name="tools_often"
                label="Software/Tools you used most often"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please input the tools(s) you used often",
                  },
                ]}
              >
                <Select
                  className="input-horizontal"
                  disabled={isDisabled}
                  mode="tags"
                  placeholder="Please input the tools(s) you used often"
                  dropdownStyle={{ display: "none" }}
                  style={{ width: "250px", maxWidth: "340px" }}
                  tokenSeparators={[","]}
                ></Select>
              </Form.Item>
              <Form.Item
                name="impact"
                className="input-horizontal"
                label="How impactful was your work?"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select
                  //mode="multiple"
                  placeholder="Select"
                  style={{ width: "170px", maxWidth: "340px" }}
                  //tokenSeparators={[","]}
                  className="input-horizontal"
                  disabled={isDisabled}
                  options={[
                    { value: "No impact" },
                    { value: "Not very impactful" },
                    { value: "Somewhat impactful" },
                    { value: "Impactful" },
                    { value: "Very impactful" },
                  ]}
                ></Select>
              </Form.Item>
              <Form.Item
                name="work_time"
                className="input-horizontal"
                label="How much of the time did you spend working?"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select
                  placeholder="Select"
                  style={{ width: "170px", maxWidth: "340px" }}
                  className="input-horizontal"
                  disabled={isDisabled}
                  options={[
                    { value: "0-20% (no work)" },
                    { value: "20-40%" },
                    { value: "40-60%" },
                    { value: "60-80%" },
                    { value: "80-100% (very busy)" },
                  ]}
                ></Select>
              </Form.Item>
              <Form.Item
                name="project_description"
                label={
                  <span>
                    Describe your main project(s). Please explain what you{" "}
                    <b>actually</b> did - don't overhype it ;)
                  </span>
                }
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please describe your internship",
                  },
                  {
                    min: 100,
                    message: "Please write at least 100 characters (~20 words)",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Describe your main project - there's room for any other comments later!"
                  disabled={isDisabled}
                  rows={rowNumber}
                ></Input.TextArea>
              </Form.Item>

              <Form.Item
                name="pros"
                label="Pros:"
                className="input-horizontal"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Input.TextArea
                  className="input-horizontal"
                  placeholder="The good stuff..."
                  style={{ maxWidth: "350px" }}
                  disabled={isDisabled}
                  rows={rowNumber / 2}
                />
              </Form.Item>

              <Form.Item
                name="cons"
                label="Cons:"
                className="input-horizontal"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="The bad stuff..."
                  className="input-horizontal"
                  style={{ maxWidth: "350px" }}
                  disabled={isDisabled}
                  rows={rowNumber / 2}
                />
              </Form.Item>

              <Form.Item
                name="optional_remarks"
                className="optional_remarks"
                style={{ margin: "-25px 0 20px 0" }}
                label="Anything else you want to share? Add any additional thoughts/advice/stories here!"
              >
                <Input.TextArea
                  disabled={isDisabled}
                  rows={rowNumber / 2}
                ></Input.TextArea>
              </Form.Item>

              <Button
                className="submit-nextPage"
                type="primary"
                size="large"
                onClick={() => {
                  setSubmitPage("1");
                  window.scrollTo(0, 0);
                }}
              >
                <LeftCircleOutlined /> Back
              </Button>

              <Button
                className="submit-nextPage"
                type="primary"
                size="large"
                onClick={() => {
                  setSubmitPage("3");
                  window.scrollTo(0, 0);
                }}
              >
                Next <RightCircleOutlined />
              </Button>
            </div>
          </TabPane>
          <TabPane tab="Interview" key="3">
            <div className="section-card interview-process">
              <h2 className="submit-section-header">The interview</h2>
              <div className="submit-horizontalFields">
                <Form.Item
                  name="interview_application_new"
                  label="How did you find the job / apply?"
                  extra="Choose all that apply"
                  rules={[
                    {
                      required: false,
                      message: "Please select an option",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Please select method(s)"
                    style={{ width: "100%", maxWidth: "340px" }}
                    tokenSeparators={[","]}
                    className="input-horizontal"
                    disabled={isDisabled}
                    options={[
                      { value: "Company career page" },
                      { value: "Online job board" },
                      { value: "School job board" },
                      { value: "Referral/networking" },
                      { value: "Career fair" },
                      { value: "Cold email/call" },
                    ]}
                  ></Select>
                </Form.Item>

                <Form.Item
                  name="interview_types_new"
                  label="Interview type"
                  extra="Choose all that apply"
                  rules={[
                    {
                      required: false,
                      message: "Please select an option",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Please select types"
                    style={{ width: "100%", maxWidth: "340px" }}
                    tokenSeparators={[","]}
                    className="input-horizontal"
                    disabled={isDisabled}
                    options={[
                      { value: "Behavioral" },
                      { value: "Knowledge (theoretical)" },
                      { value: "Knowledge (technical)" },
                      { value: "Case" },
                      { value: "Past experience" },
                    ]}
                  ></Select>
                </Form.Item>
                <Form.Item
                  className="input-horizontal"
                  name="rounds"
                  label="Rounds"
                  rules={[
                    {
                      required: false,
                      message: "Please select an option",
                    },
                  ]}
                >
                  <Radio.Group
                    disabled={isDisabled}
                    className="input-horizontal-rounds"
                  >
                    <Radio value={"1"}>1</Radio>
                    <br></br>
                    <Radio value={"2"}>2</Radio>
                    <br></br>
                    <Radio value={"3"}>3</Radio>
                    <br></br>
                    <Radio value={"4+"}>4+</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <Form.Item
                name="interview_questions"
                label="Interview questions: what did they ask? How difficult were they?"
                rules={[
                  {
                    required: false,
                    message: "Please select an option",
                  },
                ]}
              >
                <Input.TextArea disabled={isDisabled} rows={2}></Input.TextArea>
              </Form.Item>

              <Form.Item
                name="interview_advice"
                label="What do your classmates need to do to get this internship? What do you wish you would've known?"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Input.TextArea disabled={isDisabled} rows={2}></Input.TextArea>
              </Form.Item>
            </div>
            <div className="section-card submit">
              <Form.Item
                name="feedback"
                label="What can we improve on Canary? What questions should we remove or add?"
              >
                <Input.TextArea disabled={isDisabled} rows={1}></Input.TextArea>
              </Form.Item>

              <Form.Item name="referral" label="How'd you hear about Canary?">
                <Input.TextArea disabled={isDisabled} rows={1}></Input.TextArea>
              </Form.Item>

              <Form.Item
                name="permission"
                label="Conditions"
                rules={[{ required: true, message: "Please check to accept" }]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={isDisabled}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                >
                  By clicking the checkbox, I give permission to Canary to share
                  my anonymized information on its website and with third
                  parties. I have read and agree to Canary's{" "}
                  <a
                    href="https://app.termly.io/document/privacy-policy/c599c195-4b40-430f-8387-3288a250e2fe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  ,{" "}
                  <a
                    href="https://app.termly.io/document/terms-of-use-for-website/8cc4f576-8bd2-4b88-a984-0e30a80851ce"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  , and{" "}
                  <a
                    href="https://docs.google.com/document/d/1VdlOuxD-Qmh5yCBLh7QtXtjBOvFoSgjT-32u9nhBzvM/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    our Community Guidelines
                  </a>
                  . (We take privacy and anonymity seriously - we will NOT share
                  your name or email.)
                </Checkbox>
              </Form.Item>
              <div className="dataExplanation-submit">
                <p className="dataExplanationText-submit">
                  Privacy is priority #1 with Canary{" "}
                  <span role="img" aria-label="lock">
                    🔒
                  </span>{" "}
                  <br></br>
                  <a target="_blank" rel="noopener noreferrer" href="/FAQ">
                    Check out our FAQ
                  </a>{" "}
                  for answers to all your data-related questions.
                </p>
              </div>
              <Form.Item>
                <Button
                  className="submit-nextPage"
                  type="primary"
                  size="large"
                  onClick={() => {
                    setSubmitPage("2");
                    window.scrollTo(0, 0);
                  }}
                >
                  <LeftCircleOutlined /> Back
                </Button>

                <Button
                  disabled={!termsAccepted}
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  Submit <CheckCircleOutlined />
                </Button>
                <div>
                  {unfinished && (
                    <div className="unfinished-text">
                      Whoops! You left some required fields blank - go back to
                      fill them in!
                    </div>
                  )}
                </div>
              </Form.Item>
            </div>
          </TabPane>
        </Tabs>
      </Form>
    </>
  );
};

const verticalStyle = {
  lineHeight: "30px",
  display: "block",
};

export interface PayValue {
  type: "hourly" | "lump" | "monthly" | "weekly";
  amount: number;
  currency: "USD" | "EUR";
}

interface YearInputProps {
  value?: YearValue;
  onChange?: (value: YearValue) => void;
  isDisabled: boolean;
}

const YearInput: React.FC<YearInputProps> = ({
  value = {},
  onChange,
  isDisabled,
}) => {
  const [gradLevel, setGradLevel] = useState<
    "undergraduate" | "graduate" | "graduated"
  >();
  const [year, setYear] = useState<
    "1st" | "2nd" | "3rd" | "4th" | "5th" | "6th+"
  >();

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange({ grad_level: gradLevel, year, ...value, ...changedValue });
    }
  };

  const onGradLevelChange = (e) => {
    let newVal = e.target.value;
    if (!("grad_level" in value)) {
      setGradLevel(newVal);
      if (!("year" in value)) {
        setYear("1st");
      }
    }
    triggerChange({ grad_level: newVal, year: value.year || "1st" });
  };

  const onCurrencyChange = (e) => {
    let newVal = e.target.value;
    if (!("year" in value)) {
      setYear(newVal);
      if (!("grad_level" in value)) {
        setGradLevel("undergraduate");
      }
    }
    triggerChange({
      year: newVal,
      grad_level: value.grad_level || "undergraduate",
    });
  };

  return (
    <span className="year-select">
      <Radio.Group
        disabled={isDisabled}
        value={value.grad_level}
        onChange={onGradLevelChange}
      >
        <Radio.Button value="undergraduate">Undergraduate</Radio.Button>
        <Radio.Button value="graduate">Graduate</Radio.Button>
        <Radio.Button value="graduated">Graduated</Radio.Button>
      </Radio.Group>
      <div className="year-select-number">
        <Radio.Group
          value={value.grad_level === "graduated" ? "" : value.year}
          onChange={onCurrencyChange}
          disabled={isDisabled || value.grad_level === "graduated"}
        >
          <Radio.Button value="1st">1st</Radio.Button>
          <Radio.Button value="2nd">2nd</Radio.Button>
          <Radio.Button value="3rd">3rd</Radio.Button>
          <Radio.Button value="4th">4th</Radio.Button>
          <Radio.Button value="5th">5th</Radio.Button>
          <Radio.Button value="6th+">6th+</Radio.Button>
        </Radio.Group>
      </div>
    </span>
  );
};

const SubmitReview = (props: RouteComponentProps) => {
  const { currentUser, logout } = useAuth();

  return (
    <>
      <>
        <h1 className="submit-title align-center">
          Write a review <img className="icon-emoji-big" src={pencil}></img>
        </h1>
        <h2 className="align-center">
          Students helping students{" "}
          <img className="icon-emoji-big" src={strong}></img>
        </h2>

        <div className="SubmitReview centered">
          {/*
          <div className="align-center amazon-card">
            <h3>🔥 Leave a review for chance to get $100 Amazon gift card</h3>1
            of every 50 reviews will win! Yeah not the best odds but we're broke
            students too 😂
          </div>*/}
          <DynamicRule />
        </div>
      </>
    </>
  );
};

export default SubmitReview;
