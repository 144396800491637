import React from "react";
import "./style.css";

import { RouteComponentProps } from "@reach/router";
import { Button, Row, Col, Collapse } from "antd/es";
import { useMediaQuery } from "react-responsive";

import lightbulb from "../../images/icons/lightbulb.png";
import office from "../../images/icons/office.png";

const InsightsEmployers = (props: RouteComponentProps) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  const isMobile = !isDesktop;

  return (
    <div className="">
      <h1 className="align-center">
        Canary Insights <img className="icon-emoji-big" src={lightbulb}></img>
      </h1>
      <h2 className="align-center">
        For Employers <img className="icon-emoji-big" src={office}></img>
      </h2>
      <div className="content-card_insights-title-container">
        <div className="content-card_insights-title">
          <p>
            As students, we want the best internship experiences, and that means
            helping employers improve their programs, using the anonymized
            internship feedback students contribute to the platform.
            <br></br>
            <br></br>This page is just the tip of the iceberg of the insights
            the Canary team has developed. Interested in more extensive and
            custom in-depth insights reports for your company? Reach out to{" "}
            <a href="mailto:employers@canarystudent.com">
              employers@canarystudent.com
            </a>
          </p>
        </div>
      </div>
      <div className="insight-grid">
        <div className="content-card_insights ">
          <div className="section-header-insights">
            <h2>Impactful work leads to full-time conversion</h2>
            <p>
              Employers need to ensure they carve out meaningful projects for
              their interns if they want them to come back full time.
            </p>
          </div>

          <a
            target="_blanks"
            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1240955081&format=image"
          >
            <img
              className="image_graph"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1240955081&format=image"
            ></img>
          </a>
          <span className="clickToEnlarge">(Click image to enlarge)</span>
        </div>

        <div className="content-card_insights ">
          <div className="section-header-insights">
            <h2>Busy interns are happy interns</h2>
            <p>
              Interns who have plenty of work to do, on average, have better
              experiences. They don't want busy-work (see the previous chart),
              but they definitely want something to do! Don't keep them too
              idle.
            </p>
          </div>

          <a
            target="_blanks"
            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1912277027&format=image"
          >
            <img
              className="image_graph"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1912277027&format=image"
            ></img>
          </a>
          <span className="clickToEnlarge">(Click image to enlarge)</span>
        </div>
        <div className="content-card_insights">
          <div className="section-header-insights">
            <h2>Distribution of hourly pay vs. overall rating</h2>
            <p>
              Especially below $30/hr, internship ratings have a low correlation
              to hourly pay - students prioritize impactful, meaningful
              projects.
            </p>
          </div>

          <a
            target="_blanks"
            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1504271340&format=image"
          >
            <img
              className="image_graph"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1504271340&format=image"
            ></img>
          </a>
          <span className="clickToEnlarge">(Click image to enlarge)</span>
        </div>

        <div className="content-card_insights">
          <div className="section-header-insights">
            <h2>Remote internships do not disappoint</h2>
            <p>
              We expect this to change post-pandemic, but in the current moment,
              remote internships do not seem to be severely effecting students
              experiences - actually rating more highly than in-person and
              hybrid formats.
            </p>
          </div>

          <a
            target="_blanks"
            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=442763083&format=image"
          >
            <img
              className="image_graph"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=442763083&format=image"
            ></img>
          </a>
          <span className="clickToEnlarge">(Click image to enlarge)</span>
        </div>
      </div>
      <div className="content-card_insights-title-container">
        <div className="content-card_insights-title">
          <h2 className="">
            Want to level up your internship program and attract the best
            talent?
          </h2>
          <p>
            Canary offers in-depth custom reports to help companies understand
            how their internship programs stack up to the competition and what
            they need to do to attract top talent and convert them to full-time.
          </p>
          <br></br>
          Reach out to us at{" "}
          <a href="mailto:employers@canarystudent.com">
            employers@canarystudent.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default InsightsEmployers;
