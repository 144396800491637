import React, { useState, useEffect, useRef } from "react";
import { RouteComponentProps, navigate, useLocation } from "@reach/router";
import "./style.css";
import algoliasearch from "algoliasearch";
import { CompanyCard, DiscoverResults, SignUpRequired } from "..";
import CompaniesScroll from "../CompaniesScroll";
// import { schools_static, majors_static } from "../SubmitAutocomplete.js";
import { useAuth } from "../../contexts/AuthContext";
import {
  Button,
  Input,
  Pagination,
  Spin,
  Result,
  Form,
  Select,
  AutoComplete,
  notification,
} from "antd";
import {
  FilterOutlined,
  CloseOutlined,
  ArrowDownOutlined,
  RightCircleOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import {
  schools,
  schools_static,
  majors_static,
  majors_live,
  schools_live,
  companies,
} from "../SubmitAutocomplete.js";

import { Review } from "../../reviews";
import { database } from "../../database";

import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

import pencil from "../../images/icons/pencil.png";

// let test_rev = test_data[1]
// database.collection('review').doc(test_rev.id).set(test_rev)

let is_defaultResults = true;

interface HomeProps extends RouteComponentProps {
  setPage: any;
  page: any;
  setHomeMajor: any;
  homeMajor: any;
  setHomeSchool: any;
  homeSchool: any;
  homeEmail: any;
  setHomeEmail: any;
  children?: any;
}

let reviewsLimit = 0;

const Home = (props: HomeProps) => {
  const location = useLocation();
  const [reviews, setReviews] = useState<Review[]>([]);
  const [hitsObject, setHitsObject] = useState<any>();
  const [searchText, setSearchText] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  var urlParams = new URLSearchParams(props.location?.search);

  const [discoverParam, setDiscoverParam] = useState("Featured");

  // Algolia api keys
  const algoliaClient = algoliasearch(
    "ZYXV1W90TC",
    "d389b8c61ad7d47f3964288fce2b6013"
  );
  const collectionIndexName = "reviews_prod"; // "reviews_dev" for testing and playground
  const collectionIndex = algoliaClient.initIndex(collectionIndexName);

  const sentinel = useRef<HTMLDivElement | null>(null);

  function handleChange(value) {
    setDiscoverParam(value);
  }

  // OnFinish for sign-up portal on home page - passes through to the register page and autofills these values
  const onFinish = (values: any) => {
    ////console.log("Success:", values.major);
    setHomeMajor(values.major);
    setHomeSchool(values.school);
    setHomeEmail(values.email);
    navigate("/register");
  };

  useEffect(() => {
    database
      .collection("content")
      .doc("reviewsLimit")
      .get()
      .then((doc) => {
        reviewsLimit = doc.data()?.reviewsLimit;
      });
  }, []);

  const onSentinelIntersection = (entries) => {
    if (currentUser || isDefaultResults) {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          !loading &&
          hitsObject.page < hitsObject.nbPages
        ) {
          console.log(hitsObject);
          searchForReviews(hitsObject.page + 1, searchText);
        }
      });
    }
  };

  const searchForReviews = async (page: number | undefined, search: string) => {
    if (
      search == "" &&
      company.length == 0 &&
      positions.length == 0 &&
      majors.length == 0 &&
      tools.length == 0 &&
      schools.length == 0
    ) {
      setIsDefaultResults(true);
    } else {
      setIsDefaultResults(false);
    }

    var reviews_data: any[] = [];

    let companyFilter = [];
    company.forEach((companyName) => {
      //@ts-ignore
      companyFilter.push("company.name:" + companyName);
    });

    let majorFilter = [];
    majors.forEach((major) => {
      //@ts-ignore
      majorFilter.push("student_major:" + major);
    });

    let toolsFilter = [];
    tools.forEach((toolName) => {
      //@ts-ignore
      toolsFilter.push("tools.often:" + toolName);
    });

    let schoolsFilter = [];
    schools.forEach((schoolName) => {
      //@ts-ignore
      schoolsFilter.push("student_school:" + schoolName);
    });

    let positionFilter = [];
    positions.forEach((positionName) => {
      //@ts-ignore
      positionFilter.push("position:" + positionName);
    });

    setLoading(true);
    await collectionIndex
      .search(search, {
        attributesToRetrieve: ["objectID", "isVisible"],
        page: page,
        facetFilters: [
          companyFilter,
          toolsFilter,
          majorFilter,
          schoolsFilter,
          positionFilter,
        ],
      })
      .then(async (searched) => {
        //console.log(searched)

        setHitsObject(searched);

        for (var i = 0; i < searched.hits.length; i++) {
          const element = searched.hits[i];
          var reviewRef = database
            .collection("review")
            .doc(element["objectID"]);
          console.log("called");
          try {
            const doc = await reviewRef.get();

            if (doc.exists && element["isVisible"] === true) {
              setPage(1);
              reviews_data.push(doc.data());
            } else {
              // doc.data() will be undefined in this case
              //console.log("No such document!");
            }
          } catch (err) {
            console.log(err);
          }
        }
        setLoading(false);

        setReviews((reviews) => [...reviews, ...(reviews_data as Review[])]);
      });
  };

  useEffect(() => {
    async function getReviews(search) {
      setLoading(true);
      urlParams = new URLSearchParams(search);

      searchForReviews(0, search);
    }

    const userSearch: string = urlParams.get("search") || "";
    setSearchText(userSearch);
    setReviews([]);
    setPage(1);
    getReviews(userSearch);
  }, [location.search]);

  useEffect(() => {
    var observer = new IntersectionObserver(onSentinelIntersection, {});
    if (sentinel.current) {
      observer.observe(sentinel.current);
    }

    return () => observer.disconnect();
  }, [onSentinelIntersection, sentinel]);

  const page = props.page;
  const setPage = props.setPage;
  const homeMajor = props.homeMajor;
  const setHomeMajor = props.setHomeMajor;
  const homeSchool = props.homeSchool;
  const setHomeSchool = props.setHomeSchool;
  const homeEmail = props.homeSchool;
  const setHomeEmail = props.setHomeEmail;

  const { Option } = Select;

  const [form] = Form.useForm();
  const [reviewCount, setReviewCount] = useState<number>(0);
  const [company, setCompany] = useState<String[]>([]);
  const [positions, setPositions] = useState<String[]>([]);
  const [tools, setTools] = useState<String[]>([]);
  const [majors, setMajors] = useState<String[]>([]);
  const [schools, setSchools] = useState<String[]>([]);
  const { currentUser, logout } = useAuth();
  const [isDefaultResults, setIsDefaultResults] = useState(true);

  const initialValues = {
    key: urlParams.get("key") || "relevance",
    search: urlParams.get("search") || "",
    tools: urlParams.get("tools")?.split(",") || [],
    company: urlParams.get("company")?.split(",") || [],
    position: urlParams.get("position")?.split(",") || [],
    major: urlParams.get("major")?.split(",") || [],
    school: urlParams.get("school")?.split(",") || [],
  };

  // Handle search
  function onFormChange(changed, all) {
    if ("search" in changed && Object.keys(changed).length === 1) {
      return;
    }
    setPage(1);
    for (const [key, value] of Object.entries(changed)) {
      if (!value || (value as Array<string>).length === 0) {
        urlParams.delete(key);
      } else {
        urlParams.set(key, value as string);
      }
    }
    navigate("/?" + urlParams.toString());
  }

  function handleSearch(value) {
    setPage(1);
    if (value) urlParams.set("search", value);
    else urlParams.delete("search");
    navigate("/?" + urlParams.toString());
  }

  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  const isMobile = !isDesktop;

  return (
    <div className="Home">
      <div
        className={classNames("drawer-shade", { visible: showFilter })}
        onClick={() => setShowFilter(false)}
      ></div>

      <div className="jumbotron-company">
        <div className="jumbotron__content block">
          <h1>
            The internship review platform,<br></br>built by students
            <br />
          </h1>
          <h3>
            Learn what internships are really like, and how to get them, from
            students like you
          </h3>
        </div>
        <br></br>
        <br></br>
      </div>

      <DiscoverResults tag={discoverParam} type="companies" />
      {discoverParam && (
        <div className="discover-selector-container">
          Showing{" "}
          <Select
            defaultValue="Featured"
            style={{ width: 120 }}
            onChange={handleChange}
            className="discover-selector"
          >
            <Option value="Featured">Featured</Option>
            <Option value="Big Tech">Tech</Option>
            <Option value="Consulting+Finance">Consulting/Finance</Option>
            <Option value="Defense/Aerospace">Defense/Aerospace</Option>
            <Option value="Retail+CPG">Retail and Consumer Goods</Option>
            <Option value="Manufacturing">Manufacturing</Option>
          </Select>{" "}
          companies
        </div>
      )}

      <div
        className={classNames("drawer-shade", { visible: showFilter })}
        onClick={() => setShowFilter(false)}
      ></div>

      <Form
        layout="vertical"
        form={form}
        onValuesChange={onFormChange}
        initialValues={initialValues}
      >
        <div className="searchBarPlusCallToActionContainer">
          <div className="search review-page-search">
            <Form.Item name="search" noStyle>
              <Input.Search
                className="searchinput"
                placeholder='Try searching "Software Engineering Intern at Google"'
                value={searchText}
                allowClear
                onChange={(e) => setSearchText(e.target.value)}
                onSearch={handleSearch}
              />
            </Form.Item>
            <Button
              type="primary"
              className="filter-toggle"
              style={{
                border: "none",
                marginLeft: "5px",
                height: "100%",
                marginRight: "auto",
                borderRadius: "5px",
              }}
              onClick={() => setShowFilter(true)}
            >
              <FilterOutlined />
            </Button>
          </div>

          {(isDesktop && (
            <div className="callToAction_ReviewsPage">
              <span className="callToActionText nudge-right">
                Had an internship? Empower your peers!{" "}
              </span>
              <Button
                className="callToActionBtn"
                type="primary"
                size="middle"
                onClick={() => navigate("/submit")}
              >
                <img className="icon-emoji" src={pencil}></img>Write a Review
              </Button>
            </div>
          )) || (
            <div className="callToAction_ReviewsPage">
              <span className="callToActionText">
                Had an internship? Empower your peers!{" "}
              </span>
              <br></br>
              <Button
                className="callToActionBtn_mobile"
                type="primary"
                onClick={() => navigate("/submit")}
              >
                <img className="icon-emoji" src={pencil}></img>Write a Review
              </Button>
            </div>
          )}
        </div>
        {/*
          <div className="switch-search">
            <Button type="primary" onClick={() => navigate("/")}>
              Switch to searching companies <RightCircleOutlined />
            </Button>
          </div>*/}

        <div className="search-content">
          <Filter
            onClose={() => setShowFilter(false)}
            visible={showFilter}
            setCompany={setCompany}
            setMajors={setMajors}
            setPositions={setPositions}
            setSchools={setSchools}
            setTools={setTools}
          />
          <div className="reviews-container">
            <div className="reviews-header">
              <div>
                {(isDefaultResults && (
                  <h3 className="results-tag">
                    Showing most{" "}
                    <span className="results-specific-tag">recent</span> reviews
                  </h3>
                )) || (
                  <h3 className="results-tag">
                    Showing results for most{" "}
                    <span className="results-specific-tag">relevant</span>{" "}
                    reviews
                  </h3>
                )}
              </div>
            </div>

            <div className="reviews">
              {!currentUser &&
                !isDefaultResults &&
                reviews
                  .slice(0, reviewsLimit)
                  .map((review, i) => (
                    <CompanyCard
                      key={review.id}
                      name={review.company.name}
                      image={review.company.image}
                      description={review.company.description}
                      reviews={[review]}
                      company_id={review.company.id}
                      num_reviews={review.company.num_reviews}
                    />
                  ))}
              {(currentUser || isDefaultResults) &&
                reviews.map((review, i) => (
                  <CompanyCard
                    key={review.id}
                    name={review.company.name}
                    image={review.company.image}
                    description={review.company.description}
                    reviews={[review]}
                    company_id={review.company.id}
                    num_reviews={review.company.num_reviews}
                  />
                ))}
              {!loading && !reviews ? (
                <>
                  <Result
                    status="warning"
                    title="Search returned 0 results"
                    style={{ gridColumn: "1 / 3" }}
                  />
                </>
              ) : (
                <></>
              )}
              <div id="sentinel" ref={sentinel} />
            </div>

            <div className="review-spinner">
              {loading ? <Spin className="text-center" size="large" /> : <></>}
            </div>

            {!currentUser && !isDefaultResults && !loading && (
              <SignUpRequired />
            )}

            <div className="writeReview_bottom">
              <p className="callToAction">
                A student somewhere is looking for a review of where <b>you</b>{" "}
                worked! Leave a review to help them out!
              </p>
              <Button type="primary" onClick={() => navigate("/submit")}>
                <img className="icon-emoji" src={pencil}></img>Write a Review
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Home;

const Filter = ({
  onClose,
  visible,
  setCompany,
  setPositions,
  setTools,
  setMajors,
  setSchools,
}) => {
  return (
    <div className={classNames("filter", { visible })}>
      <div className="filter-toggle filter-close" onClick={onClose}>
        <CloseOutlined />
      </div>
      <h2>
        <FilterOutlined /> Filter
      </h2>
      <Form.Item name="company" label="Company">
        <Select
          options={companies}
          onChange={(input, option) => {
            setCompany(input);
          }}
          notFoundContent=""
          allowClear
          mode="multiple"
          placeholder="Amazon, Tesla, Boeing, etc."
        ></Select>
      </Form.Item>
      {/*
      <Form.Item name="position" label="Positions">
        <Select
          onChange={(input) => {
            setPositions(input);
          }}
          //notFoundContent=""
          allowClear
          mode="tags"
          placeholder=""
        ></Select>
        </Form.Item>*/}
      <Form.Item name="tools" label="Tools">
        <Select
          onChange={(input) => {
            setTools(input);
          }}
          notFoundContent=""
          allowClear
          placeholder="Excel, Java, Matlab, etc."
          mode="tags"
        ></Select>
      </Form.Item>

      <h3>Intern profile</h3>
      <Form.Item name="major" label="Majors">
        <Select
          options={majors_live}
          onChange={(input) => {
            setMajors(input);
          }}
          notFoundContent=""
          allowClear
          mode="multiple"
          placeholder="Computer Science, Business, etc."
        ></Select>
      </Form.Item>
      <Form.Item name="school" label="Schools">
        <Select
          options={schools_live}
          onChange={(input) => {
            setSchools(input);
          }}
          notFoundContent=""
          allowClear
          mode="multiple"
          placeholder=""
        ></Select>
      </Form.Item>
      <div className="callToAction_ReviewsPage">
        <span className="callToActionText">Had an internship or co-op?</span>
        <br></br>
        <Button type="primary" size="large" onClick={() => navigate("/submit")}>
          <img className="icon-emoji" src={pencil}></img>Write a Review
        </Button>
      </div>
    </div>
  );
};

interface SortInput {
  value?: "ascending" | "descending";
  onChange?: (value: "ascending" | "descending") => void;
}

const SortInput: React.FC<SortInput> = ({ value = "descending", onChange }) => {
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const onClick = () => {
    let newVal = value === "ascending" ? "descending" : "ascending";
    triggerChange(newVal);
  };

  return (
    <div className="sort">
      <Button
        className={"sort-direction " + value}
        shape="circle"
        style={{
          border: "none",
          background: "none",
          padding: "0",
          boxShadow: "none",
        }}
        onClick={onClick}
      >
        <ArrowDownOutlined />
      </Button>
    </div>
  );
};
