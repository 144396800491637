import React, { useContext, useState, useEffect } from 'react'
// This is an instance of firebase auth made in the database.js file
import { auth } from '../database'

// Creates an instance of our Auth Context
const AuthContext = React.createContext()

// Allows the Auth context to be accessed with this getter
export function useAuth() {
    return useContext(AuthContext)
}

// Provider function handling all authentication context functionality
export function AuthProvider({ children }) {
    // Management of the currently signed in user using state hook
    const [currentUser, setCurrentUser] = useState()
    // Manages loading state of auth
    const [loading, setLoading] = useState(true)


    // Firebase function to actually register our user
    function register(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
        // .catch(error => {alert(error.message);})
    }

    // Firebase function to log a user in
    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    // Firebase function to log a user out
    function logout() {
        return auth.signOut()
    }

    // Firebase function to reset a user's email address
    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    // useEffect hook to only update the user when the component is first mounted
    useEffect(() => {

        // Firebase function to actually set current user
        // Pass in either the current user or null
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })
        // Unsubscribe from onAuthStateChanged listener whenever component is unmounted
        return unsubscribe
    }, [])

    // Object containing all of the information about our current user that we may need
    const value = {
        currentUser,
        register,
        login,
        logout,
        resetPassword
    }
    
    // Returning the Auth Provider, wrapping all components of the application as children
    // This allows all of the information stored in value to be accessed anywhere in our application
    // Middle line makes sure to only render out the children if auth is not loading
    return(
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}