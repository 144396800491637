import React, { useState, useEffect } from "react";
import "./style.css";

import { RouteComponentProps, navigate } from "@reach/router";
import { EnvironmentFilled } from "@ant-design/icons";
import { Stat, PayDisplay, MessageButton } from "..";
import { Radio, Rate, Collapse, Skeleton, Checkbox } from "antd/es";
import { useMediaQuery } from "react-responsive";
// import { Button } from "antd";
import { Link } from "@reach/router";

import { RightCircleOutlined } from "@ant-design/icons";

import { Review as ReviewInterface } from "../../reviews";
import { database } from "../../database";

const rateColor = (value) =>
  value >= 4 ? "#2FF495" : value >= 3 ? "#F9E02B" : "#F23E30"; //2FF495 30ADF2

const Question: React.FunctionComponent<{
  label: string;
  className?: string;
}> = ({ label, className = "", children }) => (
  <div className={"question" + className}>
    <div className="question__label">{label}</div>
    <div className="question__content">{children}</div>
  </div>
);

function NA_maker(content) {
  if (content === "") {
    content = "n.a.";
  }
  return content;
}

function is_remote_packager(content) {
  if (content === "undefined") {
    content = "n.a.";
  } else if (content === "Some of both") {
    content = "Remote + In-person";
  } else if (content === null) {
    content = "In-person";
  }
  return content;
}

// function googleSearchPackager(company_name) {
//   return company_name.replace("&", "%26");
// }

const radioStyle = {
  lineHeight: "30px",
};

function companyPageURLPackager(company_name) {
  console.log(company_name);
  return company_name.replace("&", "%26");
}

interface ReviewProps extends RouteComponentProps {
  reviewID?: string;
}
const Review = (props: ReviewProps) => {
  const isMobile = useMediaQuery({ query: "(min-width: 660px)" });
  const [review, setReview] = useState<ReviewInterface>();

  useEffect(() => {
    database
      .collection("review")
      .doc(props.reviewID)
      .get()
      .then((data) => {
        if (!data.data()) navigate("not-found");
        setReview(data.data() as ReviewInterface);
      })
      .catch((err) => {
        navigate("not-found");
      });
  }, [props.reviewID]);
  // const review = reviews.find(review => review.id === props.reviewID)

  //var apply_search_url = 'https://www.google.com/search?q='+{review.company.name};

  // Scroll back to top of the page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="myID" className="review-container">
      {review ? (
        <div className="review">
          <div className="review__company">
            {review.company.image && (
              <img
                src={review.company.image}
                alt=""
                className="review__company-image"
              />
            )}
            <div className="review__company-details">
              <h2 className="review__company-name">{review.company.name}</h2>
              <div className="review__company-description">
                {review.company.description}
              </div>
              <Link
                className="company-card__seeAll_top"
                to={"/companies/" + review.company.name}
              >
                {" "}
                {/*navigate("/submit"*/}See all reviews for{" "}
                {review.company.name} <RightCircleOutlined />
              </Link>
            </div>
          </div>

          <div className="review__card">
            <div className="review__position">
              <div className="review__position-name">
                <h2>{review.position}</h2>&emsp;
              </div>
            </div>
            <div className="messageButton_mobile">
              <MessageButton review={review} />
            </div>
            <div className="review__position-details">
              {review.internship_type && (
                <span className="review__internship-type">
                  {(review.structured_program ? "Structured" : "Unstructured") +
                    " " +
                    review.internship_type}
                </span>
              )}
              &emsp;
              {(review.location && (
                <span className="review__location">
                  <EnvironmentFilled /> {review.location}
                </span>
              )) || (
                <span className="review__location">
                  <EnvironmentFilled /> Remote
                </span>
              )}
              &emsp;
              <span className="review__terms">
                {review.terms.join(", ") && (
                  <>
                    <span className="review_terms_title">Employed: </span>
                    {review.terms.join(", ")}
                  </>
                )}
              </span>
            </div>
            <div>
              <span className="review-card__team">
                Format: {is_remote_packager(review.is_remote)}
                <br></br>
                {review.team && <>Department: {review.team}</>}
              </span>
            </div>
            <div className="review__ratings">
              <Stat title="Overall rating" className="review__overall-rating">
                <div
                  style={{
                    position: "relative",
                    width: "90px",
                    height: "90px",
                  }}
                >
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    width="70px"
                    height="70px"
                    viewBox="0 0 70 70"
                    style={{ margin: "10px" }}
                  >
                    <path
                      style={{ fill: rateColor(review.overall_rating) }}
                      d="M35,70C15.67,70,0,54.33,0,35S15.67,0,35,0s35,15.67,35,35L35,70z"
                    />
                  </svg>
                  <h2>{review.overall_rating}</h2>
                </div>
              </Stat>
              <Stat title="Culture rating" className="review__culture-rating">
                <Rate
                  character="●"
                  style={{ color: rateColor(review.culture_rating) }}
                  value={review.culture_rating}
                  disabled
                  allowHalf
                />
              </Stat>
              <Stat title="Work rating" className="review__work-rating">
                <Rate
                  character="●"
                  style={{ color: rateColor(review.work_rating) }}
                  value={review.work_rating}
                  disabled
                  allowHalf
                />
              </Stat>
            </div>
            <div className="review__content">
              {isMobile ? (
                <div className="review__short">
                  <div className="review__tools">
                    <h4>Tools Used</h4>
                    <div className="review__tools-content">
                      {review.tools.often.length > 0 && (
                        <Question
                          label="Used all the time"
                          className="review__tools-always"
                        >
                          {review.tools.often.join(", ")}
                        </Question>
                      )}
                      {review.tools.occasionally.length > 0 && (
                        <Question
                          label="Used occasionally"
                          className="review__tools-occasionally"
                        >
                          {review.tools.occasionally.join(", ")}
                        </Question>
                      )}
                      {review.tools.rarely.length > 0 && (
                        <Question
                          label="Used a little"
                          className="review__tools-little"
                        >
                          {review.tools.rarely.join(", ")}
                        </Question>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="review__compensation">
                      <h4>Compensation</h4>
                      <Question label="Pay">
                        {review.paydict ? (
                          <PayDisplay {...review.paydict} />
                        ) : (
                          review.pay
                        )}
                      </Question>
                      {review.housing_stipend && (
                        <Question label="Housing stipend">
                          {review.housing_stipend
                            ? review.housing_stipend
                            : "None"}
                        </Question>
                      )}
                      {review.benefits && (
                        <Question label="Other benefits">
                          {review.benefits}
                        </Question>
                      )}
                    </div>

                    <div className="review__profile">
                      <h4>Intern Profile</h4>
                      <Question label="Major">{review.major}</Question>
                      <Question label="Year">
                        {review.year.grad_level
                          ? review.year.grad_level.charAt(0).toUpperCase() +
                            review.year.grad_level.substring(1)
                          : ""}
                        , {review.year.year} year
                      </Question>
                      <Question label="School">{review.school}</Question>
                      {review.other_studies && (
                        <Question label="Other areas of study">
                          {review.other_studies}
                        </Question>
                      )}
                    </div>

                    {review.offer >= 0 && review.offer != 4 && (
                      <div className="review__offer">
                        <h4>Offers</h4>
                        <Question label="Offered full time position?">
                          {
                            [
                              "Yes, accepted",
                              "Offered, but declined",
                              "Not offered",
                              "Not applicable (e.g. too early)",
                            ][review.offer]
                          }
                        </Question>
                        {review.offer !== 0 &&
                          review.offer !== 1 &&
                          review.would_accept_offer !== 4 && (
                            <Question label="Would accept full time offer?">
                              {
                                [
                                  "Yes, definitely",
                                  "Maybe, I probably would",
                                  "No, definitely not" /* Used to be "Not offered" for some reason. So Reviews before 8-1-2020 that have a would_accept_offer value of 2 might have been clicking "not offered" instead of "No, defintely not"*/,
                                  "Maybe, but probably not",
                                ][review.would_accept_offer]
                              }
                            </Question>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <Collapse bordered={false} style={{ backgroundColor: "#fff" }}>
                  <Collapse.Panel header={<h4>Intern Profile</h4>} key="1">
                    <Question label="Major">{review.major}</Question>
                    <Question label="Year">
                      {review.year.grad_level
                        ? review.year.grad_level.charAt(0).toUpperCase() +
                          review.year.grad_level.substring(1)
                        : ""}
                      , {review.year.year} year
                    </Question>
                    <Question label="School">{review.school}</Question>
                    {review.other_studies && (
                      <Question label="Other areas of study">
                        {review.other_studies}
                      </Question>
                    )}
                  </Collapse.Panel>
                  <Collapse.Panel header={<h4>Compensation</h4>} key="2">
                    <Question label="Pay">
                      {review.paydict ? (
                        <PayDisplay {...review.paydict} />
                      ) : (
                        review.pay
                      )}
                    </Question>
                    {review.housing_stipend && (
                      <Question label="Housing stipend">
                        {review.housing_stipend
                          ? review.housing_stipend
                          : "None"}
                      </Question>
                    )}
                  </Collapse.Panel>
                  <Collapse.Panel header={<h4>Tools Used</h4>} key="3">
                    {review.tools.often.length > 0 && (
                      <Question
                        label="Used all the time"
                        className="review__tools-always"
                      >
                        {review.tools.often.join(", ")}
                      </Question>
                    )}
                    {review.tools.occasionally.length > 0 && (
                      <Question
                        label="Used occasionally"
                        className="review__tools-occasionally"
                      >
                        {review.tools.occasionally.join(", ")}
                      </Question>
                    )}
                    {review.tools.rarely.length > 0 && (
                      <Question
                        label="Used a little"
                        className="review__tools-little"
                      >
                        {review.tools.rarely.join(", ")}
                      </Question>
                    )}
                  </Collapse.Panel>
                  <Collapse.Panel header={<h4>Fulltime Offer</h4>} key="4">
                    <Question label="Offered full time position?">
                      {
                        [
                          "Yes",
                          "Offered, but declined",
                          "Not offered",
                          "Not applicable (e.g. too early)",
                        ][review.offer]
                      }
                    </Question>
                    {review.offer !== 0 && (
                      <Question label="Would accept full time offer?">
                        {
                          [
                            "Yes, definitely",
                            "Maybe, I probably would",
                            "Maybe, but probably not",
                            "No, definitely not",
                          ][review.would_accept_offer]
                        }
                      </Question>
                    )}
                  </Collapse.Panel>
                </Collapse>
              )}
              <div className="review__long">
                <div className="review__description">
                  <h2 className="review-header">My experience</h2>
                  <h3>Overview</h3>
                  <p>{review.project_description}</p>
                  <p>{review.description}</p>
                  <p>{review.optional_remarks}</p>
                </div>
                <div className="review__recommendation">
                  {review.pros && (
                    <>
                      <h3>Pros</h3>
                      {/* <Question label="Would recommend it to people who..."><p>{review.recommend}</p></Question> */}
                      <p>{review.pros}</p>
                    </>
                  )}
                </div>
                <div className="review__recommendation">
                  {review.cons && (
                    <>
                      <h3>Cons</h3>
                      {/* <Question label="Would recommend it to people who..."><p>{review.recommend}</p></Question> */}
                      <p>{review.cons}</p>
                    </>
                  )}
                </div>
                <div className="review__recommendation">
                  {/*<h3>Recommendations</h3>*/}
                  {review.recommend && (
                    <>
                      <h3>Would recommend it to people who...</h3>
                      {/* <Question label="Would recommend it to people who..."><p>{review.recommend}</p></Question> */}
                      <p>{NA_maker(review.recommend)}</p>
                    </>
                  )}
                  {review.not_recommend && (
                    <>
                      <h3>Would NOT recommend it to people who...</h3>
                      {/* <Question label="Would NOT recommend it to people who..."><p>{review.not_recommend}</p></Question> */}
                      <p>{NA_maker(review.not_recommend)}</p>
                    </>
                  )}
                </div>

                <div className="review__work">
                  {/* <Question label="Impact of work"> */}
                  <h3>Impact of work</h3>
                  {!isNaN(review.impact) && (
                    <Radio.Group value={review.impact}>
                      <Radio style={radioStyle} value={0}>
                        No impact
                      </Radio>
                      <Radio style={radioStyle} value={1}>
                        Not very impactful
                      </Radio>
                      <Radio style={radioStyle} value={2}>
                        Somewhat impactful
                      </Radio>
                      <Radio style={radioStyle} value={3}>
                        Impactful
                      </Radio>
                      <Radio style={radioStyle} value={4}>
                        Very impactful
                      </Radio>
                    </Radio.Group>
                  )}
                  {isNaN(review.impact) && (
                    <Radio.Group value={review.impact}>
                      <Radio style={radioStyle} value={"No impact"}>
                        No impact
                      </Radio>
                      <Radio style={radioStyle} value={"Not very impactful"}>
                        Not very impactful
                      </Radio>
                      <Radio style={radioStyle} value={"Somewhat impactful"}>
                        Somewhat impactful
                      </Radio>
                      <Radio style={radioStyle} value={"Impactful"}>
                        Impactful
                      </Radio>
                      <Radio style={radioStyle} value={"Very impactful"}>
                        Very impactful
                      </Radio>
                    </Radio.Group>
                  )}
                  {/* </Question> */}
                  {/* <Question label="How much knowledge or experience was needed going in (pre-requisites)?"> */}
                  {review.prerequisites && (
                    <>
                      <h3>
                        How much knowledge or experience was needed going in
                        (pre-requisites)?
                      </h3>
                      <Radio.Group value={review.prerequisites}>
                        <Radio style={radioStyle} value={0}>
                          None - they'll teach you what you need to know
                        </Radio>
                        <Radio style={radioStyle} value={1}>
                          Beginner - need basic knowledge/experience in this
                          area
                        </Radio>
                        <Radio style={radioStyle} value={2}>
                          Intermediate - need to be pretty familiar with this
                          area
                        </Radio>
                        <Radio style={radioStyle} value={3}>
                          Expert - need to have advanced knowledge / multiple
                          prior experiences in this area
                        </Radio>
                      </Radio.Group>
                    </>
                  )}
                  {/* </Question> */}
                  {/* <Question label="Time spent working"> */}
                  {review.work_time && !isNaN(review.work_time) && (
                    <>
                      <h3>Time spent working</h3>
                      <Radio.Group value={review.work_time}>
                        <Radio style={radioStyle} value={0}>
                          0-20% (No work)
                        </Radio>
                        <Radio style={radioStyle} value={1}>
                          20-40%
                        </Radio>
                        <Radio style={radioStyle} value={2}>
                          40-60%
                        </Radio>
                        <Radio style={radioStyle} value={3}>
                          60-80%
                        </Radio>
                        <Radio style={radioStyle} value={4}>
                          80-100% (Very busy)
                        </Radio>
                      </Radio.Group>
                    </>
                  )}
                  {review.work_time && isNaN(review.work_time) && (
                    <>
                      <h3>Time spent working</h3>
                      <Radio.Group value={review.work_time}>
                        <Radio style={radioStyle} value={"0-20% (no work)"}>
                          0-20% (No work)
                        </Radio>
                        <Radio style={radioStyle} value={"20-40%"}>
                          20-40%
                        </Radio>
                        <Radio style={radioStyle} value={"40-60%"}>
                          40-60%
                        </Radio>
                        <Radio style={radioStyle} value={"60-80%"}>
                          60-80%
                        </Radio>
                        <Radio style={radioStyle} value={"80-100% (very busy)"}>
                          80-100% (Very busy)
                        </Radio>
                      </Radio.Group>
                    </>
                  )}
                  {review.remote_description && (
                    <div className="review__remotedescription">
                      <h3>How did working remote affect your experience?</h3>

                      <p>{review.remote_description}</p>
                    </div>
                  )}
                  <div>
                    {(review.rounds ||
                      review.interview_advice ||
                      review.interview_application) && (
                      <>
                        <h2 className="review-header">
                          <br></br>Interview advice
                        </h2>

                        {review.interview_application &&
                          review.interview_application.length > 0 && (
                            <>
                              <h3>How did you find the job / apply?</h3>
                              <Checkbox.Group
                                value={review.interview_application}
                                options={[
                                  {
                                    label:
                                      "Online job board (e.g., Linkedin, Indeed, etc.)",
                                    value: "job-board",
                                  },
                                  {
                                    label: "School job board",
                                    value: "school-job-board",
                                  },
                                  {
                                    label: "Company's career page",
                                    value: "career-page",
                                  },
                                  {
                                    label: "Networking / personal referral",
                                    value: "referral",
                                  },
                                  {
                                    label: "Career fair",
                                    value: "career-fair",
                                  },
                                  {
                                    label: "Cold email",
                                    value: "cold-email",
                                  },
                                ]}
                              />
                            </>
                          )}
                        {review.interview_application_new &&
                          review.interview_application_new.length > 0 && (
                            <>
                              <h3>How did you find the job / apply?</h3>
                              <Checkbox.Group
                                value={review.interview_application_new}
                                options={[
                                  {
                                    label:
                                      "Online job board (e.g., Linkedin, Indeed, etc.)",
                                    value: "Online job board",
                                  },
                                  {
                                    label: "School job board",
                                    value: "School job board",
                                  },
                                  {
                                    label: "Company's career page",
                                    value: "Company career page",
                                  },
                                  {
                                    label: "Referral/networking",
                                    value: "Referral/networking",
                                  },
                                  {
                                    label: "Career fair",
                                    value: "Career fair",
                                  },
                                  {
                                    label: "Cold email",
                                    value: "Cold email/call",
                                  },
                                ]}
                              />
                            </>
                          )}
                        {review.rounds && (
                          <>
                            <h3>Interview Rounds</h3>
                            <Radio.Group value={review.rounds}>
                              <Radio value={"1"}>1</Radio>
                              <Radio value={"2"}>2</Radio>
                              <Radio value={"3"}>3</Radio>
                              <Radio value={"4+"}>4+</Radio>
                            </Radio.Group>
                          </>
                        )}
                        {review.formats && review.formats.length > 0 && (
                          <>
                            <h3>Interview format(s)</h3>
                            <Checkbox.Group
                              value={review.formats}
                              options={[
                                { label: "In-person", value: "in_person" },
                                {
                                  label:
                                    "Remote (video conference, phone call, etc.)",
                                  value: "remote",
                                },
                                { label: "Recorded video", value: "recorded" },
                                {
                                  label:
                                    "Online test (e.g. HireVue, HackerRank)",
                                  value: "online_test",
                                },
                              ]}
                            />
                          </>
                        )}
                        {review.interview_types &&
                          review.interview_types.length > 0 && (
                            <>
                              <h3>Interview type</h3>
                              <Checkbox.Group
                                value={review.interview_types}
                                options={[
                                  { label: "Behavioral", value: "behavioral" },
                                  { label: "Technical", value: "technical" },
                                  { label: "Case", value: "case" },
                                  {
                                    label: "Past Experience",
                                    value: "past_experience",
                                  },
                                ]}
                              />
                            </>
                          )}
                        {review.interview_types_new &&
                          review.interview_types_new.length > 0 && (
                            <>
                              <h3>Interview type</h3>
                              <Checkbox.Group
                                value={review.interview_types_new}
                                options={[
                                  { label: "Behavioral", value: "Behavioral" },
                                  {
                                    label: "Knowledge (theoretical)",
                                    value: "Knowledge (theoretical)",
                                  },
                                  {
                                    label: "Knowledge (technical)",
                                    value: "Knowledge (technical)",
                                  },
                                  { label: "Case", value: "Case" },
                                  {
                                    label: "Past Experience",
                                    value: "Past experience",
                                  },
                                ]}
                              />
                            </>
                          )}
                        {review.interview_questions && (
                          <>
                            <h3>Interview questions</h3>
                            <p>{review.interview_questions}</p>
                          </>
                        )}

                        {/* </Question> */}
                        {review.interview_advice && (
                          <div className="review__advice">
                            <h3>Advice on how to prepare</h3>
                            <p>{NA_maker(review.interview_advice)}</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <br></br>
                  More questions? Send the reviewer a message!
                  <MessageButton review={review} />
                </div>
              </div>
            </div>
          </div>
          <div className="company-card__seeAll_bottom">
            <Link
              className="company-card__seeAll_bottom"
              to={"/companies/" + companyPageURLPackager(review.company.name)}
            >
              {" "}
              {/*navigate("/submit"*/}See all reviews for {review.company.name}{" "}
              <RightCircleOutlined />
            </Link>
          </div>
        </div>
      ) : (
        <Skeleton active paragraph={{ rows: 10 }} />
      )}
    </div>
  );
};

export default Review;
