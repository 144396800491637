import React, { useState, useEffect } from "react";
import "./style.css";
import { locations, companies } from "../SubmitAutocomplete.js";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { useCookies } from "react-cookie";
import { RouteComponentProps, navigate } from "@reach/router";
import {
  Input,
  Form,
  Button,
  Select,
  Radio,
  Tooltip,
  Rate,
  Checkbox,
  InputNumber,
  Tabs,
  AutoComplete,
  notification,
} from "antd/es";
import {
  QuestionCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { YearValue, MyInfo as myInfoInterface } from "../../reviews";
import { database } from "../../database";
// import { createEmitAndSemanticDiagnosticsBuilderProgram } from "typescript";
import { useAuth } from "../../contexts/AuthContext";

let isDisabled = false;

const close = (key) => {
  console.log(
    "Notification was closed. Either the close button was clicked or duration time elapsed."
  );
  notification.close(key);
  navigate("/register");
};

const mustSignUpNotification = () => {
  const key = `open${Date.now()}`;
  const tooltp = (
    <span>
      We won't spam you, sell your email, or reveal your indentity to anyone.{" "}
      <span role="img" aria-label="tophat">
        🕵
      </span>{" "}
      <Tooltip
        className="input-horizontal-spacer"
        title="Canary ensures all reviews are from real students. All reviews are anonymous and personal info like email is only used to verify your account, nothing more. 🔐"
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </span>
  );
  const btn = (
    <Button type="primary" size="large" onClick={() => close(key)}>
      Sign up
    </Button>
  );
  notification.open({
    placement: "topRight",
    duration: 5,
    top: 100,
    message: "Sign up to help your fellow student! 😊",
    description: tooltp,
    btn,
    key,
  });
};

const { Option } = Select;

const dev_is_required = true;

const { TabPane } = Tabs;

const years = [
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
];
const semesters = ["Spring", "Summer", "Fall"];

const terms1 = years
  .reduce((acc, year) => {
    let perm = semesters.reduce(
      (a, semester) => [...a, semester + " " + year],
      [] as string[]
    );
    return acc.concat(perm);
  }, [] as string[])
  .map((option, i) => (
    <Option value={option} key={i}>
      {option}
    </Option>
  ));

const terms = terms1.reverse();

function callback(key) {
  console.log(key);
}

const SubmitTab = ({ unfinished, setSubmitPage }) => {
  const [form] = Form.useForm();
  const [cookies, setCookie, removeCookie] = useCookies([
    "name",
    "email",
    "school",
    "majors",
    "year",
  ]);
  const [hasHousingStipend, setHasHousingStipend] = useState(false);
  //const [submitPage, setSubmitPage] = useState<String>();
  const { currentUser, logout } = useAuth();
  const [myInfo, setMyInfo] = useState<myInfoInterface>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  // const [unfinished, setUnfinished] = useState(false);
  useEffect(() => {
    async function getUserInfo(userID) {
      console.log("hey");
      database
        .collection("users")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          setMyInfo(doc.data() as myInfoInterface);
          console.log(doc.data());
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if (currentUser) {
    //   isDisabled = false;
    //   getUserInfo(currentUser.uid);
    // }
  }, []);

  useEffect(() => {
    //   window.scrollTo(0, 0);
  }, []);
  // console.log(unfinished);

  return (
    <>
      <div className="section-card submit">
        <Form.Item
          name="feedback"
          label="What can we improve on Canary? What questions should we remove or add?"
        >
          <Input.TextArea disabled={isDisabled} rows={1}></Input.TextArea>
        </Form.Item>

        <Form.Item
          name="feedback_length"
          label={<span>How was the length of this form?</span>}
          rules={[
            {
              required: false,
              message: "",
            },
          ]}
        >
          <Radio.Group disabled={isDisabled}>
            <Radio.Button value="Too short">Too short</Radio.Button>
            <Radio.Button value="Just right">Just Right</Radio.Button>
            <Radio.Button value="Too long">Too long</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="permission"
          label="Conditions"
          rules={[{ required: true, message: "Please check to accept" }]}
          valuePropName="checked"
        >
          <Checkbox
            disabled={isDisabled}
            onChange={() => setTermsAccepted(!termsAccepted)}
          >
            By clicking the checkbox, I give permission to Canary to share my
            anonymized information on its website and with third parties. I have
            read and agree to Canary's{" "}
            <a
              href="https://app.termly.io/document/privacy-policy/c599c195-4b40-430f-8387-3288a250e2fe"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            ,{" "}
            <a
              href="https://app.termly.io/document/terms-of-use-for-website/8cc4f576-8bd2-4b88-a984-0e30a80851ce"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            , and{" "}
            <a
              href="https://docs.google.com/document/d/1VdlOuxD-Qmh5yCBLh7QtXtjBOvFoSgjT-32u9nhBzvM/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              our Community Guidelines
            </a>
            .
          </Checkbox>
        </Form.Item>
        <div className="dataExplanation-submit">
          <p className="dataExplanationText-submit">
            Privacy is priority #1 with Canary{" "}
            <span role="img" aria-label="lock">
              🔒
            </span>{" "}
            <br></br>
            <a target="_blank" rel="noopener noreferrer" href="/FAQ">
              Check out our FAQ
            </a>{" "}
            for answers to all your data-related questions.
          </p>
        </div>
        <Form.Item>
          <Button
            className="submit-nextPage"
            type="primary"
            size="large"
            onClick={() => {
              setSubmitPage("4");
              window.scrollTo({
                top: 100,
                behavior: "smooth",
              });
            }}
          >
            <LeftCircleOutlined /> Back
          </Button>
          <Button
            disabled={!termsAccepted}
            type="primary"
            htmlType="submit"
            size="large"
          >
            Submit
          </Button>
          <div>
            {unfinished && (
              <div className="unfinished-text">
                Whoops! You left some required fields blank - go back to fill
                them in!
              </div>
            )}
          </div>
        </Form.Item>
      </div>
    </>
  );
};

const verticalStyle = {
  lineHeight: "30px",
  display: "block",
};

export default SubmitTab;
