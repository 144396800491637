import React, { useState, useEffect } from "react";
import { RouteComponentProps, useLocation } from "@reach/router";
import "./style.css";
// import algoliasearch from "algoliasearch";
import { useAuth } from "../../contexts/AuthContext";
import { Carousel, Button, Input, Pagination, Spin, Result, Form } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import {
  ArrowDownOutlined,
  RightOutlined,
  LeftOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { DiscoverCompanyCard } from "..";
import { Review, Company, Ratings } from "../../reviews";
import { database } from "../../database";
import { useMediaQuery, useRef } from "react-responsive";

const DiscoverResults = ({ type, tag }: { type: string; tag: string }) => {
  const [items, setItems] = useState<Company[]>([]);
  const [selected, setSelected] = React.useState([]);
  const [position, setPosition] = React.useState(0);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  let collection = "";
  if (type == "companies") {
    collection = "companies_public";
  } else if (type == "reviews") {
    collection = "review";
  }

  useEffect(() => {
    database
      .collection(collection)
      .where("tags", "array-contains", tag)
      .orderBy("num_reviews", "desc")
      .get()
      .then((data) => {
        let item_data = data.docs.map((d) => d.data());
        //loading = false;

        // companies_data.sort(sortFunctions[rating_toShow]);
        {
          /*item_data = item_data.slice(0, 25);
  if (collection == "reviews") {
    setReviewItems(item_data as Review[]);
  } else if (collection == "companies_public") {}
*/
        }
        setItems(item_data as Company[]);
      })
      .catch((err: string) => {
        console.log(err);
      });
  }, [tag]);

  return (
    <div className="discover-results">
      {(items.length > 0 && (
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {items.map((company, i) => (
            <Card
              itemId={i}
              title={""}
              key={i}
              onClick={handleClick(i)}
              selected={isItemSelected(i)}
              company={company}
            />
          ))}
        </ScrollMenu>
      )) || <Spin className="text-center discover-spinner" size="large" />}
    </div>
  );
};

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <div className="clickToScroll" onClick={() => scrollPrev()}>
      <div className="clickToScrollButton clickToScrollButton-left">
        <LeftOutlined />
      </div>
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <div className="clickToScroll" onClick={() => scrollNext()}>
      <div className="clickToScrollButton clickToScrollButton-right">
        <RightOutlined />
      </div>
    </div>
  );
}

function Card({ onClick, selected, title, itemId, company }) {
  const visibility = React.useContext(VisibilityContext);

  return (
    <div onClick={() => onClick(visibility)} tabIndex={0}>
      <div style={{ height: "10px" }}></div>
      <div className="card">
        <DiscoverCompanyCard
          name={company.name}
          company_id={company.id}
          num_reviews={company.num_reviews}
          ratings={company.ratings}
          description={company.industry}
          image={company.image}
        />
      </div>
      <div style={{ height: "10px" }}></div>
    </div>
  );
}

export default DiscoverResults;
