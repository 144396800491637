import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  AutoComplete,
  Tooltip,
} from "antd";
import { RouteComponentProps, Link, navigate } from "@reach/router";
import { useAuth } from "../../contexts/AuthContext";
import { schools_static, majors_static } from "../SubmitAutocomplete.js";
import { auth, database } from "../../database";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import * as EmailValidator from "email-validator";
import { RegisterPortal } from "..";

import bird_side from "../../images/icons/bird_side.png";

interface RegisterProps extends RouteComponentProps {
  homeMajor: any;
  homeSchool: any;
  homeEmail: any;
}

const DynamicRule = (props: RegisterProps) => {
  const [form] = Form.useForm();
  // Setting the state of all the input fields
  const [emailInput, setEmailInput] = useState(props.homeEmail || "");
  const [passwordInput, setPasswordInput] = useState("");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const [usernameInput, setUsernameInput] = useState("");
  // const [schoolInput, setSchoolInput] = useState("");
  // const [majorInput, setMajorInput] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { register, currentUser } = useAuth();

  const dev_is_required = true;
  const isMobile = useMediaQuery({ query: "(min-width: 660px)" });

  // Function called upon clicking the submit button
  async function handleSubmit() {
    //e) {
    //e.preventDefault();

    // let cont = false;
    //console.log(usernameInput);
    var ref = database.collection("usernames").doc(usernameInput);
    ref
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          //console.log(doc);
          return setError("Username already exists");
        } else {
          if (passwordInput !== confirmPasswordInput) {
            return setError("The passwords that you entered do not match");
          } else {
            try {
              setError("");
              setLoading(true);
              await register(emailInput, passwordInput);
              console.log("AUTH SUCCESS");
              database
                .collection("usernames")
                .doc(usernameInput)
                .set({ username: usernameInput })
                .catch((err) => {
                  console.log("error");
                  return setError("Username could not be added");
                });
              //database.collection("user").doc(currentUser.id).set(user);
              navigate("/submit");
            } catch {
              setError("Failed to create an account");
            }
            setLoading(false);
          }
          // cont = true;
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  const onSuccess = (values) => {
    handleSubmit();

    auth.onAuthStateChanged(function (user) {
      if (user) {
        const UID = user.uid;

        console.log(UID);

        const user_set = {
          id: UID,
          username: values.username,
          major: values.major,
          school: values.school,
          user_timestamp: { seconds: new Date().getTime() / 1000 },
        };

        database
          .collection("users")
          .doc(user_set.id)
          .set(user_set)
          .catch((err) => {
            console.log("User record creation unsuccessful:", err);
            //navigate("/submit-error");
          });

        console.log("FIRESTORE SUCCESS");
      }
    });
  };

  const Submit = ({ onSubmit }) => (
    <Form.Item>
      <Button
        //disabled={loading}
        type="primary"
        htmlType="submit"
        disabled={!termsAccepted}
      >
        {" "}
        Register{" "}
      </Button>
      <br></br>
      <div className="already-have-account">
        Already have an account? <Link to="/login"> Log In here! </Link>
      </div>
    </Form.Item>
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Register">
      <div className="Register_Title">
        <h1 className="align-center">
          Join the Flock <img className="icon-emoji-big" src={bird_side}></img>
        </h1>
        <h3 className="align-center">
          Reviews are anonymous. We don't share your identity.{" "}
          <Tooltip
            className="input-horizontal-spacer"
            title="All Canary reviews are anonymous. Your email and username are not publicly connected to your review or shared with Employers/ Schools /etc 🔐"
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </h3>
      </div>
      <RegisterPortal
        setAccountCreated=""
        setMyInfo=""
        setIsDisabled=""
        portalStyle="portalCard"
      />
    </div>
  );
};

const Register = (props: RegisterProps) => {
  return (
    <div className="">
      <DynamicRule
        homeEmail={props.homeEmail}
        homeSchool={props.homeSchool}
        homeMajor={props.homeMajor}
      />
    </div>
  );
};

export default Register;
