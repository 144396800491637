import React, { useState } from "react";
import "./style.css";

import { Button, Modal } from "antd";
import { Form, Input } from "antd/es";

import { MessageOutlined, SendOutlined } from "@ant-design/icons";
import { useAuth } from "../../contexts/AuthContext";
import { auth, database } from "../../database";
import { useMediaQuery } from "react-responsive";

import login from "../../images/icons/login.png";
import registerIcon from "../../images/icons/register.png";
import bird_front from "../../images/icons/bird_front.png";

const MessageButton = ({ review }) => {
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [contactModalLoading, setContactModalLoading] = useState(false);
  const [form] = Form.useForm();
  const { currentUser, logout } = useAuth();

  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  const isMobile = !isDesktop;

  function showModal() {
    setContactModalVisible(true);
  }

  function handleOk(values) {
    // Get the sender's user info (major and school) to input into the email to the poster
    console.log("Send pressed");
    database
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        const senderMajor = doc.data()?.major;
        const senderSchool = doc.data()?.school;
        const senderUsername = doc.data()?.username;
        if (values.note) {
          database
            .collection("messages_pending")
            .doc()
            .set({
              reviewID: review.id,
              position: review.position,
              company: review.company.name,
              senderMajor: senderMajor,
              senderSchool: senderSchool,
              senderUsername: senderUsername,
              senderID: currentUser.uid,
              senderEmail: currentUser.email,
              note: values.note,
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });

    setContactModalLoading(true);
    setTimeout(() => {
      setContactModalLoading(false);
      setContactModalVisible(false);
    }, 3000);
  }

  function handleCancel() {
    setContactModalVisible(false);
  }
  // const onSubmit = (values) => {
  //   console.log(values);
  // };
  const { TextArea } = Input;

  return (
    <div>
      <div onClick={showModal} className="sendMessageToPosterButton">
        <span className="sendMessageToPosterIcon">
          <MessageOutlined />
        </span>
        {(isDesktop && (
          <span className="sendMessageToPosterText">Message reviewer</span>
        )) || <span className="sendMessageToPosterText">Message</span>}
      </div>

      {(currentUser && (
        <Modal
          visible={contactModalVisible}
          title="Send the reviewer a message!"
          //onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              type="primary"
              form={review.id}
              key="submit"
              htmlType="submit"
              loading={contactModalLoading}
              //onClick={handleOk}
            >
              Send <SendOutlined />
            </Button>,
          ]}
        >
          {/*<h4>Enter a note to send to the reviewer!</h4>*/}
          <h4>
            You are messaging the reviewer of{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"reviews/" + review.id}
            >
              {review.position}
            </a>{" "}
            at{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"/companies/" + review.company.name}
            >
              {review.company.name}
            </a>
          </h4>
          <br></br>
          <p>
            Ask more questions on their experience, what you should brush up on
            for the interview, or any other questions you have! This will send
            an email to the poster of the review{" "}
            <strong>
              that includes your major, school, email address, and your message.
            </strong>{" "}
            Then they can respond directly to you with their advice!
          </p>
          <br></br>
          <Form id={review.id} form={form} onFinish={handleOk}>
            <Form.Item
              name="note"
              rules={[
                {
                  required: true,
                  message: "Please enter your note!",
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Form>
        </Modal>
      )) || (
        <Modal
          visible={contactModalVisible}
          title="Sign up to send the reviewer a message!"
          //onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
        >
          <p>
            Want to ask what classes you should take or how you should prepare
            for the interview? When you join the Flock, you can send messages
            directly to the reviewer, and they can respond with their answers
            and advice. (it's free!)
          </p>
          <br></br>
          <Button
            className="signUpToSendMessageButton"
            type="primary"
            href="/register"
          >
            <img src={registerIcon} className="icon-emoji"></img>
            Sign up{" "}
          </Button>
          <Button
            className="signUpToSendMessageButton"
            type="primary"
            href="/login"
          >
            <img src={login} className="icon-emoji"></img>
            Log in{" "}
          </Button>
        </Modal>
      )}
    </div>
  );
};

export default MessageButton;
