import React, { useState, useEffect } from "react";
import "./style.css";
import { locations, companies } from "../SubmitAutocomplete.js";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { useCookies } from "react-cookie";
import { RouteComponentProps, navigate } from "@reach/router";
import {
  Input,
  Form,
  Button,
  Select,
  Radio,
  Tooltip,
  Rate,
  Checkbox,
  InputNumber,
  Tabs,
  AutoComplete,
  notification,
  Slider,
  Alert,
} from "antd/es";
import {
  QuestionCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { YearValue, MyInfo as myInfoInterface } from "../../reviews";
import { database } from "../../database";
// import { createEmitAndSemanticDiagnosticsBuilderProgram } from "typescript";
import { useAuth } from "../../contexts/AuthContext";
import { schools_static, majors_static } from "../SubmitAutocomplete.js";
import * as EmailValidator from "email-validator";

let isDisabled = false;

const { Option } = Select;

const dev_is_required = true;

const { TabPane } = Tabs;

const years = [
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
];
const semesters = ["Spring", "Summer", "Fall"];

const terms1 = years
  .reduce((acc, year) => {
    let perm = semesters.reduce(
      (a, semester) => [...a, semester + " " + year],
      [] as string[]
    );
    return acc.concat(perm);
  }, [] as string[])
  .map((option, i) => (
    <Option value={option} key={i}>
      {option}
    </Option>
  ));

const terms = terms1.reverse();

function callback(key) {
  console.log(key);
}

const EmoryQuestionsTab = ({ setSubmitPage, index }) => {
  const [form] = Form.useForm();
  const [cookies, setCookie, removeCookie] = useCookies([
    "name",
    "email",
    "school",
    "majors",
    "year",
  ]);
  const [hasHousingStipend, setHasHousingStipend] = useState(false);
  // const [submitPage, setSubmitPage] = useState("1");

  const { currentUser, logout } = useAuth();

  const [myInfo, setMyInfo] = useState<myInfoInterface>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  // const [unfinished, setUnfinished] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [params, setParams] = useState("");
  const [unfinished, setUnfinished] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function onFail() {
    setUnfinished(true);
  }

  function onSuccess(values) {
    function fixAmpersand(word) {
      let newWord = word.replace("&", "%26");
      return newWord;
    }

    const newParams =
      "school=" +
      "Emory University" +
      "&email=" +
      fixAmpersand(values.email) +
      "&company=" +
      fixAmpersand(values.company) +
      "&position=" +
      fixAmpersand(values.position) +
      "&location=" +
      fixAmpersand(values.location);

    setParams(newParams);

    setSubmitSuccess(true);

    const surveyResponse = {
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      school: "Emory University",
      timestamp: { seconds: new Date().getTime() / 1000 },
      email: values.email || "",
      company: values.company || "",
      position: values.position || "",
      location: values.location || "",
      survey_id: "emory_1",
    };

    database
      .collection("survey")
      .doc(surveyResponse.id)
      .set(surveyResponse)
      .catch((err: string) => {
        console.log(err);
      });
  }

  function navigateToSubmit(params) {
    navigate("/submit/?" + params);
    window.location.reload();
  }

  return (
    <>
      <div className="section-card internship-details emory-questions">
        <Form
          scrollToFirstError={true}
          onFinish={onSuccess}
          onFinishFailed={onFail}
          form={form}
          layout="vertical"
          name="emory-questions"
        >
          {!submitSuccess && (
            <>
              <h2 className="emory-text">Your internship</h2>
              <Form.Item
                name="email"
                label={
                  <span>
                    Emory email{" "}
                    <Tooltip
                      className="input-horizontal-spacer"
                      title="This is only shared with the Emory Career Center. This will NEVER be public or shared w/o your permission."
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please enter your email",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value.endsWith("@emory.edu") ||
                        !EmailValidator.validate(value) ||
                        value === null
                      ) {
                        return Promise.reject(
                          "Please enter a valid Emory email address"
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input
                  style={{ maxWidth: "300px" }}
                  placeholder="Please enter your Emory email"
                  className=""
                  disabled={isDisabled}
                />
              </Form.Item>

              <Form.Item
                className=""
                name="company"
                label="Company"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Where did you intern?",
                  },
                ]}
              >
                <AutoComplete
                  className=""
                  disabled={isDisabled}
                  options={companies}
                  placeholder="Where did you intern?"
                  style={{ maxWidth: "300px" }}
                  filterOption={(inputValue, option) =>
                    option
                      ? option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      : false
                  }
                />
              </Form.Item>

              <Form.Item
                className=""
                name="position"
                label="Position title"
                rules={[
                  {
                    required: dev_is_required,
                    message: "What was your position title?",
                  },
                ]}
              >
                <Input
                  style={{ maxWidth: "300px" }}
                  placeholder="What was your position title?"
                  className=""
                  disabled={isDisabled}
                />
              </Form.Item>

              <Form.Item
                className=""
                name="location"
                label="Location"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Where was your internship?",
                  },
                ]}
              >
                <Input
                  style={{ maxWidth: "300px" }}
                  placeholder='City, State (or "Remote")'
                  className=""
                  disabled={isDisabled}
                />
              </Form.Item>

              {/*{(index == 0 && (
                      <Button
                        className="submit-nextPage"
                        type="primary"
                        size="large"
                        onClick={() => {
                          setSubmitPage("2");
                          window.scrollTo({
                            top: 100,
                            behavior: "smooth",
                          });
                        }}
                      >
                    )) || (
                      <div className="section-card about-you">
                        <h3 className="willBePosted_disclaimer">
                          🔒 This section is private to the Emory Career Center
                        </h3>
                      </div>
                    )}*/}
              {/* {index===0 ? <Button
                    className="submit-nextPage"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSubmitPage("2");
                      window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                      });
                    }}
                  >
                  </Button>:<Button/>} */}

              <Button
                className="submit-nextPage"
                type="primary"
                size="large"
                htmlType="submit"
              >
                Submit <CheckCircleOutlined />
              </Button>
            </>
          )}

          {/*<Button
          className="submit-nextPage"
          type="primary"
          size="large"
          onClick={() => {
            setSubmitPage("2");
            window.scrollTo({
              top: 100,
              behavior: "smooth",
            });
          }}
        >
          Next <RightCircleOutlined />
        </Button>*/}
          {submitSuccess && (
            <div>
              <Alert
                type="success"
                showIcon
                message="Response submitted!"
                description="Your response has been submitted to the Emory Career Center. Now please continue on to leave your full internship review on Canary!"
              ></Alert>
              <br></br>
              <Button
                className="submit-nextPage"
                type="primary"
                size="large"
                onClick={() => {
                  navigateToSubmit(params);
                }}
              >
                Leave review on Canary <RightCircleOutlined />
              </Button>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

const verticalStyle = {
  lineHeight: "30px",
  display: "block",
};

export interface PayValue {
  type: "hourly" | "lump" | "monthly" | "weekly";
  amount: number;
  currency: "USD" | "EUR";
}

interface YearInputProps {
  value?: YearValue;
  onChange?: (value: YearValue) => void;
}

const YearInput: React.FC<YearInputProps> = ({ value = {}, onChange }) => {
  const [gradLevel, setGradLevel] = useState<
    "undergraduate" | "graduate" | "graduated"
  >();
  const [year, setYear] = useState<
    "1st" | "2nd" | "3rd" | "4th" | "5th" | "6th+"
  >();

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange({ grad_level: gradLevel, year, ...value, ...changedValue });
    }
  };

  const onGradLevelChange = (e) => {
    let newVal = e.target.value;
    if (!("grad_level" in value)) {
      setGradLevel(newVal);
      if (!("year" in value)) {
        setYear("1st");
      }
    }
    triggerChange({ grad_level: newVal, year: value.year || "1st" });
  };

  const onCurrencyChange = (e) => {
    let newVal = e.target.value;
    if (!("year" in value)) {
      setYear(newVal);
      if (!("grad_level" in value)) {
        setGradLevel("undergraduate");
      }
    }
    triggerChange({
      year: newVal,
      grad_level: value.grad_level || "undergraduate",
    });
  };

  return (
    <span className="year-select">
      <Radio.Group value={value.grad_level} onChange={onGradLevelChange}>
        <Radio.Button value="undergraduate">Undergraduate</Radio.Button>
        <Radio.Button value="graduate">Graduate</Radio.Button>
        <Radio.Button value="graduated">Graduated</Radio.Button>
      </Radio.Group>
      <div className="year-select-number">
        <Radio.Group
          value={value.grad_level === "graduated" ? "" : value.year}
          onChange={onCurrencyChange}
          disabled={value.grad_level === "graduated"}
          optionType="default"
        >
          <Radio.Button value="1st">1st</Radio.Button>
          <Radio.Button value="2nd">2nd</Radio.Button>
          <Radio.Button value="3rd">3rd</Radio.Button>
          <Radio.Button value="4th">4th</Radio.Button>
          <Radio.Button value="5th">5th</Radio.Button>
          <Radio.Button value="6th+">6th+</Radio.Button>
        </Radio.Group>
      </div>
    </span>
  );
};

export default EmoryQuestionsTab;
