import React from "react";
import "./style.css";
import { Review, Company, Ratings } from "../../reviews";
import { useMediaQuery } from "react-responsive";
import { RightCircleOutlined, RightOutlined } from "@ant-design/icons";

// This removes the company name from the "See All" link if it's too long (so it doesn't clutter up the UI)
function longNameFix(company_name, isDesktop) {
  let limit = 20;
  if (!isDesktop) {
    limit = 15;
  }

  if (company_name.length > limit) {
    return "this company";
  } else {
    return company_name;
  }
}

const rateColor = (value) =>
  value >= 4 ? "#2FF495" : value > 2.5 ? "#F9E02B" : "#F23E30"; //2FF495 30ADF2

const DiscoverCompanyCard = ({
  name,
  description,
  image,
  company_id,
  num_reviews,
  ratings,
}: {
  name: string;
  description: string;
  image?: string;
  company_id: string;
  num_reviews: number;
  ratings: Ratings;
}) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  const isMobile = !isDesktop;

  ratings.overall_avg = Math.round(ratings.overall_avg * 10) / 10;
  ratings.work_avg = Math.round(ratings.work_avg * 10) / 10;
  ratings.culture_avg = Math.round(ratings.culture_avg * 10) / 10;

  return (
    <>
      {isDesktop && (
        <div className="discover-company-header">
          <img className="discover-company-logo" src={image}></img>
          <div className="discover-company-details">
            <h2 className="discover-company-name">{name}</h2>

            <p className="discover-company-industry">{description}</p>
          </div>

          <div className="company-search_avgRatings">
            <div>
              <p className="company-card_rating-title">Overall</p>
              <div
                className="company-card__overall-rating"
                style={{ background: rateColor(ratings.overall_avg) }}
              >
                {ratings.overall_avg}
                <span className="divisor">/5</span>
              </div>
            </div>
            <div>
              <p className="company-card_rating-title">Work</p>
              <div
                className="company-card__overall-rating"
                style={{ background: rateColor(ratings.work_avg) }}
              >
                {ratings.work_avg}
                <span className="divisor">/5</span>
              </div>
            </div>
            <div>
              <p className="company-card_rating-title">Culture</p>
              <div
                className="company-card__overall-rating"
                style={{ background: rateColor(ratings.culture_avg) }}
              >
                {ratings.culture_avg}
                <span className="divisor">/5</span>
              </div>
            </div>
          </div>
          <div className="company-search-featured__right">
            <a className="discover-company-seeAll" href={"/companies/" + name}>
              <div>
                See all <b>{num_reviews || ""}</b> reviews for<br></br>
                {longNameFix(name, isDesktop)}
              </div>
              <div className="discover-company-seeAll_arrow">
                <RightOutlined />
              </div>
            </a>
          </div>
        </div>
      )}

      {isMobile && (
        <div className="discover-company-header">
          <img className="discover-company-logo" src={image}></img>
          <br></br>
          <div className="discover-company-details">
            <h2 className="discover-company-name">{name}</h2>

            <p className="discover-company-industry">{description}</p>
          </div>

          <div className="company-search_avgRatings">
            <div>
              <p className="company-card_rating-title">Overall</p>
              <div
                className="company-card__overall-rating"
                style={{ background: rateColor(ratings.overall_avg) }}
              >
                {ratings.overall_avg}
                <span className="divisor">/5</span>
              </div>
            </div>
            <div>
              <p className="company-card_rating-title">Work</p>
              <div
                className="company-card__overall-rating"
                style={{ background: rateColor(ratings.work_avg) }}
              >
                {ratings.work_avg}
                <span className="divisor">/5</span>
              </div>
            </div>
            <div>
              <p className="company-card_rating-title">Culture</p>
              <div
                className="company-card__overall-rating"
                style={{ background: rateColor(ratings.culture_avg) }}
              >
                {ratings.culture_avg}
                <span className="divisor">/5</span>
              </div>
            </div>
          </div>
          <br></br>
          <a className="" href={"/companies/" + name}>
            <div className="discover-company-seeAll-mobile">
              See all <b>{num_reviews || ""}</b> reviews for{" "}
              {longNameFix(name, isDesktop)} <RightOutlined />
            </div>
          </a>
        </div>
      )}
    </>
  );
};

export default DiscoverCompanyCard;
