import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  AutoComplete,
  Tooltip,
} from "antd";
import { RouteComponentProps, Link, navigate } from "@reach/router";
import { useAuth } from "../../contexts/AuthContext";
import { schools_static, majors_static } from "../SubmitAutocomplete.js";
import { auth, database } from "../../database";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import * as EmailValidator from "email-validator";
import { YearValue, MyInfo as myInfoInterface } from "../../reviews";
import { ImportsNotUsedAsValues } from "typescript";

import login from "../../images/icons/login.png";
import registerIcon from "../../images/icons/register.png";
import bird_front from "../../images/icons/bird_front.png";

const SignUpRequired = ({}) => {
  const [form] = Form.useForm();
  // Setting the state of all the input fields
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const [usernameInput, setUsernameInput] = useState("");
  // const [schoolInput, setSchoolInput] = useState("");
  // const [majorInput, setMajorInput] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { register, currentUser } = useAuth();

  const dev_is_required = true;
  const isMobile = useMediaQuery({ query: "(min-width: 660px)" });

  // Function called upon clicking the submit button

  return (
    <>
      <div className="signUpRequired-container">
        <div className="signUpRequired-card">
          <h2 className="signUpRequired-header">Sign up to see all reviews</h2>
          <h4 className="signUpRequired-description">
            Canary is a community of students helping each other level-up our
            internship game.<br></br>
            Join the Flock <img src={bird_front} className="icon-emoji" />
            to get full access.
          </h4>
          <Button
            className="signUpToSendMessageButton"
            type="primary"
            size="large"
            href="/register"
          >
            <img src={registerIcon} className="icon-emoji"></img>
            Sign up{"  "}
          </Button>
          <Button
            className="signUpToSendMessageButton"
            type="primary"
            size="large"
            href="/login"
          >
            <img src={login} className="icon-emoji"></img>
            Log in {"  "}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SignUpRequired;
