import React, { useState } from "react";
import "./style.css";

import { Stat, PayDisplay, MessageButton } from "..";
import { Rate, Button } from "antd";
import { Collapse, notification, Form } from "antd/es";

import {
  RightCircleOutlined,
  HeartOutlined,
  HeartFilled,
} from "@ant-design/icons";
import { Link, navigate } from "@reach/router";
import { Review } from "../../reviews";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../contexts/AuthContext";
import { database } from "../../database";
import * as firebase from "firebase/app";
import "firebase/firestore";

import moment from "moment";
// import Review from '../pages/Review';

let notif_vis = "inline-block";

const rateColor = (value) =>
  value >= 4 ? "#2FF495" : value > 2.5 ? "#F9E02B" : "#F23E30"; //2FF495 30ADF2

interface ReviewCardProps {
  review: Review;
  note?: string;
  wordLimit?: number;
  charLimit?: number;
  overview?: string;
}

let liked = false;
let initialLikeUpdate = 0;

function dateFormatter(timestamp) {
  let currentTime = new Date().getTime() / 1000;

  var numDaysBetween = function (timestamp, currentTime) {
    var today = currentTime;
    // console.log("today", today);
    var diff = Math.abs(timestamp - today);
    // console.log("diff", diff);
    return diff / (60 * 60);
  };

  const timeDiff_hours = Math.floor(numDaysBetween(timestamp, currentTime));

  if (timeDiff_hours < 1) {
    //return "Just now";
    return "Today";
  } else if (timeDiff_hours == 1) {
    //return "1 hour ago";
    return "Today";
  } else if (timeDiff_hours <= 16) {
    //return <>{timeDiff_hours} hours ago</>;
    return "Today";
  } else if (timeDiff_hours <= 48) {
    return "Yesterday";
  } else if (timeDiff_hours <= 24 * 7) {
    return <>{Math.floor(timeDiff_hours / 24)} days ago</>;
  } else {
    return moment.unix(timestamp).format("MM/DD/YY");
  }
}

function is_remote_packager(content) {
  if (content === "undefined") {
    content = "n.a.";
  } else if (content === "Some of both") {
    content = "Remote+In-person";
  } else if (content === null) {
    content = "In-person";
  }
  return content;
}

const close = (key) => {
  console.log(
    "Notification was closed. Either the close button was clicked or duration time elapsed."
  );
  notification.close(key);

  navigate("/register");
};

const mustLoginNotification = () => {
  notif_vis = "inline-block";
  const key = `open${Date.now()}`;
  const btn = (
    <Button type="primary" size="large" onClick={() => close(key)}>
      Sign up
    </Button>
  );
  notification.open({
    placement: "topRight",
    top: 100,
    message: "Sign up to save reviews!",
    description:
      "Make an account so you can favorite the most helpful reviews for later",
    btn,
    key,
    style: { display: notif_vis },
    //@ts-ignore
    // onClose: close,
  });
};

/*Combine project_description and description for the review card display, but only combine for when project_descrption is not undefined (to handle legacy reviews with no project_description field) */

function reviewOverviewCombine(
  description,
  project_description,
  optional_remarks,
  pros,
  cons
) {
  let overview = "test";

  if (typeof description == "undefined") {
    description = "";
  }
  if (typeof project_description == "undefined") {
    project_description = "";
  }
  if (typeof optional_remarks == "undefined") {
    optional_remarks = "";
  }
  if (typeof pros == "undefined") {
    pros = "";
  }
  if (typeof cons == "undefined") {
    cons = "";
  }

  if (description != "" && project_description != "") {
    if (description[description.length - 1] != ".") {
      description = description + ". ";
    } else {
      description = description + " ";
    }
  }
  if (project_description != "" && optional_remarks != "") {
    if (project_description[project_description.length - 1] != ".") {
      project_description = project_description + ". ";
    } else {
      project_description = project_description + " ";
    }
  }
  if (optional_remarks != "" && pros != "") {
    if (optional_remarks[optional_remarks.length - 1] != ".") {
      optional_remarks = optional_remarks + ". ";
    } else {
      optional_remarks = optional_remarks + " ";
    }
  }
  if (pros != "" && cons != "") {
    if (pros[pros.length - 1] != ".") {
      pros = pros + ". ";
    } else {
      pros = pros + " ";
    }
  }

  if (
    project_description != "" &&
    optional_remarks == "" &&
    (pros != "" || cons != "")
  ) {
    if (project_description[project_description.length - 1] != ".") {
      project_description = project_description + ". ";
    } else {
      project_description = project_description + " ";
    }
  }

  overview = description + project_description + pros + cons + optional_remarks;

  return overview;
}

const ReviewCard = ({
  review,
  note = "",
  wordLimit = 35,
  charLimit = 300,
}: ReviewCardProps) => {
  const [likeReview, setLikeReview] = useState(<HeartOutlined />);
  const [onLoad, setOnLoad] = useState(0);
  const [form] = Form.useForm();
  const { currentUser } = useAuth(); //const { currentUser, logout } = useAuth();

  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });

  const isMobile = !isDesktop;

  if (currentUser && onLoad === 0) {
    database
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        //console.log(initialLikeUpdate);

        if (doc?.data()?.favorite_reviews?.includes(review.id)) {
          liked = true;
          //console.log("test1");
          setLikeReview(<HeartFilled className="redHeart" />);
        } else {
          liked = false;
          setLikeReview(<HeartOutlined />);
        }
        initialLikeUpdate = initialLikeUpdate + 1;
      });
    setOnLoad(1);
  }

  function favoriteReview(e) {
    if (e.target.nodeName !== "BUTTON") {
      if (currentUser) {
        liked = !liked;
        database
          .collection("users")
          .doc(currentUser.uid)
          .get()
          .then((doc) => {
            if (doc?.data()?.favorite_reviews?.includes(review.id)) {
              liked = false;
              setLikeReview(<HeartOutlined />);
              database
                .collection("users")
                .doc(currentUser.uid)
                .update({
                  favorite_reviews: firebase.firestore.FieldValue.arrayRemove(
                    review.id
                  ),
                });
            } else {
              liked = true;
              setLikeReview(<HeartFilled className="redHeart" />);
              database
                .collection("users")
                .doc(currentUser.uid)
                .update({
                  favorite_reviews: firebase.firestore.FieldValue.arrayUnion(
                    review.id
                  ),
                });
            }
          });

        return review.id;
      } else {
        mustLoginNotification();
      }
    }
  }

  function truncateDescription(description) {
    let wordLimited =
      description.split(" ").length > wordLimit
        ? description.split(" ").slice(0, wordLimit).join(" ") + " ..."
        : description;
    if (wordLimited.length > charLimit)
      return description.slice(0, charLimit).replace(/\s\.\.\./, "") + " ...";
    return wordLimited;
  }

  // function showModal() {
  //   if (currentUser) {
  //     setContactModalVisible(true);
  //   } else {
  //     mustLoginNotification();
  //   }
  // }
  // function handleOk(values) {
  //   console.log(values);
  //   setContactModalLoading(true);

  //   setTimeout(() => {
  //     setContactModalLoading(false);
  //     setContactModalVisible(false);
  //   }, 3000);

  //   // Get the sender's user info (major and school) to input into the email to the poster
  //   database
  //     .collection("users")
  //     .doc(currentUser.uid)
  //     .get()
  //     .then((doc) => {
  //       const senderMajor = doc.data()?.major;
  //       const senderSchool = doc?.data()?.school;

  //       console.log(review.id);
  //       console.log(review.position);
  //       console.log(review.company.name);
  //       console.log(currentUser.email);
  //       console.log(senderMajor);
  //       console.log(senderSchool);
  //       console.log(values.note);

  //       database.collection("messages_pending").doc().set({
  //         reviewID: review.id,
  //         position: review.position,
  //         company: review.company.name,
  //         senderMajor: senderMajor,
  //         senderSchool: senderSchool,
  //         senderID: currentUser.uid,
  //         senderEmail: currentUser.email,
  //         note: values.note
  //       });
  //     });
  // }

  // function handleCancel() {
  //   setContactModalVisible(false);
  // }

  console.log(review.company.name);

  return (
    <div className="review-card">
      <div>
        {isDesktop && (
          <>
            <div className="review-card__header">
              <div
                className="review-card__overall-rating"
                style={{ background: rateColor(review.overall_rating) }}
              >
                {review.overall_rating}
                <span className="divisor">/5</span>
              </div>

              <div className="review-card__stats">
                {/* <Stat title="Overall">
                <Rate character="●" style={{ color: rateColor(review.overall_rating) }} value={review.overall_rating} disabled allowHalf />
              </Stat> */}
                <Stat title="Work">
                  <Rate
                    character="●"
                    style={{ color: rateColor(review.work_rating) }}
                    value={review.work_rating}
                    disabled
                    allowHalf
                  />
                </Stat>
                <Stat title="Culture">
                  <Rate
                    character="●"
                    style={{ color: rateColor(review.culture_rating) }}
                    value={review.culture_rating}
                    disabled
                    allowHalf
                  />
                </Stat>
                <div className="dateAndRemoteTag">
                  <div className="review-card__date">
                    {dateFormatter(review.timestamp.seconds)}
                  </div>
                </div>
              </div>
              <div className="review-card__meta">
                <MessageButton review={review} />
                <button className="likeButton" onClick={favoriteReview}>
                  <span className="likeButtonIcon">{likeReview}</span>
                </button>
              </div>
            </div>
            <div className="review-card__content">
              <h2 className="review-card__position">
                <Link
                  className="review-card__position_link"
                  to={"/reviews/" + review.id}
                >
                  {review.position}
                </Link>
              </h2>
              <span className="review-card__team">
                Format: {is_remote_packager(review.is_remote)}{" "}
                {review.team && <> | Department: {review.team}</>}
              </span>

              <div className="review-card__description">
                {truncateDescription(
                  reviewOverviewCombine(
                    review.description,
                    review.project_description,
                    review.optional_remarks,
                    review.pros,
                    review.cons
                  )
                )}
              </div>
              <div className="review-card__footer">
                <Stat title="Pay">
                  <span className="review-card__pay">
                    {review.paydict ? (
                      <PayDisplay {...review.paydict} />
                    ) : (
                      review.pay
                    )}
                  </span>
                </Stat>
                <Stat title="Tools">
                  <span className="review-card__tool">
                    {review.tools.often.slice(0, 3).join(", ")}
                  </span>
                </Stat>
                <Stat title="Major">
                  <span className="review-card__major">{review.major}</span>
                </Stat>
                <Stat title="School">
                  <span className="review-card__school">{review.school}</span>
                </Stat>
              </div>
              <div className="review-card__readmore_container">
                <Link
                  className="review-card__readmore"
                  to={"/reviews/" + review.id}
                >
                  Read full review <RightCircleOutlined />
                </Link>
              </div>
            </div>
          </>
        )}
      </div>

      <div>
        {isMobile && (
          <>
            <div className="review-card__info_mobile">
              <div className="review-card__header_mobile">
                <div className="review-card__top_mobile">
                  <div
                    className="review-card__overall-rating_mobile"
                    style={{ background: rateColor(review.overall_rating) }}
                  >
                    <span className="review-card__overall-rating__text_mobile">
                      {review.overall_rating}
                    </span>
                    <span className="divisor">/5</span>
                  </div>
                  <div className="review-card__date_mobile">
                    {dateFormatter(review.timestamp.seconds)}
                  </div>
                  <div className="review-card__farRight_mobile">
                    <MessageButton review={review} />
                    <button
                      className="likeButton-mobile"
                      onClick={favoriteReview}
                    >
                      <span className="likeButtonIcon-mobile">
                        {likeReview}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="review-card__content">
              <div className="review-card__position_mobile">
                <h2 className="review-card__position_text_mobile">
                  <Link
                    className="review-card_position_text_mobile_link"
                    to={"/reviews/" + review.id}
                  >
                    {review.position} <RightCircleOutlined />
                  </Link>
                </h2>
              </div>
              <div className="review-card__stats">
                <Stat title="Work">
                  <Rate
                    character="●"
                    style={{ color: rateColor(review.work_rating) }}
                    value={review.work_rating}
                    disabled
                    allowHalf
                  />
                </Stat>
                <Stat title="Culture">
                  <Rate
                    character="●"
                    style={{ color: rateColor(review.culture_rating) }}
                    value={review.culture_rating}
                    disabled
                    allowHalf
                  />
                </Stat>
              </div>
              <div>
                <span className="review-card__team">
                  {review.is_remote && (
                    <>Format: {is_remote_packager(review.is_remote)}</>
                  )}
                  <br></br>
                  {review.team && <>Department: {review.team}</>}
                </span>
              </div>
              <div className="review-card__description">
                {truncateDescription(
                  reviewOverviewCombine(
                    review.description,
                    review.project_description,
                    review.optional_remarks,
                    review.pros,
                    review.cons
                  )
                )}
              </div>
              <div className="review-card__footer">
                <Stat title="Pay">
                  {review.paydict ? (
                    <PayDisplay {...review.paydict} />
                  ) : (
                    review.pay
                  )}
                </Stat>
                <Stat title="Tools">
                  <span className="review-card__tool">
                    {review.tools.often.slice(0, 3).join(", ")}
                  </span>
                </Stat>
                <Stat title="Major">{review.major}</Stat>
                <Stat title="School">{review.school}</Stat>
                {/* <Stat title="Year">
                  {review.year.grad_level ? review.year.grad_level.charAt(0).toUpperCase() + review.year.grad_level.substring(1) : ''}, {review.year.year} year
                </Stat> */}
                {/*}<div className="review-card__view">
                  <Link className="review-card__view-text" to={"/reviews/" + review.id}>Read more <ArrowRightOutlined/></Link>
                </div>*/}
              </div>
            </div>
            <div className="review-card__readmore_mobile">
              <Link to={"/reviews/" + review.id}>
                Read full review <RightCircleOutlined />
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReviewCard;
