import React, { useState } from "react";
import "../../test.less";
import "./style.css";

import {
  Home,
  CompanyHome,
  SubmitReview,
  About,
  Review,
  Login,
  Register,
  Profile,
  ForgotPassword,
  Company,
  FAQ,
  DropdownMenu,
  WebsiteMenu,
  CompanyRankings,
  InsightsEmployers,
  InsightsStudents,
  School,
  Survey,
} from "..";
import {
  Router,
  Link,
  RouteComponentProps,
  Location,
  navigate,
} from "@reach/router";

import { CookiesProvider } from "react-cookie";
import { Button, Result, BackTop } from "antd";

import { useMediaQuery } from "react-responsive";

import logo from "../../images/canaryLogo-V2-img.png";
import createXlogo from "../../images/createXlogo.jpg";

import { AuthProvider } from "../../contexts/AuthContext";

const ReviewNotFound: React.FunctionComponent<RouteComponentProps> = (
  props
) => (
  <div className="not-found">
    <Result
      status="error"
      title="Review not found"
      subTitle="Sorry, we can't find that review."
      extra={<Button onClick={() => navigate("/")}>Back Home</Button>}
    />
  </div>
);

const CompanyNotFound: React.FunctionComponent<RouteComponentProps> = (
  props
) => (
  <div className="not-found">
    <Result
      status="error"
      title="Company not found"
      subTitle="Sorry, we can't find this company."
      extra={<Button onClick={() => navigate("/")}>Back Home</Button>}
    />
  </div>
);

const ReviewSuccess: React.FunctionComponent<RouteComponentProps> = (props) => (
  <div className="submit-success">
    <Result
      status="success"
      title="Review Submitted!"
      subTitle="Thank you for helping your fellow student! Your review is now awaiting approval by our moderation team and will be live once it is approved."
      extra={
        <Button onClick={() => navigate("/submit")}>
          <span role="img" aria-label="pencil emoji">
            ✏️
          </span>{" "}
          Write another review
        </Button>
      }
    />
  </div>
);

const ReviewError: React.FunctionComponent<RouteComponentProps> = (props) => (
  <div className="submit-error">
    <Result
      status="error"
      title="Review not found"
      subTitle="So sorry! There was an error submitting your review. If you think this happened by mistake, you can send us an email to feedback@canarystudent.com."
      extra={<Button onClick={() => navigate("/submit")}>Try Again</Button>}
    />
  </div>
);

const App = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const [page, setPage] = useState<number>(1);
  const [homeMajor, setHomeMajor] = useState<string>("");
  const [homeSchool, setHomeSchool] = useState<string>("");
  const [homeEmail, setHomeEmail] = useState<string>("");

  // This gives us our current user, important for showing the correct Nav bar pages
  // const { currentUser } = useAuth()

  // Returns true if there is a current user and false otherwise
  // function activeUser() {
  //   return currentUser !== null
  // }
  {
    /*
  if (window.location.pathname.includes("/survey")) {
    return (
      <AuthProvider>
        <CookiesProvider>
          <Router primary={false}>
            <ReviewSuccess path="/Emory/survey/submit-success" />
            <ReviewError path="/Emory/survey/submit-error" />
            <Survey path="/Emory/survey" />
          </Router>
        </CookiesProvider>
      </AuthProvider>
    );
    // return ( <SubmitReview path="/submit"/>)
  } else { */
  }

  if (window.location.pathname.includes("/survey")) {
    return (
      <AuthProvider>
        <CookiesProvider>
          <Router primary={false}>
            <ReviewSuccess path="/Emory/survey/submit-success" />
            <ReviewError path="/Emory/survey/submit-error" />
            <Survey path="/surveys/Emory%20University" />
          </Router>
        </CookiesProvider>
      </AuthProvider>
    );
    // return ( <SubmitReview path="/submit"/>)
  } else {
    return (
      <AuthProvider>
        <CookiesProvider>
          <div className="App">
            {/* <Headroom> */}
            <div className="headroom">
              <Location>
                {(context) => (
                  <nav>
                    <Link to="/" style={{ margin: "auto 30px" }}>
                      {" "}
                      <img className="logo" alt="Canary Logo" src={logo} />
                    </Link>
                    {isMobile ? (
                      <DropdownMenu />
                    ) : (
                      <WebsiteMenu context={context} />
                    )}
                  </nav>
                )}
              </Location>
            </div>

            <div className="background"></div>
            <BackTop />

            <div className="content">
              <Router primary={false}>
                <Home
                  path="/"
                  homeMajor={homeMajor}
                  setHomeMajor={setHomeMajor}
                  homeSchool={homeSchool}
                  setHomeSchool={setHomeSchool}
                  homeEmail={homeEmail}
                  setHomeEmail={setHomeEmail}
                  page={page}
                  setPage={setPage}
                ></Home>
                <Review path="/reviews/:reviewID" />
                <SubmitReview path="/submit" />
                <About path="/about" />
                <Login path="/login" />
                <InsightsStudents path="insights/students" />
                <InsightsEmployers path="insights/employers" />
                <Register
                  path="/register"
                  homeMajor={homeMajor}
                  homeSchool={homeSchool}
                  homeEmail={homeEmail}
                />
                <CompanyRankings path="/topcompanies" />
                <Profile path="/profile" />
                <ReviewNotFound path="/reviews/not-found" />
                <ForgotPassword path="/forgotpassword" />
                <FAQ path="/FAQ" />
                <Company
                  path="/companies/:companyName"
                  page={page}
                  setPage={setPage}
                />
                <School
                  path="/schools/:schoolName"
                  page={page}
                  setPage={setPage}
                />
                <CompanyNotFound path="/companies/not-found" />
                {/*<CompanyHome path="/" page={page} setPage={setPage}></CompanyHome>*/}
                <ReviewSuccess path="/submit-success" />
                <ReviewError path="/submit-error" />
              </Router>
            </div>
            <footer>
              <div className="canary-details">
                <div>
                  <p>
                    <span>Canary is a Georgia Tech Create-X Startup. V2.6</span>
                    <br></br>
                    <span>
                      Copyright © 2021 Canary Enterprises LLC. All rights
                      reserved.
                    </span>
                  </p>
                </div>
                <a href="/FAQ">FAQ page</a> |{" "}
                <a href="https://canarystudent.com/about" target="_blank">
                  About us
                </a>{" "}
                |{" "}
                <a href="mailto:hello@canarystudent.com">
                  hello@canarystudent.com
                </a>
              </div>
            </footer>
          </div>
        </CookiesProvider>
      </AuthProvider>
    );
  }
};

export default App;
