import React from "react";
import "./style.css";
import { RouteComponentProps, navigate, useLocation } from "@reach/router";
import { Button, Row, Col, Spin, Collapse } from "antd/es";
import { LinkedinOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import pencil from "../../images/icons/pencil.png";
import student from "../../images/icons/student.png";
import lightbulb from "../../images/icons/lightbulb.png";

const InsightsStudents = (props: RouteComponentProps) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  const isMobile = !isDesktop;

  return (
    <div className="">
      <h1 className="align-center">
        Canary Insights <img className="icon-emoji-big" src={lightbulb}></img>
      </h1>
      <h2 className="align-center">
        For Students <img className="icon-emoji-big" src={student}></img>
      </h2>
      <div className="content-card_insights-title-container">
        <div className="content-card_insights-title">
          <p>
            Canary is built by students, so our goal is to offer other students
            transparent information and insights to go get the best internships
            (and know what they should be getting paid for them). Our team is
            constantly analyzing the anonymous data from our platform to equip
            students with the knowledge they need to start the best careers they
            can.
            <br></br>
            <br></br>
            These graph images are linked to a live database and refreshed with
            new data on a regular basis, so the data you're seeing is always
            current.
          </p>
          <br></br>
          Write a review to help us add more insights for other students!
          <br></br>
          <Button
            className=""
            type="primary"
            size="middle"
            onClick={() => navigate("/submit")}
          >
            <img className="icon-emoji" src={pencil}></img>Write a Review
          </Button>
        </div>
      </div>
      <div className="insight-grid">
        <div className="insight-card-container">
          <div className="content-card_insights">
            <div className="section-header-insights">
              <h2>Average hourly internship pay by major</h2>
              <p>Computer Science majors lead the pack in hourly pay</p>
            </div>
            <a
              target="_blanks"
              href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1989434499&format=image"
            >
              <img
                className="image_graph"
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1989434499&format=image"
              ></img>
            </a>
            <span className="clickToEnlarge">(Click image to enlarge)</span>
          </div>
        </div>

        <div className="insight-card-container">
          <div className="content-card_insights">
            <div className="section-header-insights">
              <h2>Companies ranked by pay and rating</h2>
              <p>Tech companies lead the pack</p>
            </div>

            <a
              target="_blanks"
              href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1957692321&amp;format=image"
            >
              <img
                className="image_graph"
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1957692321&amp;format=image"
              ></img>
            </a>
            <span className="clickToEnlarge">(Click image to enlarge)</span>
          </div>
        </div>

        <div className="insight-card-container">
          <div className="content-card_insights">
            <div className="section-header-insights">
              <h2>Average internship hourly pay progression</h2>
              <p>
                Hourly pay starts at $20 and plateaus near $25 for most students
              </p>
            </div>
            <a
              target="_blanks"
              href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=429837574&amp;format=image"
            >
              <img
                className="image_graph"
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=429837574&amp;format=image"
              ></img>
            </a>
            <span className="clickToEnlarge">(Click image to enlarge)</span>
          </div>
        </div>

        <div className="insight-card-container">
          <div className="content-card_insights">
            <div className="section-header-insights">
              <h2>Average internship hourly pay progression by major</h2>
              <p>
                Hourly pay starts at $20 and plateaus near $25 for most majors
              </p>
            </div>
            <Row gutter={[16, 8]}>
              <Col className="align-center" span={12}>
                <a
                  target="_blanks"
                  href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=225635541&format=image"
                >
                  <img
                    className="image_graph"
                    src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=225635541&format=image"
                  ></img>
                </a>
              </Col>
              <Col className="align-center" span={12}>
                <a
                  target="_blanks"
                  href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1777844492&format=image"
                >
                  <img
                    className="image_graph"
                    src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1777844492&format=image"
                  ></img>
                </a>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col className="align-center" span={12}>
                <a
                  target="_blanks"
                  href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=2091251658&format=image"
                >
                  <img
                    className="image_graph"
                    src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=2091251658&format=image"
                  ></img>
                </a>
              </Col>
              <Col className="align-center" span={12}>
                <a
                  target="_blanks"
                  href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1753391935&format=image"
                >
                  <img
                    className="image_graph"
                    src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1753391935&format=image"
                  ></img>
                </a>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col className="align-center" span={12}>
                <a
                  target="_blanks"
                  href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1220639276&format=image"
                >
                  <img
                    className="image_graph"
                    src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1220639276&format=image"
                  ></img>
                </a>
              </Col>
              <Col className="align-center" span={12}>
                <a
                  target="_blanks"
                  href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=185611854&format=image"
                >
                  <img
                    className="image_graph"
                    src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=185611854&format=image"
                  ></img>
                </a>
              </Col>
              <span className="clickToEnlarge">(Click images to enlarge)</span>
            </Row>
          </div>
        </div>

        {/*<div className="content-card_insights">
          <div className="section-header-insights">
            <h2>Most common software tools used</h2>
            <p>Apparently most of the world runs on Microsoft Excel...</p>
            <p>(Frequency: 5 = Very common, 1 = Not very common)</p>
          </div>

          <Collapse bordered={false} style={{ backgroundColor: "#fff" }}>
            <Collapse.Panel header={<h4>All Majors 📊</h4>} key="1">
              {" "}
              <a
                target="_blanks"
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=673922894&format=image"
              >
                <img
                  className="image_graph"
                  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=673922894&format=image"
                ></img>
              </a>
            </Collapse.Panel>
            <Collapse.Panel header={<h4>Engineering 📊</h4>} key="2">
              {" "}
              <a
                target="_blanks"
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=2099081009&format=image"
              >
                <img
                  className="image_graph"
                  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=2099081009&format=image"
                ></img>
              </a>
            </Collapse.Panel>
            <Collapse.Panel header={<h4>Computer Science 📊</h4>} key="3">
              {" "}
              <a
                target="_blanks"
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1293677588&format=image"
              >
                <img
                  className="image_graph"
                  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSrcNgztCJt2Gt-QyzkWCmTMWT6awhM73Sc7AYB2XXu-5Qfr6E-PZheBbaxY1JukEKSAX6vOzt5T7st/pubchart?oid=1293677588&format=image"
                ></img>
              </a>
            </Collapse.Panel>
          </Collapse>
        </div>*/}
        <br></br>
      </div>
      <div className="insights-CTA-container">
        <div className="insights-CTA">
          Write a review to help us add more insights for other students!{" "}
          <Button
            className=""
            type="primary"
            size="middle"
            onClick={() => navigate("/submit")}
          >
            <img className="icon-emoji" src={pencil}></img>Write a Review
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InsightsStudents;
