import React, { useState, useEffect } from "react";
import "./style.css";
import { navigate, RouteComponentProps, Link } from "@reach/router";
import { Button, Alert, Result, Spin } from "antd";
import { useAuth } from "../../contexts/AuthContext";
import { database } from "../../database";
import {
  Review as ReviewInterface,
  MyInfo as myInfoInterface,
} from "../../reviews";
// import ReviewCard from "./ReviewCard";
import * as firebase from "firebase/app";
import "firebase/firestore";

import {
  FormOutlined,
  CloseCircleOutlined,
  HeartFilled,
} from "@ant-design/icons";

import email from "../../images/icons/email.png";
import school from "../../images/icons/school.png";
import pencil from "../../images/icons/pencil.png";
import heart from "../../images/icons/heart.png";
import journal from "../../images/icons/journal.png";

const Profile = (props: RouteComponentProps) => {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const [myInfo, setMyInfo] = useState<myInfoInterface>();
  const [favReviews, setFavReviews] = useState<ReviewInterface[] | undefined>(
    undefined
  );
  const [userReviews, setUserReviews] = useState<ReviewInterface[] | undefined>(
    undefined
  );
  const [onLoadFav, setOnLoadFav] = useState(0);
  const [onLoadUser, setOnLoadUser] = useState(0);
  const [reviewDeleteRequestID, setReviewDeleteRequestID] = useState("");
  const [reviewDeleteRequestID_array, setReviewDeleteRequestID_array] =
    useState<[String]>([""]);
  const [showAreYouSureID, setShowAreYouSureID] = useState("");

  //window.scrollTo(0, 0);

  function handleDeleteRequest_1(reviewID) {
    setShowAreYouSureID(reviewID);
  }

  function handleDeleteRequest_2(reviewID) {
    setReviewDeleteRequestID(reviewID);

    database
      .collection("users")
      .doc(currentUser.uid)
      .update({
        delete_request: firebase.firestore.FieldValue.arrayUnion(reviewID),
      });

    database
      .collection("delete_requests")
      .doc()
      .set({
        email: currentUser.email,
        uid: currentUser.uid,
        username: myInfo?.username,
        review_id: reviewID,
        is_deleted: false,
        timestamp: { seconds: new Date().getTime() / 1000 },
      });
  }

  useEffect(() => {
    // Check for delete requests

    async function getFavReviews(userID) {}
    //console.log("hey");
    database
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        setMyInfo(doc.data() as myInfoInterface);
        //console.log(doc.data());

        let fav_reviews_IDs = doc.data()?.favorite_reviews || [];
        let user_reviews_IDs = doc.data()?.user_reviews || [];
        let delete_requests_IDs = doc.data()?.delete_request || [];

        setReviewDeleteRequestID_array(delete_requests_IDs);

        let favReviews_data = [];
        let userReviews_data = [];
        fav_reviews_IDs.forEach(getFavReviewData);
        user_reviews_IDs.forEach(getUserReviewData);

        if (fav_reviews_IDs.length === 0) {
          setOnLoadFav(1);
        }
        if (user_reviews_IDs.length === 0) {
          setOnLoadUser(1);
        }

        function getFavReviewData(item, index) {
          database
            .collection("review")
            .doc(item)
            .get()
            .then((doc) => {
              if (doc.exists) {
                //@ts-ignore
                favReviews_data?.push(doc.data());
              }
              //console.log(fav_reviews_IDs.length);
              //console.log(favReviews_data);
              if (index === fav_reviews_IDs.length - 1) {
                //console.log("set");
                setFavReviews(favReviews_data as ReviewInterface[]);
                setOnLoadFav(1);
              }
              //reviews_data.sort(sortFunctions["date"]);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        function getUserReviewData(item, index) {
          database
            .collection("review")
            .doc(item)
            .get()
            .then((doc) => {
              if (doc.exists) {
                //@ts-ignore
                userReviews_data?.push(doc.data());
              }

              if (index === user_reviews_IDs.length - 1) {
                setUserReviews(userReviews_data as ReviewInterface[]);
                setOnLoadUser(1);
              }
              //reviews_data.sort(sortFunctions["date"]);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getFavReviews(currentUser.uid);
  }, [currentUser.uid]);

  function onDeleteClicked(reviewID) {
    database
      .collection("users")
      .doc(currentUser.uid)
      .update({
        favorite_reviews: firebase.firestore.FieldValue.arrayRemove(reviewID),
      })
      .then(() => {
        window.location.reload(false);
      });
  }

  // Function called when logout button is clicked
  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate("/");
      window.location.reload(false);
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <div className="Profile">
      <div className="Title">
        {myInfo?.username && (
          <h1 className="align-center">Hey {myInfo?.username}!</h1>
        )}
      </div>

      <div className="content-card-profile">
        {error && (
          <Alert message="Error" description={error} type="error" showIcon />
        )}

        {currentUser && (
          <h3>
            <img className="icon-emoji" src={email}></img>
            <strong>Email</strong>
            <br></br>
            <span className="myInfoTag">{currentUser.email}</span>
          </h3>
        )}

        {myInfo?.major && (
          <h3>
            <img className="icon-emoji" src={journal}></img>
            <strong>Major</strong>
            <br></br>
            <span className="myInfoTag">{myInfo?.major}</span>
          </h3>
        )}

        {myInfo?.school && (
          <h3>
            <img className="icon-emoji" src={school}></img>
            <strong>School</strong>
            <br></br>
            <span className="myInfoTag">{myInfo?.school}</span>
          </h3>
        )}

        <h3 className="favReviewsHeader">
          <img className="icon-emoji" src={pencil}></img>
          <strong>My reviews:</strong>
        </h3>

        <div className="userReviews">
          {onLoadUser === 1 ? (
            //@ts-ignore
            userReviews ? (
              //@ts-ignore
              userReviews
                //.slice((page - 1) * reviewsPerPage, page * reviewsPerPage)
                .map((review, i) => (
                  <div className="favReviewContainer">
                    {(!review.is_visible && (
                      <p className="favReview" key={review.id}>
                        {review.position}
                        <br></br>
                        at {review.company.name}
                        <br></br>
                        <div className="underReviewOrNotCallout">
                          <span className="underReview">
                            Your review is under review (see what we did there
                            <span role="img" aria-label="winky face">
                              😉
                            </span>
                            )
                          </span>
                        </div>
                      </p>
                    )) || (
                      <p className="favReview" key={review.id}>
                        <Link
                          className="review-card__position"
                          to={"/reviews/" + review.id}
                        >
                          {review.position}
                        </Link>
                        <br></br>
                        at{" "}
                        <Link
                          className="review-card__position"
                          to={"/companies/" + review.company.name}
                        >
                          {review.company.name}
                        </Link>
                        <br></br>
                        {!(
                          reviewDeleteRequestID == review.id ||
                          reviewDeleteRequestID_array.includes(review.id)
                        ) && (
                          <div className="underReviewOrNotCallout">
                            <span className="reviewIsLive">
                              Your review is live!
                            </span>
                          </div>
                        )}
                        <br></br>
                        {((reviewDeleteRequestID == review.id ||
                          reviewDeleteRequestID_array.includes(review.id)) && (
                          <>
                            Deletion requested. You will be notified when it is
                            removed 👍
                          </>
                        )) ||
                          (showAreYouSureID == review.id && (
                            <>
                              <>
                                <CloseCircleOutlined /> Request deletion
                              </>
                              <br></br>
                              <>Are you sure? </>
                              <a
                                onClick={() => handleDeleteRequest_2(review.id)}
                              >
                                Yes
                              </a>
                            </>
                          )) || (
                            <a onClick={() => handleDeleteRequest_1(review.id)}>
                              <CloseCircleOutlined /> Request deletion
                            </a>
                          )}
                      </p>
                    )}
                  </div>
                  //<ReviewCard key={review.id} review={review} />
                ))
            ) : (
              <Result
                icon={<FormOutlined />}
                title="Looks like you haven't left any reviews yet!"
                style={{ gridColumn: "1 / 3" }}
                extra={
                  <Button href="/submit" type="primary">
                    Leave a review!
                  </Button>
                }
              />
            )
          ) : (
            <>
              <Spin size="large" />
            </>
          )}
        </div>

        <h3 className="favReviewsHeader">
          <img className="icon-emoji" src={heart}></img>
          <strong>Favorite reviews:</strong>
        </h3>

        <div className="favReviews">
          {onLoadFav === 1 ? (
            //@ts-ignore
            favReviews ? (
              //@ts-ignore
              favReviews.map((review, i) => (
                <div className="favReviewContainer">
                  <p className="favReview" key={review.id}>
                    <Link
                      className="review-card__position"
                      to={"/reviews/" + review.id}
                    >
                      {review.position}
                    </Link>
                    <br></br>
                    at{" "}
                    <Link
                      className="review-card__position"
                      to={"/companies/" + review.company.name}
                    >
                      {review.company.name}
                    </Link>
                  </p>
                  <button
                    onClick={() => onDeleteClicked(review.id)}
                    className="deleteButton"
                  >
                    <CloseCircleOutlined />
                  </button>
                </div>
                //<ReviewCard key={review.id} review={review} />
              ))
            ) : (
              <Result
                icon={<HeartFilled />}
                title="Looks like you haven't favorited any reviews yet!"
                style={{ gridColumn: "1 / 3" }}
                extra={
                  <Button href="/" type="primary">
                    Go explore reviews!
                  </Button>
                }
              />
            )
          ) : (
            <>
              <Spin size="large" />
            </>
          )}
        </div>

        <div className="Logout">
          <Button type="primary" onClick={handleLogout}>
            Log Out
          </Button>
        </div>
        <p className="disclaimer-profile">
          Canary is a new platform and we're working hard to continuously come
          out with new features. For the time being, if you want to edit your
          info or a review you've left, shoot us an email at
          <a href="mailto:feedback@canarystudent.com">
            {" "}
            feedback@canarystudent.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Profile;
