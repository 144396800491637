import React, { useState } from "react";
import { Link } from "@reach/router";
import { Menu } from "antd";
import { auth } from "../../database";
import SubMenu from "antd/lib/menu/SubMenu";

import reviewsIcon from "../../images/icons/reviews.png";
import pencil from "../../images/icons/pencil.png";
import people from "../../images/icons/people.png";
import register from "../../images/icons/register.png";
import login from "../../images/icons/login.png";
import bird_front from "../../images/icons/bird_front.png";
import medal from "../../images/icons/medal.png";
import lightbulb from "../../images/icons/lightbulb.png";
import student from "../../images/icons/student.png";
import office from "../../images/icons/office.png";

let loggedIn = false;

auth.onAuthStateChanged(function (user) {
  if (user) {
    loggedIn = true;
  }
});

const WebsiteMenu = ({ context }: { context: any }) => {
  return (
    <Menu
      style={{ height: "100%", marginTop: "auto" }}
      mode="horizontal"
      selectedKeys={[context.location.pathname.replace("/", "") || "companies"]}
    >
      {/*
  <Menu.Item key="companies">
    <Link to="/">
      <span role="img" aria-label="building emoji">
        🏢
      </span>{" "}
      Companies
    </Link>
  </Menu.Item>*/}

      <Menu.Item key="reviews">
        <Link to="/">
          <img className="icon-emoji" src={reviewsIcon}></img>Reviews
        </Link>
      </Menu.Item>

      <Menu.Item key="submit">
        <Link to="/submit">
          <img className="icon-emoji" src={pencil}></img>Write a review
        </Link>
      </Menu.Item>

      <SubMenu
        icon={
          <>
            <img className="icon-emoji" src={lightbulb}></img>
          </>
        }
        title="Insights"
        className="AccountMenu"
      >
        <Menu.Item key="insights-students">
          <Link to="/insights/students">
            <img className="icon-emoji" src={student}></img>For students
          </Link>
        </Menu.Item>
        <Menu.Item key="insights-employers">
          <Link to="/insights/employers">
            <img className="icon-emoji" src={office}></img>For employers
          </Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item>
        <Link to="/topcompanies">
          <img className="icon-emoji" src={medal}></img>Top companies
        </Link>
      </Menu.Item>

      {loggedIn && (
        <Menu.Item key="profile">
          <Link to="/profile">
            <img className="icon-emoji" src={people}></img>My profile
          </Link>
        </Menu.Item>
      )}

      {!loggedIn && (
        <SubMenu
          icon={<img className="icon-emoji" src={people}></img>}
          title=" Account"
          className="AccountMenu"
        >
          <Menu.Item key="register">
            <Link to="/register">
              <img className="icon-emoji" src={register}></img>Sign up
            </Link>
          </Menu.Item>
          <Menu.Item key="login">
            <Link to="/login">
              <img className="icon-emoji" src={login}></img>Log in
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
      <Menu.Item key="about">
        <a href="https://www.canarystudent.com/about" target="_blank">
          <img className="icon-emoji" src={bird_front}></img>About
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default WebsiteMenu;
