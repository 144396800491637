import React, { useState, useEffect } from "react";
import { RouteComponentProps, navigate, useLocation } from "@reach/router";
import "./style.css";
import algoliasearch from "algoliasearch";
import { useAuth } from "../../contexts/AuthContext";
import blurry_ranking from "../../images/CompanyRankings_blurry.jpg";

import { Button, Input, Pagination, Spin, Result, Form } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";

import {
  ArrowDownOutlined,
  RightOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { CompanySearchCard, CompanyCard } from "..";

import { Review, Company, Ratings } from "../../reviews";
import { database } from "../../database";

import classNames from "classnames";
import { useMediaQuery, useRef } from "react-responsive";
import TopCompaniesCompanyCard from "../TopCompaniesCompanyCard";
import { Select } from "antd";

import medal from "../../images/icons/medal.png";

const { Option } = Select;

let is_defaultResults = true;

interface CompanyHomeProps extends RouteComponentProps {
  children?: any;
}
const CompanyRankings = (props: CompanyHomeProps) => {
  const location = useLocation();
  // const [reviews, setReviews] = useState<Review[] | undefined>(undefined);
  const [hitsObject, setHitsObject] = useState<any>();
  const [searchText, setSearchText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { currentUser, logout } = useAuth();

  const sortFunctions = {
    date: (a, b) => b.timestamp.seconds - a.timestamp.seconds,
    "rating-overall": (a, b) => b.ratings.overall_avg - a.ratings.overall_avg,
    "rating-work": (a, b) => b.ratings.work_avg - a.ratings.work_avg,
    "rating-culture": (a, b) => b.ratings.culture_avg - a.ratings.culture_avg,
  };

  const algoliaClient = algoliasearch(
    "ZYXV1W90TC",
    "d389b8c61ad7d47f3964288fce2b6013"
  );
  const collectionIndexName = "companies_prod"; // "reviews_dev" for testing and playground
  const collectionIndex = algoliaClient.initIndex(collectionIndexName);

  var companies_data: any[] = [];

  var urlParams = new URLSearchParams(props.location?.search);

  // const isMobile = useMediaQuery({ query: "(min-width: 660px)" });
  const [companies, setCompanies] = useState<Company[] | undefined>(undefined);

  // const [reviewsPerPage, setReviewsPerPage] = useState<number>(10);
  const [companiesPerPage, setCompaniesPerPage] = useState<number>(25);
  const [rating_toShow, setRating_toShow] = useState<string>("rating-overall");

  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  // const isMobile = !isDesktop;

  useEffect(() => {
    database
      .collection("companies_public")
      //.orderBy("timestamp", "desc")
      //.where("timestamp", ">=", {seconds: (new Date().getTime() - 5500000000) / 1000, })
      //.limit(100)
      .where("num_reviews", ">=", 5)
      .get()
      .then((data) => {
        let companies_data = data.docs.map((d) => d.data());
        //loading = false;

        companies_data.sort(sortFunctions[rating_toShow]);
        companies_data = companies_data.slice(0, 25);

        setCompanies(companies_data as Company[]);
      });
  }, [rating_toShow]);

  function handleChange(value) {
    console.log(`selected ${value}`);
    setRating_toShow(value);

    database
      .collection("companies_public")
      //.orderBy("timestamp", "desc")
      //.where("timestamp", ">=", {seconds: (new Date().getTime() - 5500000000) / 1000, })
      //.limit(100)
      .where("num_reviews", ">=", 5)
      .get()
      .then((data) => {
        let companies_data = data.docs.map((d) => d.data());
        //loading = false;

        companies_data.sort(sortFunctions[rating_toShow]);
        companies_data = companies_data.slice(0, 25);

        setCompanies(companies_data as Company[]);
      });
  }

  return (
    <div className="TopCompanies">
      {(true && (
        <>
          <div className="TopCompaniesTitle_container">
            <h1>
              Top 25 Companies{" "}
              <img className="icon-emoji-big" src={medal}></img>
            </h1>
            <h3>The best places to intern, rated by students</h3>
            <p>
              Only companies with at least 5 reviews are eligible for the
              ranking. Don't see your company?{" "}
              <a href="/submit">Leave a review</a> to help them join the
              ranking!
            </p>
          </div>

          <br></br>
          <div className="rank-selector-container">
            Ranked by{" "}
            <Select
              defaultValue="Overall rating"
              style={{ width: 120 }}
              onChange={handleChange}
              className="rank-selector"
            >
              <Option value="rating-overall">Overall rating</Option>
              <Option value="rating-work">Work rating</Option>
              <Option value="rating-culture">Culture rating</Option>
            </Select>
          </div>
          <div className="top-companies-results">
            {companies ? (
              companies.length > 0 ? (
                companies.map((company, i) => (
                  <TopCompaniesCompanyCard
                    ranking={i}
                    key={company.name}
                    name={company.name}
                    image={company.image}
                    ratings={company.ratings}
                    rating_toShow={rating_toShow}
                    description={company.industry}
                    reviews={[]}
                    company_id={company.id}
                    num_reviews={company.num_reviews}
                  />
                ))
              ) : (
                <Result
                  status="warning"
                  title="Search returned 0 results"
                  style={{ gridColumn: "1 / 3" }}
                />
              )
            ) : (
              <div className="review-spinner footer-spacer">
                <Spin size="large" />
              </div>
            )}
          </div>
        </>
      )) || (
        <>
          <div className="TopCompaniesTitle_container">
            <h1>Top 20 Companies 🥇</h1>
            <h3>The best places to intern, rated by students</h3>
            <div className="rank-selector-container">
              Ranked by{" "}
              <Select
                defaultValue="Overall rating"
                style={{ width: 120 }}
                className="rank-selector"
              >
                <Option value="1">Overall rating</Option>
                <Option value="2">Work rating</Option>
                <Option value="3">Culture rating</Option>
              </Select>
            </div>
            {!currentUser && (
              <>
                <div className="section-card mustSignUp">
                  <h1>Sign up to see the top companies</h1>

                  <Button
                    className="submit-alertButton"
                    href="/register"
                    type="primary"
                    size="large"
                  >
                    <span role="img" aria-label="hand writing">
                      ✍️
                    </span>{" "}
                    Sign up
                  </Button>
                  <Button
                    className="submit-alertButton"
                    href="/login"
                    type="primary"
                    size="large"
                  >
                    <span role="img" aria-label="key">
                      🔑
                    </span>{" "}
                    Log In
                  </Button>
                </div>
                <div>
                  <img
                    className="rankings-blurry-img"
                    src={blurry_ranking}
                  ></img>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyRankings;
