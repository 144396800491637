import React, { useState } from "react";
import "./style.css";
import { Form, Input, Button, Alert } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { RouteComponentProps, Link, navigate } from "@reach/router";
import { useAuth } from "../../contexts/AuthContext";

import confetti from "../../images/icons/confetti.png";

const Login = (props: RouteComponentProps) => {
  // Setting the state of all the input fields
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { login } = useAuth();

  // Function called upon clicking the submit button
  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailInput, passwordInput);
      navigate("/profile");
      //window.location.reload(false);
    } catch {
      setError("Failed to log in");
    }
    setLoading(false);
  }

  return (
    <div className="Login">
      <div className="Title">
        <h1 className="align-center">
          Welcome back! <img className="icon-emoji-big" src={confetti}></img>
        </h1>
        <h3 className="align-center">It's good to see ya</h3>
      </div>

      <div className="content-card-login">
        {error && (
          <Alert message="Error" description={error} type="error" showIcon />
        )}

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
        >
          <Form.Item
            className="emailField"
            name="email"
            rules={[
              { required: true, message: "Please input your Email Address!" },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Link to="/forgotpassword"> Forgot Password? </Link>
          </Form.Item>

          <Form.Item>
            <Button
              disabled={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={handleSubmit}
            >
              Log in
            </Button>
            <div className="dontHaveAccount">
              Don't have an account?{" "}
              <Link to="/register"> Register here! </Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
