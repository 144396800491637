import React, { useState } from "react";
import "./style.css";


import {
	Button,
	Modal,
} from "antd/es";



class EmailReviewer extends React.Component {
	state = { visible: false };
  
	showModal = () => {
	  this.setState({
		visible: true,
	  });
	};
  
	handleOk = e => {
	  console.log(e);
	  this.setState({
		visible: false,
	  });
	};
  
	handleCancel = e => {
	  console.log(e);
	  this.setState({
		visible: false,
	  });
	};
  
	render() {
	  return (
		<>
		  <Button type="primary" onClick={this.showModal}>
			Open Modal
		  </Button>
		  <Modal
			title="Basic Modal"
			visible={this.state.visible}
			onOk={this.handleOk}
			onCancel={this.handleCancel}
		  >
			<p>{this.props}</p>
			<p>Some contents...</p>
			<p>Some contents...</p>
		  </Modal>
		</>
	  );
	}
  }
  

export default EmailReviewer;
