import React, { useState, useEffect } from "react";
import "./style.css";
import { locations, companies } from "../SubmitAutocomplete.js";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { useCookies } from "react-cookie";
import { RouteComponentProps, navigate } from "@reach/router";
import {
  Input,
  Form,
  Button,
  Select,
  Radio,
  Tooltip,
  Rate,
  Checkbox,
  InputNumber,
  Tabs,
  AutoComplete,
  notification,
} from "antd/es";
import { QuestionCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { YearValue, MyInfo as myInfoInterface } from "../../reviews";
import { database } from "../../database";
// import { createEmitAndSemanticDiagnosticsBuilderProgram } from "typescript";
import { useAuth } from "../../contexts/AuthContext";

import {
  DetailsTab,
  MyExperienceTab,
  InterviewTab,
  SubmitTab,
  EmoryQuestionsTab,
} from "..";

let isDisabled = false;

const close = (key) => {
  console.log(
    "Notification was closed. Either the close button was clicked or duration time elapsed."
  );
  notification.close(key);
  navigate("/register");
};

const mustSignUpNotification = () => {
  const key = `open${Date.now()}`;
  const tooltp = (
    <span>
      We won't spam you, sell your email, or reveal your indentity to anyone.{" "}
      <span role="img" aria-label="tophat">
        🕵
      </span>{" "}
      <Tooltip
        className="input-horizontal-spacer"
        title="Canary ensures all reviews are from real students. All reviews are anonymous and personal info like email is only used to verify your account, nothing more. 🔐"
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </span>
  );
  const btn = (
    <Button type="primary" size="large" onClick={() => close(key)}>
      Sign up
    </Button>
  );
  notification.open({
    placement: "topRight",
    duration: 5,
    top: 100,
    message: "Sign up to help your fellow student! 😊",
    description: tooltp,
    btn,
    key,
  });
};

const { Option } = Select;

const dev_is_required = true;

const { TabPane } = Tabs;

// const marks_impact = {
//   0: "No impact (busy-work)",
//   1: "Not very impactful",
//   2: "Somewhat impactful",
//   3: "Impactful",
//   4: "Very impactful",
// };

// const marks_prerequisites = {
//   0: "None (they'll teach you what you need to know)",
//   1: "Beginner (need basic knowledge/ experience)",
//   2: "Intermediate (need to be pretty familiar)",
//   3: "Expert (need to have advanced knowledge/ experience)",
// };

// const marks_timeworking = {
//   0: "0-20% (might as well have done nothing)",
//   1: "20-40% worked some, but ton of down time)",
//   2: "40-60% (some days stayed busy, but still good bit of downtime)",
//   3: "60-80% (stayed pretty busy)",
//   4: "80-100% (more or less busy the whole time)",
// };

const years = [
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
];
const semesters = ["Spring", "Summer", "Fall"];

const terms1 = years
  .reduce((acc, year) => {
    let perm = semesters.reduce(
      (a, semester) => [...a, semester + " " + year],
      [] as string[]
    );
    return acc.concat(perm);
  }, [] as string[])
  .map((option, i) => (
    <Option value={option} key={i}>
      {option}
    </Option>
  ));

const terms = terms1.reverse();

function callback(key) {
  console.log(key);
}

const DynamicRule2 = ({ l }) => {
  const [form] = Form.useForm();
  const [cookies, setCookie, removeCookie] = useCookies([
    "name",
    "email",
    "school",
    "majors",
    "year",
  ]);
  const [hasHousingStipend, setHasHousingStipend] = useState(false);
  const [submitPage, setSubmitPage] = useState("1");

  const { currentUser, logout } = useAuth();

  const [myInfo, setMyInfo] = useState<myInfoInterface>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [unfinished, setUnfinished] = useState(false);

  // useEffect(() => {
  //   async function getUserInfo(userID) {
  //     console.log("hey");
  //     database
  //       .collection("users")
  //       .doc(currentUser.uid)
  //       .get()
  //       .then((doc) => {
  //         setMyInfo(doc.data() as myInfoInterface);
  //         console.log(doc.data());
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  //   // if (currentUser) {
  //   //   isDisabled = false;
  //   //   getUserInfo(currentUser.uid);
  //   // }
  // }, []);

  const onSuccess = (values) => {
    if (values.remember_personal) {
      setCookie("name", values.name || "");
      setCookie("school", values.school || "");
      setCookie("email", values.email || "");
      setCookie("major", values.major || "");
      setCookie("other_studies", values.other_studies || "");
      setCookie("year", values.year || {});
      setCookie("remember_personal", values.remember_personal);
    } else {
      removeCookie("name");
      removeCookie("school");
      removeCookie("email");
      removeCookie("major");
      removeCookie("other_studies");
      removeCookie("year");
      removeCookie("remember_personal");
    }
    //   const onSuccess = (values) => {
    //     handler() {

    // }
    const review = {
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      timestamp: { seconds: new Date().getTime() / 1000 },
      company: {
        name: values.company_name || "",
        description: "",
        image: "",
      },
      position: values.position || "",
      team: values.team || "",
      internship_type: values.internship_type || "internship",
      structured_program: values.structured_program || "",
      location: values.location || "",
      terms: values.terms || [],
      pay: "",
      paydict: {
        type: values.pay.type || "",
        amount: values.pay.amount,
        currency: "USD",
      },
      overall_rating: values.overall_rating,
      culture_rating: values.culture_rating,
      work_rating: values.work_rating,
      year: Object.assign(
        { year: "1st", grad_level: "undergraduate" },
        values.year
      ),
      other_studies: values.other_studies || "",
      housing_stipend: values.housing_stipend || "",
      recommend: values.recommend || "",
      not_recommend: values.not_recommend || "",
      tools: {
        often: values.tools_often || [],
        occasionally: values.tools_occasionally || [],
        rarely: values.tools_rarely || [],
      },
      description: values.description || "",
      offer: values.offer === 0 ? 0 : values.offer || "",
      would_accept_offer:
        values.would_accept_offer === 0 ? 0 : values.would_accept_offer || "",
      impact: values.impact || "",
      prerequisites: values.prerequisites || "",
      expectations: values.expectations || "",
      expectations_description: values.expectations_description || "",
      work_time: values.work_time || "",
      interview_advice: values.interview_advice || "",
      optional_remarks: values.optional_remarks || "",
      rounds: values.rounds || "",
      formats: values.formats || [],
      interview_types: values.interview_types || [],
      interview_types_new: values.interview_types_new || [],
      feedback: values.feedback || "",
      is_visible: false,
      project_description: values.project_description || "",
      is_remote: values.is_remote || "",
      remote_description: values.remote_description || "",
      feedback_length: values.feedback_length || "",
      interview_application: values.interview_application || "",
      interview_application_new: values.interview_application_new || "",
      interview_questions: values.interview_questions || "",
      permission: values.permission || "",
      major: values.major || "",
      benefits: values.benefits || "",
      pros: values.pros || "",
      cons: values.cons || "",
      school: "Emory",
    };
    database
      .collection("review")
      .doc(review.id)
      .set(review)
      .then(() => {
        const user_id =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);

        database
          .collection("users")
          .doc(user_id)
          .set({
            user_type: "survey_respondent",
            user_timestamp: { seconds: new Date().getTime() / 1000 },
            id: user_id,
            email: values.email,
            major: values.major,
            school: "Emory",
            user_reviews: [review.id],
            survey_answers: {
              question_1: values.emory_question_1,
              question_2: values.emory_question_2,
            },
          })
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });

        navigate("/submit-success");
      })
      .catch((err) => {
        console.log(err);
        navigate("/submit-error");
      });
  };

  const onFail = (errorInfo) => {
    console.log("failed here");
    setUnfinished(true);
    if (errorInfo.values.remember_personal) {
      setCookie("name", errorInfo.values.name || "");
      setCookie("school", errorInfo.values.school || "");
      setCookie("email", errorInfo.values.email || "");
      setCookie("major", errorInfo.values.major || "");
      setCookie("other_studies", errorInfo.values.other_studies || "");
      setCookie("year", errorInfo.values.year || {});
      setCookie("remember_personal", errorInfo.values.remember_personal);
    } else {
      removeCookie("name");
      removeCookie("school");
      removeCookie("email");
      removeCookie("major");
      removeCookie("other_studies");
      removeCookie("year");
      removeCookie("remember_personal");
    }
  };

  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);
  const componentsMap = {
    DetailsTab,
    MyExperienceTab,
    InterviewTab,
    SubmitTab,
    EmoryQuestionsTab,
  };

  return (
    <>
      <Form
        scrollToFirstError={true}
        onFinish={onSuccess}
        onFinishFailed={onFail}
        form={form}
        layout="vertical"
        name="dynamic_rule"
      >
        <Tabs
          defaultActiveKey="1"
          activeKey={submitPage}
          onChange={callback}
          onTabClick={(key, e) => {
            setSubmitPage(key);
          }}
        >
          {l.map((s, i, arr) => {
            let DynamicComponent = componentsMap[s.replace(/ +/g, "") + "Tab"];
            {
              if (arr.length <= 1 || i + 1 != arr.length) {
                return (
                  <TabPane tab={s} key={(i + 1).toString()}>
                    {" "}
                    {(i >= 1 && (
                      <div className="section-card about-you">
                        <h3 className="willBePosted_disclaimer">
                          🐥 This section will be anonymously shared on Canary
                        </h3>
                      </div>
                    )) || (
                      <div className="section-card about-you">
                        <h3 className="willBePosted_disclaimer">
                          🔒 This section is private to the Emory Career Center
                        </h3>
                      </div>
                    )}
                    <DynamicComponent setSubmitPage={setSubmitPage} 
                    index={i} />{" "}
                  </TabPane>
                );
              } else {
                return (
                  <TabPane tab={s} key={(i + 1).toString()}>
                    {" "}
                    <DynamicComponent
                      setSubmitPage={setSubmitPage}
                      unfinished={unfinished}
                    />{" "}
                  </TabPane>
                );
              }
            }
            {
              /*return <TabPane tab={s} key={(i+1).toString()} > <DynamicComponent setSubmitPage={setSubmitPage} unfinished={unfinished}/> </TabPane>*/
            }
          })}
        </Tabs>
      </Form>
    </>
  );
};

const verticalStyle = {
  lineHeight: "30px",
  display: "block",
};

export interface PayValue {
  type: "hourly" | "lump" | "monthly" | "weekly";
  amount: number;
  currency: "USD" | "EUR";
}
export default DynamicRule2;
