import React, { useState, useEffect, useRef } from "react";
import { RouteComponentProps, navigate, useLocation } from "@reach/router";
import "./style.css";
import algoliasearch from "algoliasearch";
import { ReviewCard, DiscoverResults, SignUpRequired } from "..";
import CompaniesScroll from "../CompaniesScroll";
// import { schools_static, majors_static } from "../SubmitAutocomplete.js";
import { useAuth } from "../../contexts/AuthContext";
import {
  Button,
  Input,
  Pagination,
  Spin,
  Result,
  Form,
  Select,
  AutoComplete,
  notification,
  Tooltip,
} from "antd";
import {
  FilterOutlined,
  CloseOutlined,
  ArrowDownOutlined,
  RightCircleOutlined,
  DoubleRightOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  schools,
  schools_static,
  majors_static,
  majors_live,
  schools_live,
  companies,
} from "../SubmitAutocomplete.js";

import { Review, Company as CompanyInterface } from "../../reviews";
import { database } from "../../database";

import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

import pencil from "../../images/icons/pencil.png";

// let test_rev = test_data[1]
// database.collection('review').doc(test_rev.id).set(test_rev)

let is_defaultResults = true;

interface CompanyProps extends RouteComponentProps {
  companyName?: string;
  setPage: any;
  page: any;
  children?: any;
}

let reviewsLimit = 0;

database
  .collection("content")
  .doc("reviewsLimit")
  .get()
  .then((doc) => {
    reviewsLimit = doc.data()?.reviewsLimit_companyPage;
  });

const Company = (props: CompanyProps) => {
  const location = useLocation();
  const [reviews, setReviews] = useState<Review[]>([]);
  const [hitsObject, setHitsObject] = useState<any>();
  const [searchText, setSearchText] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  var urlParams = new URLSearchParams(props.location?.search);
  const [company, setCompany] = useState<CompanyInterface>();
  const { currentUser, logout } = useAuth();
  // Algolia api keys
  const algoliaClient = algoliasearch(
    "ZYXV1W90TC",
    "d389b8c61ad7d47f3964288fce2b6013"
  );
  const collectionIndexName = "reviews_prod"; // "reviews_dev" for testing and playground
  const collectionIndex = algoliaClient.initIndex(collectionIndexName);

  const sentinel = useRef<HTMLDivElement | null>(null);

  const onSentinelIntersection = (entries) => {
    if (currentUser) {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          !loading &&
          hitsObject.page < hitsObject.nbPages
        ) {
          console.log(hitsObject);
          searchForReviews(hitsObject.page + 1, searchText);
        }
      });
    }
  };

  function reviewOrReviews(num_reviews) {
    if (num_reviews > 1) {
      return "reviews";
    } else {
      return "review";
    }
  }

  const rateColor = (value) =>
    value >= 4 ? "#2FF495" : value > 2.5 ? "#F9E02B" : "#F23E30"; //2FF495 30ADF2

  const searchForReviews = async (page: number | undefined, search: string) => {
    if (
      search == "" &&
      positions.length == 0 &&
      majors.length == 0 &&
      tools.length == 0 &&
      schools.length == 0
    ) {
      setIsDefaultResults(true);
    } else {
      setIsDefaultResults(false);
    }

    var reviews_data: any[] = [];

    let companyFilter = ["company.name:" + props.companyName];

    let majorFilter = [];
    majors.forEach((major) => {
      //@ts-ignore
      majorFilter.push("student_major:" + major);
    });

    let toolsFilter = [];
    tools.forEach((toolName) => {
      //@ts-ignore
      toolsFilter.push("tools.often:" + toolName);
    });

    let schoolsFilter = [];
    schools.forEach((schoolName) => {
      //@ts-ignore
      schoolsFilter.push("student_school:" + schoolName);
    });

    let positionFilter = [];
    positions.forEach((positionName) => {
      //@ts-ignore
      positionFilter.push("position:" + positionName);
    });

    setLoading(true);
    await collectionIndex
      .search(search, {
        attributesToRetrieve: ["objectID", "isVisible"],
        page: page,
        facetFilters: [
          companyFilter,
          toolsFilter,
          majorFilter,
          schoolsFilter,
          positionFilter,
        ],
      })
      .then(async (searched) => {
        //console.log(searched)

        setHitsObject(searched);

        for (var i = 0; i < searched.hits.length; i++) {
          const element = searched.hits[i];
          var reviewRef = database
            .collection("review")
            .doc(element["objectID"]);
          try {
            const doc = await reviewRef.get();

            if (doc.exists && element["isVisible"] === true) {
              setPage(1);
              reviews_data.push(doc.data());
            } else {
              // doc.data() will be undefined in this case
              //console.log("No such document!");
            }
          } catch (err) {
            console.log(err);
          }
        }
        setLoading(false);

        if (!currentUser) {
          reviews_data = reviews_data.slice(0, reviewsLimit);
          setReviews((reviews) => [...reviews, ...(reviews_data as Review[])]);
        } else {
          setReviews((reviews) => [...reviews, ...(reviews_data as Review[])]);
        }
      });
  };

  useEffect(() => {
    database
      .collection("companies_public")
      .where("name", "==", props.companyName)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          setCompany(doc.data() as CompanyInterface);
        });
      });
  }, [props.companyName]);

  useEffect(() => {
    async function getReviews(search) {
      setLoading(true);
      urlParams = new URLSearchParams(search);

      setReviews([]);

      searchForReviews(0, search);
    }

    const userSearch: string = urlParams.get("search") || "";
    setSearchText(userSearch);
    getReviews(userSearch);
  }, [location.search]);

  useEffect(() => {
    var observer = new IntersectionObserver(onSentinelIntersection, {});
    if (sentinel.current) {
      observer.observe(sentinel.current);
    }

    return () => observer.disconnect();
  }, [onSentinelIntersection, sentinel]);

  const page = props.page;
  const setPage = props.setPage;

  const [form] = Form.useForm();
  const [reviewCount, setReviewCount] = useState<number>(0);
  const [positions, setPositions] = useState<String[]>([]);
  const [tools, setTools] = useState<String[]>([]);
  const [majors, setMajors] = useState<String[]>([]);
  const [schools, setSchools] = useState<String[]>([]);
  const [isDefaultResults, setIsDefaultResults] = useState(true);

  const initialValues = {
    key: urlParams.get("key") || "relevance",
    search: urlParams.get("search") || "",
    tools: urlParams.get("tools")?.split(",") || [],
    company: urlParams.get("company")?.split(",") || [],
    position: urlParams.get("position")?.split(",") || [],
    major: urlParams.get("major")?.split(",") || [],
    school: urlParams.get("school")?.split(",") || [],
  };

  // Retrieve number of reviews

  // Handle search
  function onFormChange(changed, all) {
    if ("search" in changed && Object.keys(changed).length === 1) {
      return;
    }
    setPage(1);
    for (const [key, value] of Object.entries(changed)) {
      if (!value || (value as Array<string>).length === 0) {
        urlParams.delete(key);
      } else {
        urlParams.set(key, value as string);
      }
    }
    navigate("/companies/" + props.companyName + "/?" + urlParams.toString());
  }

  function handleSearch(value) {
    setPage(1);
    if (value) urlParams.set("search", value);
    else urlParams.delete("search");
    navigate("/?" + urlParams.toString());
  }

  const isDesktop = useMediaQuery({ query: "(min-width: 660px)" });
  const isMobile = !isDesktop;

  return (
    <div className="Company">
      {(company?.name && (
        <>
          <div
            className={classNames("drawer-shade", { visible: showFilter })}
            onClick={() => setShowFilter(false)}
          ></div>

          <div
            className={classNames("drawer-shade", { visible: showFilter })}
            onClick={() => setShowFilter(false)}
          ></div>

          {company && (
            <>
              <div className="company-header">
                {company.banner && (
                  <img className="company-banner" src={company.banner}></img>
                )}

                <div className="company-belowBanner">
                  <div className="company-header-top">
                    {company && (
                      <img
                        src={company.image}
                        alt=""
                        className="company-header-image"
                      />
                    )}
                    <div className="company-header-details">
                      {company && (
                        <h2 className="company-header-name">{company.name}</h2>
                      )}
                      {company && (
                        <div className="company-header-industry">
                          {company.industry}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <div>
                      {company && company.description && (
                        <>
                          <div className="company-header-description">
                            {company.description}{" "}
                            <Tooltip
                              className="input-horizontal-spacer"
                              title="Attribution: Company descriptions are from public sources, e.g. Wikipedia or the company's website, where applicable."
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          </div>

                          <div className="company-header-links">
                            {company.url && (
                              <Button
                                className="company-header-link"
                                href={company.url.toString()}
                                target="_blank"
                              >
                                Website
                              </Button>
                            )}
                            {company.apply && (
                              <Button
                                className="company-header-link"
                                href={company.apply.toString()}
                                target="_blank"
                              >
                                Apply
                              </Button>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {company && company.ratings.overall_avg > 0 && (
                    <>
                      <div className="company_avgRatings">
                        <div>
                          <p className="company-card_rating-title">Overall</p>
                          <div
                            className="company-card__overall-rating"
                            style={{
                              background: rateColor(
                                company.ratings.overall_avg
                              ),
                            }}
                          >
                            {company.ratings.overall_avg}
                            <span className="divisor">/5</span>
                          </div>
                        </div>

                        <div>
                          <p className="company-card_rating-title">Work</p>
                          <div
                            className="company-card__overall-rating"
                            style={{
                              background: rateColor(company.ratings.work_avg),
                            }}
                          >
                            {company.ratings.work_avg}
                            <span className="divisor">/5</span>
                          </div>
                        </div>
                        <div>
                          <p className="company-card_rating-title">Culture</p>
                          <div
                            className="company-card__overall-rating"
                            style={{
                              background: rateColor(
                                company.ratings.culture_avg
                              ),
                            }}
                          >
                            {company.ratings.culture_avg}
                            <span className="divisor">/5</span>
                          </div>
                        </div>
                      </div>
                      <div className="disclaimer-div">
                        <div className="numReviews">
                          <span>
                            From <b>{company.num_reviews}</b>{" "}
                            {reviewOrReviews(company.num_reviews)}
                          </span>
                        </div>
                        <p className="disclaimer">
                          These values are averaged from all reviews for this
                          company. Keep in mind companies with fewer reviews can
                          be skewed by outliers!
                        </p>
                      </div>
                    </>
                  )}
                  <br></br>
                </div>
              </div>
            </>
          )}
          {company && (
            <>
              <div className="company-separator"></div>
              <div className="open-positions-title">
                Reviews for <strong>{company?.name}</strong>
              </div>
            </>
          )}

          <Form
            layout="vertical"
            form={form}
            onValuesChange={onFormChange}
            initialValues={initialValues}
          >
            <div className="search-content">
              <Filter
                onClose={() => setShowFilter(false)}
                visible={showFilter}
                setCompany={setCompany}
                setMajors={setMajors}
                setPositions={setPositions}
                setSchools={setSchools}
                setTools={setTools}
              />
              <div className="reviews-container">
                <div className="reviews-header">
                  <div>
                    {(isDefaultResults && (
                      <h3 className="results-tag">
                        Showing most{" "}
                        <span className="results-specific-tag">recent</span>{" "}
                        reviews
                      </h3>
                    )) || (
                      <h3 className="results-tag">
                        Showing results for most{" "}
                        <span className="results-specific-tag">relevant</span>{" "}
                        reviews
                      </h3>
                    )}
                  </div>
                  <Button
                    type="primary"
                    className="filter-toggle"
                    style={{
                      border: "none",
                      marginLeft: "5px",
                      height: "32px",
                      marginRight: "auto",
                      borderRadius: "5px",
                    }}
                    onClick={() => setShowFilter(true)}
                  >
                    <FilterOutlined /> Filter
                  </Button>
                </div>

                <div className="company-reviews">
                  {reviews.map((review, i) => (
                    <ReviewCard key={review.id} review={review} />
                  ))}
                  {!loading && !reviews ? (
                    <>
                      <Result
                        status="warning"
                        title="Search returned 0 results"
                        style={{ gridColumn: "1 / 3" }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <div id="sentinel" ref={sentinel} />
                </div>

                {loading ? (
                  <div className="review-spinner footer-spacer">
                    <Spin className="text-center" size="large" />
                  </div>
                ) : (
                  <></>
                )}

                {!currentUser && <SignUpRequired />}

                <div className="writeReview_bottom">
                  <p className="callToAction">
                    A student somewhere is looking for a review of where{" "}
                    <b>you</b> worked! Leave a review to help them out!
                  </p>
                  <Button type="primary" onClick={() => navigate("/submit")}>
                    <img className="icon-emoji" src={pencil}></img>Write a
                    Review
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </>
      )) || (
        <div className="review-spinner footer-spacer">
          <Spin className="text-center" size="large" />
        </div>
      )}
    </div>
  );
};

export default Company;

const Filter = ({
  onClose,
  visible,
  setCompany,
  setPositions,
  setTools,
  setMajors,
  setSchools,
}) => {
  return (
    <div className={classNames("filter", { visible })}>
      <div className="filter-toggle filter-close" onClick={onClose}>
        <CloseOutlined />
      </div>
      <h2>
        <FilterOutlined /> Filter
      </h2>

      {/*
      <Form.Item name="position" label="Positions">
        <Select
          onChange={(input) => {
            setPositions(input);
          }}
          //notFoundContent=""
          allowClear
          mode="tags"
          placeholder=""
        ></Select>
        </Form.Item>*/}
      <Form.Item name="tools" label="Tools">
        <Select
          onChange={(input) => {
            setTools(input);
          }}
          notFoundContent=""
          allowClear
          placeholder="Excel, Java, Matlab, etc."
          mode="tags"
        ></Select>
      </Form.Item>
      <Form.Item name="major" label="Majors">
        <Select
          options={majors_live}
          onChange={(input) => {
            setMajors(input);
          }}
          notFoundContent=""
          allowClear
          mode="multiple"
          placeholder="Computer Science, Business, etc."
        ></Select>
      </Form.Item>
      <Form.Item name="school" label="Schools">
        <Select
          options={schools_live}
          onChange={(input) => {
            setSchools(input);
          }}
          notFoundContent=""
          allowClear
          mode="multiple"
          placeholder=""
        ></Select>
      </Form.Item>
      <div className="callToAction_ReviewsPage">
        <span className="callToActionText">Had an internship or co-op?</span>
        <br></br>
        <Button type="primary" size="large" onClick={() => navigate("/submit")}>
          <img className="icon-emoji" src={pencil}></img>Write a Review
        </Button>
      </div>
    </div>
  );
};

interface SortInput {
  value?: "ascending" | "descending";
  onChange?: (value: "ascending" | "descending") => void;
}

const SortInput: React.FC<SortInput> = ({ value = "descending", onChange }) => {
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const onClick = () => {
    let newVal = value === "ascending" ? "descending" : "ascending";
    triggerChange(newVal);
  };

  return (
    <div className="sort">
      <Button
        className={"sort-direction " + value}
        shape="circle"
        style={{
          border: "none",
          background: "none",
          padding: "0",
          boxShadow: "none",
        }}
        onClick={onClick}
      >
        <ArrowDownOutlined />
      </Button>
    </div>
  );
};
