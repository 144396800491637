import React from "react";
import "./style.css";

import { RouteComponentProps, Link } from "@reach/router";

const FAQ = (props: RouteComponentProps) => {
  return (
    <div className="">
      <h1 className="align-center FAQ-title">
        Your burning questions{" "}
        <span role="img" aria-label="fire">
          🔥
        </span>
      </h1>
      <h3 className="align-center">
        Answered by members of the Canary team{" "}
        <span role="img" aria-label="canary">
          🐤
        </span>
      </h3>

      <div className="FAQ-container">
        <h2 className="question">Q: What happens with my data?</h2>
        <p className="answer">
          A: Hey this is Sims - great question.
          <br></br>
          <strong>First, personal data: </strong>Things like email, username,
          profile settings, and their connection to reviews is only for you. It
          will <strong>never</strong> be shared/sold/revealed. This protection
          of identity is a core tenet of Canary, and extraordinarily important
          to us as students. <br></br>
          <br></br>
          <strong>Review data: </strong>The non-personal data we gather on
          internship experiences is, of course, already made public on the site.
          Companies and Career centers may see it to improve their programs (see
          below).
          <br></br>
          <br></br>
          We strive to make it very, very clear what is public and what is not
          (sorry for all the tooltips{" "}
          <span role="img" aria-label="wink-face">
            😉
          </span>
          ). <br></br>- Sims
        </p>
        <h2 className="question">Q: How do you make money?</h2>
        <p className="answer">
          A: Right now, we don't{" "}
          <span role="img" aria-label="wink-face">
            😉
          </span>
          , but we want to use the feedback on the site to help companies make
          their programs even better as well as help schools know how to better
          assist their students get the best experiences they can. We're working
          on putting together charts and graphs and things like that with
          aggregated info to provide insights to companies and schools for these
          purposes. For example, we made a chart that shows that 75% of the
          students on our site who had "Very Impactful" experiences were far
          more likely to accept full-time offers. We hope to use this info to
          encourage companies to ensure they give us students impactful work so
          that we'll actually want to work there!
          <br></br>- Stephen
        </p>

        <h2 className="question">
          Q: Will my employer know that I wrote a review?
        </h2>
        <p className="answer">
          A: While we won't call 'em up and inform them that you left a review,
          one of our missions with Canary is to help companies make their
          internships better for students, so we can have better experiences as
          students. So, employers may reference their page to see what reviews
          say about them - so if you're worried that your employer can piece
          together your identity based upon the public data you provide on the{" "}
          <Link to="/submit"> Write a review </Link> page, feel free to omit it
          or not leave the review! We never want someone to feel uncomfortable
          with what they share on the platform.
          <br></br>- Stephen
        </p>

        <h2>Q: What info of mine is made public?</h2>
        <p className="answer">
          A: You can look to the reviews or the{" "}
          <Link to="/submit"> Write a review </Link> page to see exactly what
          info is made public. Reviews publicly show school and major, for
          example. We do this so the reviews are as helpful as possible to the
          students who visit our site. So it's totally up to you if you would
          rather not post a review if you feel they share too much info. We
          totally understand!
          <br></br>- Sims
        </p>

        <h2 className="question">
          Q: Why do I need to make an account to leave a review?
        </h2>
        <p className="answer">
          A: We do this so we have a way of verifying that you're a real
          student, and not another party trying to manipulate our platform. If
          something goes wrong, seems fishy, or we have a question, we need a
          way to contact you and make sure everything gets sorted out!
          <br></br>- Stephen
        </p>

        <h2 className="question">Q: Where did the name "Canary" come from?</h2>
        <p className="answer">
          A: Ever heard the phrase "canary in the coal mine"?{" "}
          <a
            target="__blank"
            href="https://en.wiktionary.org/wiki/canary_in_a_coal_mine#:~:text=An%20allusion%20to%20caged%20canaries,to%20exit%20the%20tunnels%20immediately."
          >
            Miners used to take canaries into coal mines with them
          </a>
          , and the bird would start chirping if there was noxious gas, so the
          miners could escape before it was too late. Basically, we liked the
          idea of canaries being a warning to others (avoiding bad internships)
          or being helpful by sharing information (finding great internships).
          Of course we hope for a better future for our users than the canaries
          in the coal mines... let's just say the canary bird population is glad
          for advancements in noxious-gas-detecting technology...
          <br></br>- Sims
        </p>
      </div>
    </div>
  );
};

export default FAQ;
