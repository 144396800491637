import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  AutoComplete,
  Tooltip,
  Radio,
  Select,
} from "antd";
import {
  RouteComponentProps,
  Link,
  navigate,
  useLocation,
} from "@reach/router";
import { useAuth } from "../../contexts/AuthContext";
import { schools_static, majors_static } from "../SubmitAutocomplete.js";
import { auth, database } from "../../database";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import * as EmailValidator from "email-validator";
import { YearValue, MyInfo as myInfoInterface } from "../../reviews";
import { ImportsNotUsedAsValues } from "typescript";

const { Option } = Select;

const DynamicRule = ({
  setAccountCreated,
  setIsDisabled,
  setMyInfo,
  portalStyle,
}) => {
  const location = useLocation();
  const [form] = Form.useForm();
  // Setting the state of all the input fields
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const [usernameInput, setUsernameInput] = useState("");
  // const [schoolInput, setSchoolInput] = useState("");
  // const [majorInput, setMajorInput] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { register, currentUser } = useAuth();

  const dev_is_required = true;
  const isMobile = useMediaQuery({ query: "(min-width: 660px)" });
  var urlParams = new URLSearchParams(location?.search);

  // Function called upon clicking the submit button
  async function handleSubmit(values) {
    window.scrollTo(0, 0);

    const thisUser = {
      major: values.major,
      school: values.school,
      username: values.username,
      favorite_reviews: [],
      user_reviews: [],
      user_type: "",
      company_id: "",
    };

    if (portalStyle == "inLine") {
      setMyInfo(thisUser as myInfoInterface);
    }

    //e) {
    //e.preventDefault();

    // let cont = false;
    //console.log(usernameInput);
    var ref = database.collection("usernames").doc(usernameInput);
    ref
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          //console.log(doc);
          return setError("Username already exists");
          //  } else {
          //    if (passwordInput !== confirmPasswordInput) {
          //      return setError("The passwords that you entered do not match");
        } else {
          try {
            setError("");
            setLoading(true);
            await register(emailInput, passwordInput);
            console.log("AUTH SUCCESS");
            if (portalStyle == "inLine") {
              setIsDisabled(false);
              setAccountCreated(true);
            }

            database
              .collection("usernames")
              .doc(usernameInput)
              .set({ username: usernameInput })
              .catch((err) => {
                console.log("error");
                return setError("Username could not be added");
              });
            //database.collection("user").doc(currentUser.id).set(user);
            navigate("/submit");
          } catch {
            setError("Email already exists - try logging in!");
          }
          setLoading(false);
        }
        // cont = true;
        //}
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  const onSuccess = (values) => {
    handleSubmit(values);

    auth.onAuthStateChanged(function (user) {
      if (user) {
        const UID = user.uid;

        console.log(UID);

        const user_set = {
          id: UID,
          username: values.username,
          major: values.major,
          school: values.school,
          user_timestamp: { seconds: new Date().getTime() / 1000 },
          gradLevel: values.gradLevel,
          gradYear: values.gradYear,
        };

        database
          .collection("users")
          .doc(user_set.id)
          .set(user_set)
          .catch((err) => {
            console.log("User record creation unsuccessful:", err);
            //navigate("/submit-error");
          });

        console.log("FIRESTORE SUCCESS");
      }
    });
  };

  const Submit = ({ onSubmit }) => (
    <Form.Item>
      <Button
        //disabled={loading}
        type="primary"
        htmlType="submit"
        disabled={!termsAccepted}
      >
        {" "}
        Create account{" "}
      </Button>
      <br></br>
      <div className="already-have-account">
        Already have an account? <Link to="/login"> Log In here! </Link>
      </div>
    </Form.Item>
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {portalStyle == "portalCard" && (
        <div className="content-card-register">
          {error && (
            <Alert
              className="register-error-alert"
              message="Whoops!"
              description={error}
              type="error"
              showIcon
            />
          )}
          <Form
            scrollToFirstError={true}
            onFinish={onSuccess}
            //onFinishFailed={onFail}
            form={form}
            layout="vertical"
            name="dynamic_rule"
          >
            <Form.Item
              //initialValue={props.homeSchool}
              className=""
              name="school"
              label="School"
              rules={[
                {
                  required: dev_is_required,
                  message: "Please input your school",
                },
              ]}
            >
              <AutoComplete
                className="autofill-input"
                //defaultValue={props.homeSchool}
                options={schools_static}
                placeholder="Please input your school"
                /*style={}*/
                filterOption={(inputValue, option) =>
                  option
                    ? option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    : false
                }
              />
            </Form.Item>

            <Form.Item
              //initialValue={props.homeMajor}
              className="major-input"
              name="major"
              label="Major"
              rules={[
                {
                  required: dev_is_required,
                  message: "Please input your major",
                },
              ]}
            >
              <AutoComplete
                className="autofill-input"
                //defaultValue={props.homeMajor}
                options={majors_static}
                placeholder="Please input your major"
                /*style={{ maxWidth: "320px" }}*/
                filterOption={(inputValue, option) =>
                  option
                    ? option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    : false
                }
              />
            </Form.Item>

            <div className="year-select-register year-select-spacing-card">
              <Form.Item
                //initialValue={props.homeMajor}
                className="input-horizontal year-input"
                name="gradYear"
                label="Grad year"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please input your graduation year",
                  },
                ]}
              >
                <Select
                  className="input-horizontal year-input-nudge year-input-nudge-bottom"
                  placeholder="Year"
                >
                  <Option value="2026">2026</Option>
                  <Option value="2025">2025</Option>
                  <Option value="2024">2024</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2022">2022</Option>
                  <Option value="2021">2021</Option>
                  <Option value="2020">2020</Option>
                  <Option value="2019">2019</Option>
                  <Option value="2018">2018</Option>
                  <Option value="2017">2017</Option>
                  <Option value="2016">2016</Option>
                </Select>
              </Form.Item>
              <Form.Item
                //initialValue={props.homeMajor}
                className="input-horizontal year-input"
                name="gradLevel"
                label=""
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please input your grad level",
                  },
                ]}
              >
                <Select
                  placeholder="Grad level"
                  className="input-horizontal year-input-nudge-bottom"
                >
                  <Option value="undergraduate">Undergrad</Option>
                  <Option value="graduate">Graduate</Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item
              //initialValue={props.homeEmail}
              name="email"
              label={
                <span>
                  School email (will not be public){" "}
                  <Tooltip
                    className="input-horizontal-spacer"
                    title="We use this to verify you are a student. This will NEVER be public or shared w/o your permission."
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  required: dev_is_required,
                  message: "",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value.endsWith(".edu") ||
                      !EmailValidator.validate(value) ||
                      value === null
                    ) {
                      return Promise.reject(
                        "Please enter a valid .edu email address"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                className="register-input"
                //defaultValue={props.homeEmail}
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                placeholder="To make sure you're a student"
              />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: dev_is_required,
                  message: "Please input your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (passwordInput.length > 0 && passwordInput.length < 6) {
                      return Promise.reject(
                        "Password must be 6 or more characters"
                      );
                    } else {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                    }
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password
                className="register-input"
                value={passwordInput}
                onChange={(e) => setPasswordInput(e.target.value)}
              />
            </Form.Item>
            {/*
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: dev_is_required,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      confirmPasswordInput.length > 0 &&
                      confirmPasswordInput.length < 6
                    ) {
                      return Promise.reject(
                        "Password must be 6 or more characters"
                      );
                    } else {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      } else if (
                        !value ||
                        getFieldValue("password") !== value
                      ) {
                        return Promise.reject(
                          "The passwords that you entered do not match"
                        );
                      }
                    }
                  },
                }),
              ]}
            >
              <Input.Password
                className="register-input"
                value={confirmPasswordInput}
                onChange={(e) => setConfirmPasswordInput(e.target.value)}
              />
            </Form.Item>*/}

            <Form.Item
              label={
                <span>
                  Username{" "}
                  <Tooltip
                    className="input-horizontal-spacer"
                    title="Usernames are not publicly connected with reviews."
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="username"
              rules={[
                {
                  required: dev_is_required,
                  message: "",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      value.length < 4 ||
                      value.length > 16 ||
                      value === null
                    ) {
                      return Promise.reject(
                        "Please enter a unique 4-16 character username"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                className="register-input"
                value={usernameInput}
                onChange={(e) => setUsernameInput(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  required: dev_is_required,
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          "Please accept the terms and conditions before continuing"
                        ),
                },
              ]}
            >
              <Checkbox
                className="disclaimer-register"
                onChange={() => setTermsAccepted(!termsAccepted)}
              >
                By clicking the checkbox, I confirm that I have read and agree
                to Canary's{" "}
                <a
                  href="https://app.termly.io/document/privacy-policy/c599c195-4b40-430f-8387-3288a250e2fe"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                ,{" "}
                <a
                  href="https://app.termly.io/document/terms-of-use-for-website/8cc4f576-8bd2-4b88-a984-0e30a80851ce"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
                ,{" "}
                <a
                  href="https://docs.google.com/document/d/1VdlOuxD-Qmh5yCBLh7QtXtjBOvFoSgjT-32u9nhBzvM/edit?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  our Community Guidelines
                </a>
                , and opt-in to account and Canary-related emails. You can
                unsubscribe anytime! <br></br>
              </Checkbox>
            </Form.Item>

            <div className="dataExplanation">
              <p className="dataExplanationText">
                Privacy is priority #1 with Canary{" "}
                <span role="img" aria-label="lock">
                  🔒
                </span>{" "}
                <br></br>
                <a target="_blank" href="/FAQ">
                  Check out our privacy FAQ.
                </a>
              </p>
            </div>
            <Submit onSubmit={onSuccess} />
          </Form>
        </div>
      )}
      {portalStyle == "inLine" && (
        <div className="">
          {error && (
            <Alert
              message="Whoops!"
              className="register-error-alert"
              description={error}
              type="error"
              showIcon
            />
          )}

          <Form
            scrollToFirstError={true}
            onFinish={onSuccess}
            //onFinishFailed={onFail}
            form={form}
            layout="vertical"
            name="dynamic_rule"
          >
            <Form.Item
              //initialValue={props.homeSchool}
              className="input-horizontal register-input"
              name="school"
              label="School"
              rules={[
                {
                  required: dev_is_required,
                  message: "Please input your school",
                },
              ]}
            >
              <AutoComplete
                className="autofill-input input-horizontal register-input"
                //defaultValue={props.homeSchool}
                options={schools_static}
                defaultValue={urlParams.get("school") || ""}
                placeholder="Please input your school"
                /*style={}*/
                filterOption={(inputValue, option) =>
                  option
                    ? option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    : false
                }
              />
            </Form.Item>
            <Form.Item
              //initialValue={props.homeMajor}
              className="major-input input-horizontal register-input"
              name="major"
              label="Major"
              rules={[
                {
                  required: dev_is_required,
                  message: "Please input your major",
                },
              ]}
            >
              <AutoComplete
                className="autofill-input input-horizontal register-input"
                //defaultValue={props.homeMajor}
                options={majors_static}
                placeholder="Please input your major"
                /*style={{ maxWidth: "320px" }}*/
                filterOption={(inputValue, option) =>
                  option
                    ? option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    : false
                }
              />
            </Form.Item>
            <div className="year-select-register">
              <Form.Item
                //initialValue={props.homeMajor}
                className="input-horizontal year-input"
                name="gradYear"
                label="Grad year"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please input your graduation year",
                  },
                ]}
              >
                <Select
                  className="input-horizontal year-input-nudge year-input-nudge-bottom"
                  placeholder="Year"
                >
                  <Option value="2025">2025</Option>
                  <Option value="2024">2024</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2022">2022</Option>
                  <Option value="2021">2021</Option>
                  <Option value="2020">2020</Option>
                  <Option value="2019">2019</Option>
                  <Option value="2018">2018</Option>
                  <Option value="2017">2017</Option>
                  <Option value="2016">2016</Option>
                </Select>
              </Form.Item>
              <Form.Item
                //initialValue={props.homeMajor}
                className="input-horizontal year-input"
                name="gradLevel"
                label=""
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please input your grad level",
                  },
                ]}
              >
                <Select
                  placeholder="Grad level"
                  className="input-horizontal year-input-nudge-bottom"
                >
                  <Option value="undergraduate">Undergrad</Option>
                  <Option value="graduate">Graduate</Option>
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              //initialValue={props.homeEmail}
              name="email"
              className="input-horizontal"
              label={
                <span>
                  School email{" "}
                  <Tooltip
                    className="input-horizontal-spacer"
                    title="We use this to verify you are a student. This will NEVER be public or shared w/o your permission."
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  required: dev_is_required,
                  message: "",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value.endsWith(".edu") ||
                      !EmailValidator.validate(value) ||
                      value === null
                    ) {
                      return Promise.reject(
                        "Please enter a valid .edu email address"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                className="register-input input-horizontal"
                //defaultValue={props.homeEmail}
                value={emailInput}
                defaultValue={urlParams.get("email") || ""}
                onChange={(e) => setEmailInput(e.target.value)}
                placeholder="To make sure you're a student"
              />
            </Form.Item>
            <div className="password-field-spacer">
              <Form.Item
                name="password"
                label="Password"
                className="input-horizontal register-input"
                rules={[
                  {
                    required: dev_is_required,
                    message: "Please input your password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value.length < 6 && value.length > 0) {
                        return Promise.reject(
                          "Password must be at least 6 characters"
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password
                  className="register-inline-password-spacer"
                  value={passwordInput}
                  onChange={(e) => setPasswordInput(e.target.value)}
                />
              </Form.Item>
            </div>
            {/*
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: dev_is_required,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      confirmPasswordInput.length > 0 &&
                      confirmPasswordInput.length < 6
                    ) {
                      return Promise.reject(
                        "Password must be 6 or more characters"
                      );
                    } else {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      } else if (
                        !value ||
                        getFieldValue("password") !== value
                      ) {
                        return Promise.reject(
                          "The passwords that you entered do not match"
                        );
                      }
                    }
                  },
                }),
              ]}
            >
              <Input.Password
                className="register-input"
                value={confirmPasswordInput}
                onChange={(e) => setConfirmPasswordInput(e.target.value)}
              />
            </Form.Item>*/}
            <Form.Item
              className="input-horizontal"
              label={
                <span>
                  Username{" "}
                  <Tooltip
                    className="input-horizontal-spacer"
                    title="Usernames are not publicly connected with reviews."
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="username"
              rules={[
                {
                  required: dev_is_required,
                  message: "",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      value.length < 4 ||
                      value.length > 16 ||
                      value === null
                    ) {
                      return Promise.reject(
                        "Please enter a unique 4-16 character username"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                className="register-input input-horizontal"
                value={usernameInput}
                onChange={(e) => setUsernameInput(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  required: dev_is_required,
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          "Please accept the terms and conditions before continuing"
                        ),
                },
              ]}
            >
              <Checkbox
                className="disclaimer-register"
                onChange={() => setTermsAccepted(!termsAccepted)}
              >
                By clicking the checkbox, I confirm that I have read and agree
                to Canary's{" "}
                <a
                  href="https://app.termly.io/document/privacy-policy/c599c195-4b40-430f-8387-3288a250e2fe"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                ,{" "}
                <a
                  href="https://app.termly.io/document/terms-of-use-for-website/8cc4f576-8bd2-4b88-a984-0e30a80851ce"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
                ,{" "}
                <a
                  href="https://docs.google.com/document/d/1VdlOuxD-Qmh5yCBLh7QtXtjBOvFoSgjT-32u9nhBzvM/edit?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  our Community Guidelines
                </a>
                , and opt-in to account and Canary-related emails. You can
                unsubscribe anytime! <br></br>
              </Checkbox>
            </Form.Item>
            <div className="dataExplanation">
              <p className="dataExplanationText">
                Privacy is priority #1 with Canary{" "}
                <span role="img" aria-label="lock">
                  🔒
                </span>{" "}
                <br></br>
                <a target="_blank" href="/FAQ">
                  Check out our privacy FAQ.
                </a>
              </p>
            </div>
            <Submit onSubmit={onSuccess} />
          </Form>
        </div>
      )}
    </>
  );
};

const RegisterPortal = ({
  setAccountCreated,
  setIsDisabled,
  setMyInfo,
  portalStyle,
}) => {
  return (
    <div className="">
      <DynamicRule
        //homeEmail={props.homeEmail}
        //homeSchool={props.homeSchool}
        //homeMajor={props.homeMajor}
        portalStyle={portalStyle}
        setIsDisabled={setIsDisabled}
        setMyInfo={setMyInfo}
        setAccountCreated={setAccountCreated}
      />
    </div>
  );
};

export default RegisterPortal;
