import React, { useState } from 'react';
import './style.css';
import { Link, RouteComponentProps } from "@reach/router"
import { Button, Alert, Form, Input } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import { useAuth } from '../../contexts/AuthContext'


const ForgotPassword = (props: RouteComponentProps) => {
  
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [emailInput, setEmailInput] = useState('')
    const { resetPassword } = useAuth()
    const [loading, setLoading] = useState(false)

    // Function called upon clicking the submit button
    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage('')
            setError('')
            setLoading(true)
            await resetPassword(emailInput)
            setMessage('Check your inbox for futher instructions')
        } catch {
            setError('Failed to reset password')
        }
        setLoading(false)
    }
  
    return (
        <div className="Forgot Password">

            <div className="Title">
                <h1 className="align-center">Password Reset</h1>
            </div>

            <div className="content-card">
        
                {error && <Alert message="Error" description={error} type='error' showIcon />}
                {message && <Alert message="Email Sent" description={message} type='info' showIcon />}

                <Form>

                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your Email Address' }]}
                    >
                        <Input 
                            prefix={<MailOutlined className="site-form-item-icon" />} 
                            placeholder="Email" 
                            value={emailInput} 
                            onChange={e => setEmailInput(e.target.value)} 
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button disabled={loading} type="primary" htmlType="submit" className="login-form-button" onClick={handleSubmit} > Reset Password </Button>
                    </Form.Item>

                    <Form.Item>
                        <Link to="/login">Log In</Link>
                    </Form.Item>

                </Form>
                

            </div>

        </div>
    );
}

export default ForgotPassword;