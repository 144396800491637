import React, { useState } from "react";
import "../../test.less";
import "./style.css";

import { Link } from "@reach/router";

import { MenuOutlined } from "@ant-design/icons";
import { auth } from "../../database";
import { Button, Menu, Dropdown } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import reviewsIcon from "../../images/icons/reviews.png";
import pencil from "../../images/icons/pencil.png";
import people from "../../images/icons/people.png";
import register from "../../images/icons/register.png";
import login from "../../images/icons/login.png";
import bird_front from "../../images/icons/bird_front.png";
import medal from "../../images/icons/medal.png";
import lightbulb from "../../images/icons/lightbulb.png";
import student from "../../images/icons/student.png";
import office from "../../images/icons/office.png";

let mobileMenu = (
  <Menu>
    {/*
    <Menu.Item>
      <Link to="/">
        <span role="img" aria-label="building emoji">
          🏢
        </span>{" "}
        Companies
      </Link>
    </Menu.Item>*/}

    <Menu.Item>
      <Link to="/">
        <img className="icon-emoji" src={reviewsIcon}></img>
        Reviews
      </Link>
    </Menu.Item>

    <Menu.Item>
      <Link to="/submit">
        <img className="icon-emoji" src={pencil}></img>
        Write a review
      </Link>
    </Menu.Item>

    <Menu.Item>
      <Link to="/topcompanies">
        <img className="icon-emoji" src={medal}></img>Top companies
      </Link>
    </Menu.Item>

    <SubMenu
      icon={<img className="icon-emoji" src={lightbulb}></img>}
      title=" Insights"
      className="AccountMenu"
    >
      <Menu.Item key="insights-students">
        <Link to="/insights/students">
          <img className="icon-emoji" src={student}></img>For students
        </Link>
      </Menu.Item>
      <Menu.Item key="insights-employers">
        <Link to="/insights/employers">
          <img className="icon-emoji" src={office}></img>For employers
        </Link>
      </Menu.Item>
    </SubMenu>

    <SubMenu
      icon={<img className="icon-emoji" src={people}></img>}
      title=" Account"
      className="AccountMenu"
    >
      <Menu.Item key="register">
        <Link to="/register">
          <img className="icon-emoji" src={register}></img>Sign up
        </Link>
      </Menu.Item>
      <Menu.Item key="login">
        <Link to="/login">
          <img className="icon-emoji" src={login}></img>Log in
        </Link>
      </Menu.Item>
    </SubMenu>

    <Menu.Item className="about-tab-mobile">
      <a href="https://www.canarystudent.com/about" target="_blank">
        <img className="icon-emoji" src={bird_front}></img>About
      </a>
    </Menu.Item>
  </Menu>
);

auth.onAuthStateChanged(function (user) {
  if (user) {
    mobileMenu = (
      <Menu>
        {/*
        <Menu.Item>
          <Link to="/">
            <span role="img" aria-label="building emoji">
              🏢
            </span>{" "}
            Companies
          </Link>
        </Menu.Item>*/}

        <Menu.Item>
          <Link to="/">
            <img className="icon-emoji" src={reviewsIcon}></img>
            Reviews
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link to="/submit">
            <img className="icon-emoji" src={pencil}></img>Write a review
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link to="/topcompanies">
            <img className="icon-emoji" src={medal}></img>Top companies
          </Link>
        </Menu.Item>

        <SubMenu
          icon={<img className="icon-emoji" src={lightbulb}></img>}
          title=" Insights"
          className="AccountMenu"
        >
          <Menu.Item key="insights-students">
            <Link to="/insights/students">
              <img className="icon-emoji" src={student}></img>For students
            </Link>
          </Menu.Item>
          <Menu.Item key="insights-employers">
            <Link to="/insights/employers">
              <img className="icon-emoji" src={office}></img>For employers
            </Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item>
          <Link to="/profile">
            <img className="icon-emoji" src={people}></img>My profile
          </Link>
        </Menu.Item>

        <Menu.Item className="about-tab-mobile">
          <a href="https://www.canarystudent.com/about" target="_blank">
            <img className="icon-emoji" src={bird_front}></img>About
          </a>
        </Menu.Item>
      </Menu>
    );
  }
});

const DropdownMenu = () => {
  return (
    <div style={{ margin: "15px 15px 10px auto" }}>
      <Dropdown key="more" overlay={mobileMenu} trigger={["click"]}>
        <Button
          style={{
            border: "none",
            padding: 0,
          }}
        >
          <MenuOutlined
            style={{
              fontSize: 20,
              verticalAlign: "left",
            }}
          />
        </Button>
      </Dropdown>
    </div>
  );
};

export default DropdownMenu;
